import { Button, Card, Col, Image, Form, Input, Row, message, Empty, Modal, Alert, Progress, Tooltip, Badge } from 'antd'
import ProgressBar from '../../components/V2/InventoryShipmentV2/ProgressBar'
import ProductService from '../../services/ProductService'
import ClaimOrderService from '../../services/ClaimOrderService'
import { openNotification } from '../../utils'
import { IProductResponse } from '../../types/Product'
import { useEffect, useState } from 'react'
import { useNavigate, useOutlet } from 'react-router-dom'
import InventoryShipmentCard from '../../components/V2/InventoryShipmentV2/InventoryShipmentCard'
import StarterCard from '../../components/V2/ProfileV2/StarterCard'
import FbaImg from '../../assets/dashboard-v2/fba-quickaccess.png';
import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg';
import OneamzAppService from '../../services/OneamzAppService'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon.svg';
import { IResponseOrder } from '../../types/Order'
import FBAResultItemCard from './OrderDetail/FBAResultItemCard'
import WarehouseCard from '../../components/FBACreate/WarehouseCard'
import InventoryService from '../../services/InvertoryService'
import dayjs from 'dayjs';


const InventoryNewV2 = () => {
    const navigate = useNavigate()
    const [productLoad, setProductLoad] = useState(false)
    const [asinFind] = Form.useForm();
    const [orderItemList, setOrderItemList] = useState<IProductResponse[]>([])
    const [totalArea, setTotalArea] = useState(0)
    const [totaPrice, setTotalPrice] = useState<any>(0)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [orderCreated, setOrderCreated] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(0)
    const [loadBalance, setLoadBalance] = useState<any>(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [orderCreateLoading, setOrderCreateLoading] = useState(false)
    const [order, setOrder] = useState<any>();
    const [fbaNo, setFbaNo] = useState<any>(null);
    const [shipmentCode, setShipmentCode] = useState<any>(null);
    const id = localStorage.getItem('id');

    // BEFORE
    const [beforeTotalVolume, setBeforeTotalVolume] = useState(0);
    const [beforeVolume, setBeforeVolume] = useState(0);
    const [beforePercent, setBeforePercent] = useState(0);
    const [beforeSuccessPercent, setBeforeSuccessPercent] = useState(0);

    // AFTER
    const [afterVolume, setAfterVolume] = useState(0);
    const [afterPercent, setAfterPercent] = useState(0);


    const [storageVolume, setStorageVolume] = useState(0)
    const [totalStorageVolume, setTotalStorageVolume] = useState(0)
    const [totalStorage, setTotalStorage] = useState(0)

    const [userVolume, setUserVolume] = useState(0)
    const [productVolume, setProductVolume] = useState(0)
    const [daysRemainingInMonth, setDaysRemainingInMonth] = useState(0)

    useEffect(() => {
        getTotalStorageVolume();
    }, [])

    const getDaysRemainingInMonth = (): number => {
        // Get the current date
        const now = dayjs();
        // Get the last day of the current month
        const endOfMonth = now.endOf('month');
        // Calculate the difference in days between now and the end of the month
        const difference = endOfMonth.diff(now, 'day');

        // Return the difference (Adding 1 because it includes today)
        return difference;
    };

    const getDaysInCurrentMonth = (): number => {
        // Şu anki tarihi al
        const now = dayjs();
        // Bu ayın son gününü al ve ayın gününü döndür (ayın kaçıncı günü olduğunu verir, dolayısıyla bu ayın gün sayısıdır)
        const daysInMonth = now.endOf('month').date();

        return daysInMonth;
    };

    const externalToken = localStorage.getItem('externalAccessToken');
    const externalRefreshToken = localStorage.getItem('externalRefreshToken');

    useEffect(() => {
        let box = 100 * 100 * 100;
        let total = 0;
        orderItemList.map(item => {
            let productItem: any = item;
            total += calculateVolumeInCubic(productItem.length, productItem.width, productItem.height) * productItem.qty;
        });
        let boxCount = total / box;
        setProductVolume(boxCount)
        // setTotalArea(boxCount);
        // setTotalPrice((boxCount * 14.99).toFixed(2));

    }, [orderItemList])

    function calculateVolumeInCubic(depthInInches: number, widthInInches: number, heightInInches: number): number {
        depthInInches = depthInInches * 2.54;
        widthInInches = widthInInches * 2.54;
        heightInInches = heightInInches * 2.54;
        return depthInInches * widthInInches * heightInInches;
    }

    const refreshPage = () => {
        window.location.reload();
    };

    const onCreateOrder = () => {
        setOrderCreateLoading(true);
        let claimOrderItems: any = [];

        orderItemList.map((orderItem: any) => {

            let orderItemObj = {
                productId: orderItem.id,
                qty: orderItem.qty,
                fnskuLabel: null,
                trackingCode: null,
                claimOrderItemGeneralServices: []
            }

            orderItemObj.claimOrderItemGeneralServices = [];

            claimOrderItems.push(orderItemObj);

        });

        let postObj = {
            shippingFee: 0,
            note: null,
            shippingProvider: 'AMAZON',
            warehouseId: 1,
            orderType: 'STORAGE',
            isInWareHouse: false,
            claimOrderItems: claimOrderItems,
            claimOrderGeneralServices: []
        };

        ClaimOrderService.create(postObj)
            .then((response: any) => {
                const claimOrderId = response.data;
                setFbaNo(claimOrderId)
                setShipmentCode(`ONESHIP (${id}-STORAGE-${claimOrderId})`)
                openNotification('success', 'Başarılı', "Depo gönderisi oluşturuldu");
                setOrderCreated(true);
                setOrderCreateLoading(true);
                getClaimOrderDetail(claimOrderId);
            })
            .catch((e: Error) => {
                console.log(e);
                setOrderCreateLoading(true);
            });

    }

    const getBalance = () => {
        OneamzAppService.getBalance()
            .then((response: any) => {
                let currentBalance: number = response.data.balance;
                setCurrentBalance(currentBalance);
                if (currentBalance < totaPrice) {
                    setLoadBalance((totaPrice - currentBalance).toFixed(2))
                    setIsModalOpen(true);
                } else {
                    setIsConfirmModal(true);
                }
            }).catch((e: Error) => {
            });
    }

    const handleSubmit = (values: any) => {
        setProductLoad(true);
        ProductService.getbyASIN(values.asin)
            .then((response: any) => {
                let data: IProductResponse = response.data;
                if (!orderItemList.some(item => item.asin === data.asin)) {
                    let newData = {
                        ...data,
                        qty: 1,
                        fnskuLabel: null,
                        trackingCode: null,
                        services: []
                    };
                    setOrderItemList([...orderItemList, newData]);
                    message.success('Ürün eklendi');
                } else {
                    message.success('Ürün listede mevcut');
                }
                console.log(data)
                setProductLoad(false);
                asinFind.resetFields();
            })
            .catch((e: Error) => {
                setProductLoad(false);
                message.error('Ürün bulunamadı');
            });
    };

    const onHandleRemoveProduct = (id: any) => {
        const updatedList = orderItemList.filter(item => item.id !== id);
        setOrderItemList(updatedList);
        message.success('Ürün çıkarıldı');
    };

    const onHandleProductChange = (updatedItem: any) => {
        setOrderItemList((orderItemList) => {
            return orderItemList.map(item => {
                // Check if the current item's id matches the updated item's id
                if (item.id === updatedItem.id) {
                    // If yes, return the updated item
                    return updatedItem;
                }
                // Otherwise, return the item as is
                return item;
            });
        });
    };

    const getClaimOrderDetail = (id: string) => {
        ClaimOrderService.get(id)
            .then((response: any) => {
                const data: IResponseOrder = new IResponseOrder(response.data);
                setOrder(data);
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    const getTotalStorageVolume = () => {
        InventoryService.getTotalStorageVolume()
            .then((response: any) => {
                let data = response.data;
                setBeforeVolume(data);

                let total = Math.ceil(data);
                setBeforeTotalVolume(total);

                let percent = data / total * 100;
                setBeforePercent(percent);
                setBeforeSuccessPercent(percent);


                setUserVolume(data);
                setStorageVolume(percent);
                setTotalStorage(total);
                setTotalStorageVolume(total);
            }).catch((e: Error) => {
            });
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        onCreateOrder();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        console.log('change productVolume', productVolume);
        let volume = userVolume + productVolume;
        let total = Math.ceil(volume);
        let percent = volume / total * 100;
        let diffBox = total - Math.ceil(userVolume);
        setTotalArea(diffBox);

        if (volume > beforeTotalVolume) {
            let diffVolume = beforeTotalVolume - volume;
            diffVolume = Math.abs(diffVolume);
            setAfterVolume(diffVolume);

            setBeforeSuccessPercent(100);

            if (diffBox > 0) {
                let diffPercent = diffVolume / diffBox * 100;
                console.log('diffVolume', diffVolume);
                console.log('totalArea', totalArea);
                console.log('diffPercent', diffPercent);
                setAfterPercent(diffPercent);
            } else {
                setAfterPercent(0);
            }
        } else {
            setAfterVolume(0);
            setAfterPercent(0);
            let diffPercent = volume / beforeTotalVolume * 100;
            setBeforeSuccessPercent(diffPercent);
        }

        let dayCount = getDaysInCurrentMonth();
        let diffDayCount = getDaysRemainingInMonth();
        setDaysRemainingInMonth(diffDayCount);
        console.log('diffDayCount', diffDayCount);
        let boxPrice = (14.99 / dayCount) * diffDayCount;

        setTotalPrice((diffBox * boxPrice).toFixed(2));
        setStorageVolume(percent);
        setTotalStorage(total);
        setTotalStorageVolume(total);
    }, [productVolume])

    return (
        <>
            {!orderCreated ?
                <>
                    <Modal title={<h3 style={{ margin: '0px' }}>Krediniz Yetersiz</h3>} open={isModalOpen} onCancel={handleCancel}
                        footer={(_, { }) => (
                            <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                                <Col>
                                    <Button type='primary' style={{ backgroundColor: 'black', color: "pure-white", padding: '0px 30px' }} onClick={handleCancel} >İptal Et</Button>
                                </Col>
                                <Col>
                                    <a target='_blank' href={`${process.env.REACT_APP_EXTERNAL_BASE_URL}/public/module/callback?accessToken=${externalToken}&refreshToken=${externalRefreshToken}&redirectUrl=/balance?amount%3D${loadBalance}%26openPaymentModal%3Dtrue`}>
                                        <Button style={{ backgroundColor: '#FFCB2B', color: 'black', padding: '0px 20px' }} type='primary'>Kredi Yükle</Button>
                                    </a>
                                </Col>
                            </Row>
                        )}>
                        <Row justify={"center"}>
                            <InfoCircleOutlined style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                        </Row>
                        <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px" }}>İşleminiz için en az <b>${loadBalance}</b> kredi yüklemelisiniz</h3>
                    </Modal>
                    <Modal open={isConfirmModal} onCancel={() => { setIsConfirmModal(false) }}
                        footer={(_, { }) => (
                            <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                                <Col>
                                    <Button className='bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text' onClick={() => { setIsConfirmModal(false) }} >İptal Et</Button>
                                </Col>
                                <Col>
                                    <Button className='bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text' loading={orderCreateLoading} onClick={() => { onCreateOrder() }} >Onayla</Button>
                                </Col>
                            </Row>
                        )}>
                        <Row justify={"center"}>
                            <WarningIcon className='ml-32' style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                        </Row>
                        <Row justify={'center'}>
                            <Col span={20}>
                                <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px 10px" }}>
                                    <b>Eklenecek Storage Box Adeti:</b> {totalArea}
                                </h3>
                                <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px" }}>
                                    {(totaPrice > 0) && <>
                                        Depolama hizmeti ödemesi için toplam kredinizden  Aylık <b>${(14.99 * totalArea).toFixed(2)}</b> olan ücretin <b>{daysRemainingInMonth}</b> Günlük miktarı olan <b>${totaPrice}</b> düşecektir. Onaylıyor musunuz?
                                    </>}
                                    {(totaPrice <= 0) && <>Depolama kapasitenizde ürünler için alan mevcut, işlem için bu ay, kredinizden ödeme alınmayacaktır.</>}
                                </h3>
                            </Col>
                        </Row>
                    </Modal>
                    <Row>
                        <Col span={24}>
                            <ProgressBar currentStep={0}></ProgressBar>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Card className='border-radius-md border-slate-blue-02 mt-24'>
                                <h3 className='h-3 font-color-slate-blue mt-0'>
                                    Asın Kodu ile Depoya Ürün Gönderin!
                                </h3>
                                <p className='text fs-14'>
                                    Gönderilen ürünlerimiz, kalite standartlarına uygun olarak hızlı ve düzenli bir şekilde depolanır.
                                    Depomuz, etkili stok yönetimi ile donatılmıştır, böylece müşteri siparişleri hızlı ve sorunsuz bir şekilde
                                    işleme alınabilir. Ürünlerimiz, güvenli bir depolama sürecinden geçtikten sonra hızlı bir şekilde sevkiyata
                                    hazır hale getirilir, bu da müşterilere zamanında ulaşım sağlar.
                                </p>
                                <Form form={asinFind} onFinish={handleSubmit}>
                                    <Row className='mt-24'>
                                        <Col >
                                            <Form.Item
                                                className='mb-0'
                                                name="asin"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Asin kodu giriniz',
                                                    }
                                                ]}
                                            >
                                                <Input className='amz-input-44px' placeholder='Asın...' ></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Button htmlType="submit" loading={productLoad} className='ml-24 btn btn-hover-dark bg-slate-blue font-color-cloud btn-text'>
                                                Ekle
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Alert className='border-none border-radius-md mt-24'
                                description={<p className='text fs-14 m-0'>
                                    <b>*Lütfen Siparişinizde aşağıdaki adres bilgilerini kullanın</b><br /><br />
                                    <b>OneAmz Depo Adresi: </b> 356 GETTY AVE BLD-5A # ONEAMZ-0413464 CLIFTON, NJ 07011-2118 United States <br />
                                    <b>Telefon: </b>  917 306 6033
                                </p>}
                                type="info"
                                showIcon
                            />
                        </Col>
                    </Row>
                    <Row className='mt-24'>
                        <Col span={24}>
                            <Card className='bg-cloud border-radius-md'>
                                {/* <Row justify={"space-between"}>
                                    <p className='text font-color-slate-blue'>
                                        <b>{totalStorage} Box, %{(storageVolume > 0) && storageVolume.toFixed(2)} Dolu</b>
                                    </p>
                                </Row> */}
                                <Row>
                                    <Col span={24}>
                                        <Card className='bg-white border-radius-md'>
                                            <Row gutter={[32, 32]}>
                                                <Col md={16} sm={12} xs={12}>
                                                    <p style={{ textAlign: "center" }} className='font-color-slate-blue-06 fs-18'>
                                                        Kullanılan <b className='font-color-slate-blue'>{beforeTotalVolume}</b> Storage Box
                                                        {/* Kullanılan <b>{beforePercent}</b> Storage Box
                                                        Kullanılan <b>{storageVolume}</b> Storage Box */}
                                                    </p>
                                                    <Tooltip placement="bottom" title={<b>%{beforeSuccessPercent.toFixed(0)}</b>}>
                                                        <Progress className='storage-progress' size={['100%', 20]} percent={(beforeSuccessPercent)} success={{ percent: beforePercent }} showInfo={false} />
                                                    </Tooltip>
                                                </Col>
                                                <Col md={8} sm={12} xs={12}>
                                                    <p style={{ textAlign: "center" }} className='font-color-slate-blue-06  fs-18'>
                                                        Eklenen <b className='font-color-slate-blue'>{(totalArea > 0) ? `+${totalArea}` : 0}</b> Storage Box
                                                        {/* {afterPercent} */}
                                                    </p>
                                                    <Tooltip placement="bottom" title={<b className='font-color-slate-blue'>%{afterPercent.toFixed(0)}</b>} color={'#FFCB2B'}>
                                                        <Progress className='product-storage-progress' size={['100%', 20]} percent={100} success={{ percent: afterPercent }} showInfo={false} />
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                            <Row className='mt-16'>
                                            <Badge color='#333E48' text="Kullanılan Storage Box" className='mr-24' />
                                            <Badge color='#FFCB2B' text="Yeni ürünlerin depolanması için gereken alan" />
                                            </Row>
                                        </Card>
                                    </Col>
                                    {/* {userVolume} - {productVolume} - {afterVolume} - {beforeSuccessPercent} */}
                                </Row>
                                <Row className='mt-2'>
                                    {orderItemList.map((item, key) => (
                                        <Col span={24}>
                                            <InventoryShipmentCard changeProduct={onHandleProductChange} removeProduct={onHandleRemoveProduct} item={item} />
                                        </Col>
                                    ))}
                                    {(orderItemList.length == 0) ? <Col span={24}><Empty description={<h3>Depo gönderim listesine ürün ekleyiniz</h3>} image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> : <></>}
                                </Row>
                                <Row className='mt-24' gutter={24} justify={'end'}>
                                    <Col className='text fs-12 font-color-slate-blue-05'>
                                        STORAGE BOX: <b>{totalArea}</b>
                                    </Col>
                                    <Col className='text fs-12 font-color-slate-blue-05'>
                                        <b>BİRİM MALİYET: $14.99</b>
                                    </Col>
                                    <Col className='text fs-12 font-color-slate-blue'>
                                        <b>TOPLAM MALİYET: ${totaPrice}</b>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-24' justify={'space-between'}>
                        <Col>
                            <Button className='btn btn-hover-dark border-radius-12 bg-cloud btn-text font-color-slate-blue'>
                                Geri
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={orderItemList.length <= 0} onClick={getBalance} className='btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud'>
                                Depoya Gönder
                            </Button>
                        </Col>
                    </Row>
                </> :
                <>
                    <Row>
                        <Col span={24}>
                            <StarterCard />
                        </Col>
                    </Row>
                    <Card className='mt-24' bodyStyle={{ border: 'none', padding: '0px' }} style={{ border: 'none' }}>
                        <Row gutter={24}>
                            <Col xl={6} xs={24}>
                                <Card className='bg-oneamz-yellow border-radius-md'>
                                    <p className='text font-color-slate-blue bg-cloud p-8 d-inline border-radius-sm'><b>DEPO GÖNDERİ KODU <InfoIcon className='wh-14' /></b></p>
                                    <h2 className='h-3 font-color-slate-blue mt-16 mb-0'>ONESHIP</h2>
                                    <h2 className='h-3 font-color-slate-blue mt-0'>({id}-STORAGE-{fbaNo})</h2>
                                    <p className='text fs-18 font-color-slate-blue' >
                                        Ürününüzün detaylarına ulaşmak için, oneship gönderi koduyla uygulama içinde arama yapabilirsiniz
                                    </p>
                                    <Row justify={'center'} className='mt-64'>
                                        <Col>
                                            <Image preview={false} src={FbaImg}></Image>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl={18} xs={24}>
                                <Card className='bg-cloud border-radius-md'>
                                    <h1 className='h-2 font-color-slate-blue m-0'>Tebrikler, 1. Adımı Tamamladınız!</h1>
                                    <p className='text fs-24 font-color-slate-blue m-0'>Gönderi Talebiniz Başarıyla Kaydedilmiştir</p>
                                    <p className='text font-color-slate-blue mt-8'>Göstermiş olduğunuz özen ve dikkat için teşekkür ederiz. Depoya ürün
                                        gönderme sürecinizin bu aşamasını başarıyla tamamladınız. 2. Adımı tamamlayıp depoya ürün gönderi sürecinizi sonlandırabilirsiniz.</p>
                                    <p className='pretitle font-color-slate-blue mt-32 upper-case'>ÜRÜN BİLGİLERİ</p>
                                    {order?.items.map((item: any, index: any) => (
                                        <FBAResultItemCard item={item} key={index} order={order} setQty={() => { }} orderItem={item}></FBAResultItemCard>
                                    ))}
                                </Card>

                                <Alert className='border-none border-radius-md mt-24'
                                    description={<p className='text fs-14 m-0'><b>ÖNEMLİ BİLGİLENDİRME:</b> Lütfen unutmayın, ürünleri aşağıda belirtilen adrese göndermediğiniz takdirde siparişiniz tamamlanmayacaktır.
                                    </p>}
                                    type="info"
                                    showIcon
                                />

                                <Card className='bg-cloud mt-24 border-radius-md'>
                                    <Row gutter={36}>
                                        <Col span={24}>
                                            <h2 className='h-2 m-0 font-color-slate-blue mb-0'>2.Adım: Ürünleri Depoya Gönderin</h2>
                                            <p className='font-color-slate-blue fs-14 btn-text-sm mt-8'>
                                                Yukarıda belirtmiş olduğunuz ürünleri aşağıdaki OneAmz Depo adresine göndermenizi bekliyoruz.
                                            </p>
                                        </Col>
                                        <Col span={24}>
                                            <WarehouseCard shipmentCode={shipmentCode} />
                                        </Col>
                                    </Row>
                                    <Row justify={'end'} gutter={8}>
                                        <Col>
                                            <Button onClick={() => navigate(`/claim/detail/${fbaNo}`)} className=' btn btn-text btn-hover-dark bg-cloud font-color-slate-blue border-slate-blue border-radius-12 mt-24'>
                                                Gönderi Detayına Git
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button onClick={() => refreshPage()} className='btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-12 mt-24'>
                                                Yeni Ürün(Ler) Depoya Çek
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        </Row>
                    </Card>
                </>
            }
        </>
    )
}

export default InventoryNewV2
