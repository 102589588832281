import React from 'react';
import { Button, Col, Form, Row, Image } from 'antd';
import loginLogo from '../../assets/loginLogo.svg'
import loginImage from '../../assets/loginImage2.png'
import { useNavigate } from "react-router-dom";

const EmailVerification: React.FC = () => {
    const navigate = useNavigate();

    const SignIn = (values: any) => {
        navigate('/login')
    };

    const onFinish = (values: any) => {
        SignIn(values)
    };

    return (
        <>
            <Row justify={'space-between'}>
                <Col xs={0} sm={0} md={0} lg={16} xl={16} style={{ position: 'fixed', top: 0, left: 0, height: '100vh', overflow: 'hidden' }}>
                    <Image style={{ width: "54vw", height: "100vh" }} preview={false} src={loginImage} />
                </Col>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 9, offset: 14 }} xl={{ span: 9, offset: 14 }}>
                    <Row justify={'center'}>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 20 }} md={{ span: 14 }} lg={{ span: 22 }} xl={18}>
                            <Row justify={'center'} style={{ margin: "5em 0 0 0", width: "100%" }}>
                                <Col>
                                    <Image style={{ height: "15vh" }} preview={false} src={loginLogo} />
                                </Col>
                            </Row>
                            <Row style={{ height: "30vh" }} justify={'center'}>
                                <Col span={20}>
                                    <Form
                                        name="normal_login"
                                        className="login-form"
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}
                                    >
                                        <h1 style={{ fontSize: "3em", margin: "1em 0 1em 0" }}>Tebrikler, Emailiniz Doğrulandı!</h1>
                                        <p style={{ fontSize: "1.2em", marginBottom: "3em" }}>
                                            Emailiniz doğrulandı. Artık hesabınıza giriş yapabilirsiniz. Oneamz'nin son teknolojiyle yönetilen Fullfilment
                                            uygulamasına hoşgeldiniz.
                                        </p>
                                        <Form.Item>
                                            <Button style={{ width: "100%", background: "black", height: "4em" }} type="primary" htmlType="submit" className="login-form-button">
                                                <p style={{ fontSize: "1.2em", margin: "0", color: "#FFCB2B" }}><b>Giriş Yap</b></p>
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default EmailVerification;