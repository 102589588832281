import { Col, Collapse, Row, Form, Input, Alert, Select, Radio, Card, RadioChangeEvent, Divider } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getCountries } from '../../utils';
import OneamzAppService from '../../services/OneamzAppService';
import { debounce } from 'lodash';
import AmazonProductImage from '../Common/AmazonProductImage';
import AmazonProductInfo from '../Common/AmazonProductInfo';
import { InputRef } from 'antd/lib/input';

interface FBMCardProps {
  fbmItem: any;
  changeShipmentFee: (id: any, fee: any) => void;
  changeCustomer: (id: any, data: any) => void;
  changeDeliveryOption: (id: any, data: any) => void;
}

interface InputRefs {
  [key: string]: React.RefObject<InputRef>;
}

const FBMCard: React.FC<FBMCardProps> = ({ fbmItem, changeCustomer, changeShipmentFee, changeDeliveryOption }) => {
  const [country, setCountry] = useState(null);
  const [shipmentOfferLoading, setShipmentOfferLoading] = useState(false);
  const [selectedShipmentOffer, setSelectedShipmentOffer] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState(null);
  const [shipmentOffers, setshipmentOffers] = useState<any[]>([])
  const [CustomerUseForm] = Form.useForm();

  const inputRefs: InputRefs = {
    fullName: useRef<InputRef>(null),
    addressLine1: useRef<InputRef>(null),
    addressLine2: useRef<InputRef>(null),
    state: useRef<InputRef>(null),
    city: useRef<InputRef>(null),
    postCode: useRef<InputRef>(null),
    phoneNumber: useRef<InputRef>(null),
    email: useRef<InputRef>(null),
  };

  useEffect(() => {
    if (fbmItem && fbmItem.customer) {
      CustomerUseForm.setFieldsValue({
        fullName: fbmItem.customer.fullName,
        country: fbmItem.customer.country,
        addressLine1: fbmItem.customer.addressLine1,
        addressLine2: fbmItem.customer.addressLine2,
        state: fbmItem.customer.state,
        city: fbmItem.customer.city,
        postCode: fbmItem.customer.postalCode,
        phoneNumber: fbmItem.customer.phoneNumber,
        email: fbmItem.customer.email,
      });
    }
  }, [fbmItem]);

  const calcShipmentFee = () => {

    setShipmentOfferLoading(true);

    const items = [{ asin: fbmItem.asin, quantity: fbmItem.qty }];

    const standartPostObj = {
      country: country,
      deliveryOption: "STANDARD",
      productList: items,
    };

    const fedexPostObj = {
      country: country,
      deliveryOption: "FEDEX_ECONOMY",
      productList: items,
    };

    const expressPostObj = {
      country: country,
      deliveryOption: "EXPRESS",
      productList: items,
    };

    setshipmentOffers([]);

    Promise.all([
      OneamzAppService.calcShipmentFee(standartPostObj),
      OneamzAppService.calcShipmentFee(fedexPostObj),
      OneamzAppService.calcShipmentFee(expressPostObj)
    ])
      .then(responses => {

        let shipmentOffersData: any[] = [];
        responses.map((response: any) => {
          const request = JSON.parse(response.config.data);
          const data = response.data;
          if (data.code === 200) {

            let title = request.deliveryOption;
            if (request.deliveryOption === 'FEDEX_ECONOMY') {
              title = 'ECONOMY'
            }

            const shipmentOffer = {
              deliveryOption: request.deliveryOption,
              title: title,
              shipmentFee: data.totalShippingFee
            }
            shipmentOffersData.push(shipmentOffer);
          }
        });

        setshipmentOffers(shipmentOffersData);
        setShipmentOfferLoading(false);

      })
      .catch(error => {
        console.error(error);
        setShipmentOfferLoading(false);
      });

  }

  const onChange = (e: RadioChangeEvent) => {
    setSelectedShipmentOffer(e.target.value);
    changeDeliveryOption(fbmItem.id, shipmentOffers[e.target.value].deliveryOption)
    changeShipmentFee(fbmItem.id, shipmentOffers[e.target.value].shipmentFee)
  };

  const handleFormChange = useCallback(debounce((changedValues, allValues) => {
    console.log('Form changes processed:', allValues);
    const fieldName = Object.keys(changedValues)[0];
    changeCustomer(fbmItem.id, allValues);
    const fieldRef = inputRefs[fieldName];
    setTimeout(() => {
      if (fieldRef && fieldRef.current) {
        fieldRef.current.focus();
      }
    }, 100);
  }, 300), [changeCustomer, fbmItem.id, inputRefs]);


  useEffect(() => {
    calcShipmentFee();
  }, [country])

  const Header = () => {
    return <Row align={"middle"} justify={"space-between"} gutter={[24, 24]}>
      <Col span={10}>
        <Row align={'middle'} justify={"space-between"} gutter={[24, 24]}>
          <Col>
            <AmazonProductImage asin={fbmItem?.asin}></AmazonProductImage>
          </Col>
          <Col span={19} >
            <AmazonProductInfo asin={fbmItem?.asin} name={fbmItem?.name}></AmazonProductInfo>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <p className='text-sm fs-12 m-0'><b>BOYUTLAR</b></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0'>
              {fbmItem?.length} x {fbmItem?.width} x {fbmItem?.height} in
              <br />
              {fbmItem?.weight} lb
            </p>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <p className='text-sm fs-12 m-0'><b>STOK</b></p>
          </Col>
        </Row>
        <Row>
          <Col>
            {fbmItem.totalQty}
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <div className='mr-24'>
              <p className='text-sm fs-12 m-0'><b>ADET</b></p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{fbmItem?.qty}</Col>
        </Row>
      </Col>
    </Row>
  }

  const CustomerForm = () => {
    return <>
      <Form form={CustomerUseForm} layout="vertical" onValuesChange={handleFormChange}>
        <Row gutter={[16, 16]}>
          <Col md={6} sm={12} xs={12}>
            <Form.Item label={<b>Ad Soyad</b>} name="fullName" rules={[{ required: true, message: 'Ad Soyad doldurulması zorunludur' }]}>
              <Input className='amz-input' ref={inputRefs.fullName} defaultValue={fbmItem?.customer?.fullName} placeholder="Ad Soyad giriniz" />
            </Form.Item>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Form.Item label={<b>Ülke/Bölge</b>} name="country" rules={[{ required: true, message: 'Ülke seçimi zorunludur' }]}>
              <Select className='amz-input' placeholder="Ülke seçiniz" options={getCountries()} value={country} defaultValue={fbmItem?.customer?.country} onChange={(value: any) => { setCountry(value) }}>
              </Select>
            </Form.Item>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Form.Item label={<b>Adres 1</b>} name="addressLine1" rules={[{ required: true, message: 'Adres Satırı doldurulması zorunludur' }]}>
              <Input className='amz-input' ref={inputRefs.addressLine1} defaultValue={fbmItem?.customer?.addressLine1} placeholder="Adresi giriniz" />
            </Form.Item>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Form.Item label={<b>Adres 2</b>} name="addressLine2" >
              <Input className='amz-input'ref={inputRefs.addressLine2} defaultValue={fbmItem?.customer?.addressLine2} placeholder="Adresin devamını giriniz" />
            </Form.Item>
          </Col>

          <Col md={6} sm={12} xs={12}>
            <Form.Item label={<b>Eyalet</b>} name="state" rules={[{ required: true, message: 'Eyalet zorunludur' }]}>
              <Input className='amz-input' ref={inputRefs.state}  defaultValue={fbmItem?.customer?.state} placeholder="Eyalet giriniz." />
            </Form.Item>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Form.Item label={<b>Şehir</b>} name="city" rules={[{ required: true, message: 'Şehir zorunludur' }]}>
              <Input className='amz-input' ref={inputRefs.city}  defaultValue={fbmItem?.customer?.city} placeholder="Şehir giriniz" />
            </Form.Item>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Form.Item label={<b>Posta Kodu</b>} name="postCode" rules={[{ required: true, message: 'Posta Kodu doldurulması zorunludur' }]}>
              <Input className='amz-input' ref={inputRefs.postalCode} defaultValue={fbmItem?.customer?.postalCode} placeholder="Posta kodu giriniz" />
            </Form.Item>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Form.Item label={<b>Telefon</b>} name="phoneNumber" rules={[{ required: true, message: 'Telefon Numarası doldurulması zorunludur' }]}>
              <Input className='amz-input'  ref={inputRefs.phoneNumber} defaultValue={fbmItem?.customer?.phoneNumber} placeholder="Telefon giriniz" />
            </Form.Item>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Form.Item label={<b>E-posta</b>} name="email" rules={[{ required: true, type: 'email', message: 'E-posta doldurulması zorunludur' }]}>
              <Input className='amz-input'  ref={inputRefs.email} defaultValue={fbmItem?.customer?.email} placeholder="E-posta adresi giriniz" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  }

  const CargoForm = () => {
    return <>
      <h2 className='title'>Kargo Seçimi</h2>
      <Card loading={shipmentOfferLoading}>
        {(country === null) ?
          <Alert showIcon type='warning' description="Kargo teklifi için ülke seçimi yapınız" ></Alert>
          :
          (shipmentOffers.length > 0) ?
            <Radio.Group className='w-full' onChange={onChange} value={selectedShipmentOffer}>
              <Row gutter={[24, 24]} justify={"space-around"}>
                {shipmentOffers.map((offer: any, key) => (
                  <>
                    <Col span={7} key={key} >
                      <Row align={"top"} justify={"space-around"}>
                        <Col>
                          <Row align={"top"} justify={"space-around"}>
                            <Radio className='amz-radio border-slate-blue-02 border-radius-lg' value={key}>
                            </Radio>
                            <span><b>{offer?.title}</b></span>
                          </Row>
                        </Col>
                        <Col>
                          <p className='table-title m-0'>Toplam Tutar</p>
                          <p className='text fs-12 font-color-slate-blue-05 m-0'>${offer?.shipmentFee}</p>
                        </Col>
                      </Row>
                    </Col>
                    {(key < shipmentOffers.length - 1) &&
                      <Col>
                        <Divider type="vertical" style={{ height: '35px', borderInlineStartColor: '#c0c0c0' }} />
                      </Col>
                    }
                  </>
                  // <Radio value={key} style={{ marginBottom: '20px' }} >
                  //   <Row style={{ minWidth: "600px" }} justify={"space-between"} align={"middle"}>
                  //     <Col span={8}>
                  //       <h4>{offer?.deliveryOption}</h4>
                  //     </Col>
                  //     <Col span={8}>
                  //       <b>Toplam Tutar</b><br />
                  //       ${offer?.shipmentFee}
                  //     </Col>
                  //   </Row>
                  // </Radio>
                ))}
              </Row>

            </Radio.Group> : <Alert showIcon type='warning' description="Seçtiğiniz ülkeye kargo teklifi verilmemektedir" ></Alert>
        }
      </Card>
    </>
  }

  return (
    <div>
      <Collapse className='antd-order-detail-collapse bg-white border-radius-md mb-24 bg-cloud'
        expandIconPosition='end' defaultActiveKey={[fbmItem.id]} items={[{ key: fbmItem.id, label: Header(), children: <><CustomerForm></CustomerForm><CargoForm></CargoForm></> }]} />
    </div>
  )
}

export default FBMCard
