import { Button, Card, Col, Row } from 'antd'
import { NavLink } from 'react-router-dom'
import { ReactComponent as PlusIcon } from '../../../assets/Plus.svg';

const NewTicket = () => {
    const currentRole = localStorage.getItem('userRole');
    return (
        <>
            {/* {(currentRole === 'CUSTOMER') &&
                <Card className='border-radius-28 mb-24 antd-card-body-p-0 p-16'>
                    <Row justify={'center'}>
                        <Col><p className='text mt-16 mb-0'><b>Destek Talebi</b></p></Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='text-small mt-8 font-color-slate-blue-05 text-align-center'>
                                Yeni destek talepleri oluşturabilir, eski taleplerinizi görüntüleyebilirsiniz.
                            </p>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Col>
                            <NavLink to={'/tickets'}>
                                <Button className='btn btn-hover-dark bg-oneamz-yellow border-radius-12'>
                                    <Row align={'middle'}>
                                        <Col span={4}>
                                            <PlusIcon className='wh-16 mr-8' />
                                        </Col>
                                        <Col span={20}>
                                            <p className='m-0 text-small'> Yeni Talep Oluştur</p>
                                        </Col>
                                    </Row>
                                </Button>
                            </NavLink>
                        </Col>
                    </Row>
                </Card>} */}
            {(currentRole === 'ADMIN') &&
                <Card className='border-radius-28 mb-24 antd-card-body-p-0 p-16'>
                    <Row justify={'center'}>
                        <Col><p className='text mt-16 mb-0'><b>Destek Talebi</b></p></Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='text-small mt-8 font-color-slate-blue-05 text-align-center'>
                                Gelen Destek Taleplerini Yönetin!
                            </p>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Col>
                            <NavLink to={'/tickets'}>
                                <Button className='btn btn-hover-dark bg-oneamz-yellow border-radius-12'>
                                    <Row align={'middle'}>
                                        <Col span={24}>
                                            <p className='m-0 text-small'>Talepleri Görüntüle</p>
                                        </Col>
                                    </Row>
                                </Button>
                            </NavLink>
                        </Col>
                    </Row>
                </Card>}
        </>
    )
}

export default NewTicket