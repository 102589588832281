import { Col, Row, Image } from 'antd';
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SettingOutlined, CommentOutlined } from '@ant-design/icons'
import LoginIcon from '../../../assets/icons/login.svg';
import { ReactComponent as NHomeIcon } from '../../../assets/V2/LayoutV2/dashboard-icon.svg';
import { ReactComponent as HomeActive } from '../../../assets/V2/LayoutV2/dashboard-active.svg';
import { ReactComponent as FBAIcon } from '../../../assets/V2/LayoutV2/fba-icon.svg';
import { ReactComponent as FBAActive } from '../../../assets/V2/LayoutV2/fba-active.svg';
import { ReactComponent as FBMIcon } from '../../../assets/V2/LayoutV2/fbm-icon.svg';
import { ReactComponent as FBMActive } from '../../../assets/V2/LayoutV2/fbm-active.svg';
import { ReactComponent as InventoryIcon } from '../../../assets/V2/LayoutV2/inventory-icon.svg';
import { ReactComponent as InventoryActive } from '../../../assets/V2/LayoutV2/inventory-active.svg';
import { ReactComponent as WalletIcon } from '../../../assets/V2/LayoutV2/wallet-icon.svg';
import { ReactComponent as WalletActive } from '../../../assets/V2/LayoutV2/wallet-active.svg';
import { ReactComponent as CustomerIcon } from '../../../assets/V2/LayoutV2/customer-icon.svg';
import { ReactComponent as CustomerActive } from '../../../assets/V2/LayoutV2/customer-active.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/V2/LayoutV2/settings-icon.svg';
import { ReactComponent as SettingsActive } from '../../../assets/V2/LayoutV2/settings-active.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/V2/LayoutV2/profile-icon.svg';
import { ReactComponent as ProfileActive } from '../../../assets/V2/LayoutV2/profile-active.svg';
import { refrestToken } from '../../../utils';
import { VideoCameraOutlined, ProjectOutlined } from '@ant-design/icons';

const Menu = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [role, setRole] = useState<'ADMIN' | 'WAREHOUSE_MANAGER' | 'CUSTOMER'>('CUSTOMER');
    const [urlPath, setUrlPath] = useState('');

    const activeFBAPaths = ['/fba/new'];
    const activeFBMPaths = ['/fbm/new'];
    const activeStoragePaths = ['/storage/new'];

    useEffect(() => {
        refrestToken();
    }, [location]);

    useEffect(() => {
        const storedRole = localStorage.getItem('userRole');
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken || accessToken == 'null' || accessToken === null) {
            logout();
        }

        if (storedRole === 'ADMIN' || storedRole === 'WAREHOUSE_MANAGER' || storedRole === 'CUSTOMER') {
            setRole(storedRole);
        }
        setUrlPath(location.pathname);
    }, [location.pathname]);

    function logout() {
        localStorage.removeItem('externalAccessToken');
        localStorage.removeItem('externalRefreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('email');
        localStorage.removeItem('accessTokenExpiredAt');
        localStorage.removeItem('refreshTokenExpiredAt');
        navigate('/login')
    }

    const checkPathIsActive = () => location.pathname.startsWith('/settings');

    return (
        <>
            <Row className=''>
                <Col span={24}>
                    <div className='menu-wrapper w-full'>
                        <NavLink className={({ isActive }) =>
                            isActive ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''
                        } to={'/'}>
                            <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                                <Col>
                                    {location.pathname === '/' ? <HomeActive className='mt-8' /> : <NHomeIcon className='mt-8' />}
                                </Col>
                                <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                    <p className='text-small  font-color-slate-blue-05'>Dashboard</p>
                                </Col>
                            </Row>
                        </NavLink>
                    </div>
                </Col>
                {['ADMIN', 'WAREHOUSE_MANAGER'].includes(role) && (
                    <div className='menu-wrapper w-full'>
                        <NavLink className={({ isActive }) =>
                            isActive ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''
                        } to={'/storage'}>
                            <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                                <Col>
                                    {activeFBMPaths.includes(location.pathname) ? (
                                        <FBMActive className='mt-8' />
                                    ) : location.pathname === '/storage' ? (
                                        <FBMActive className='mt-8' />
                                    ) : (
                                        <FBMIcon className='mt-8' />
                                    )}
                                </Col>
                                <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                    <p className='text-small  font-color-slate-blue-05'>Mal Kabul</p>
                                </Col>
                            </Row>
                        </NavLink>
                    </div>
                )}
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''
                    } to={'/fba'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                {activeFBAPaths.includes(location.pathname) ? (
                                    <FBAActive className='mt-8' />
                                ) : location.pathname === '/fba' ? (
                                    <FBAActive className='mt-8' />
                                ) : (
                                    <FBAIcon className='mt-8' />
                                )}
                            </Col>
                            <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                <p className='text-small  font-color-slate-blue-05'>FBA</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''
                    } to={'/fbm'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                {activeFBMPaths.includes(location.pathname) ? (
                                    <FBMActive className='mt-8' />
                                ) : location.pathname === '/fbm' ? (
                                    <FBMActive className='mt-8' />
                                ) : (
                                    <FBMIcon className='mt-8' />
                                )}
                            </Col>
                            <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                <p className='text-small  font-color-slate-blue-05'>FBM</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''
                    } to={'/inventory'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                {activeStoragePaths.includes(location.pathname) ? (
                                    <InventoryActive className='mt-8' />
                                ) : location.pathname === '/inventory' ? (
                                    <InventoryActive className='mt-8' />
                                ) : (
                                    <InventoryIcon className='mt-8' />
                                )}
                            </Col>
                            <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                <p className='text-small  font-color-slate-blue-05'>Envanter</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
                {['CUSTOMER'].includes(role) && (
                    <>
                        <div className='menu-wrapper w-full'>
                            <NavLink className={({ isActive }) =>
                                isActive ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''
                            } to={'/wallet'}>
                                <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                                    <Col>
                                        {location.pathname === '/wallet' ? <WalletActive className='mt-8' /> : <WalletIcon className='mt-8' />}
                                    </Col>
                                    <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                        <p className='text-small font-color-slate-blue-05'>Cüzdan</p>
                                    </Col>
                                </Row>
                            </NavLink>
                        </div>
                        <div className='menu-wrapper w-full'>
                            <NavLink className={({ isActive }) =>
                                isActive ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''
                            } to={'/education'}>
                                <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                                    <Col>
                                        <VideoCameraOutlined style={{ fontSize: "20px", color: "rgba(	51, 62, 72, 0.5)" }} size={24} />
                                    </Col>
                                    <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                        <p className='text-small font-color-slate-blue-05'>Eğitim</p>
                                    </Col>
                                </Row>
                            </NavLink>
                        </div>
                        <div className='menu-wrapper w-full'>
                            <NavLink className={({ isActive }) =>
                                isActive ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''
                            } to={'/fba-warehouses'}>
                                <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                                    <Col>
                                        <ProjectOutlined style={{ fontSize: "20px", color: "rgba(	51, 62, 72, 0.5)" }} size={24} />
                                    </Col>
                                    <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                        <p className='text-small font-color-slate-blue-05'>Amazon FBA Depo</p>
                                    </Col>
                                </Row>
                            </NavLink>
                        </div>


                    </>
                )}
                {['ADMIN', 'WAREHOUSE_MANAGER'].includes(role) && (
                    <div className='menu-wrapper w-full'>
                        <NavLink className={({ isActive }) =>
                            isActive ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''
                        } to={'/customers'}>
                            <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                                <Col>
                                    {location.pathname === '/customers' ? <CustomerActive className='mt-8' /> : <CustomerIcon className='mt-8' />}
                                </Col>
                                <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                    <p className='text-small font-color-slate-blue-05'>Müşteriler</p>
                                </Col>
                            </Row>
                        </NavLink>
                    </div>
                )}
                {['ADMIN'].includes(role) && (
                    <div className='menu-wrapper w-full'>
                        <NavLink
                            className={checkPathIsActive() ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''}
                            to={'/settings/services'}
                        >
                            <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                                <Col>
                                    {checkPathIsActive() ? <SettingsActive className='mt-8' /> : <SettingsIcon className='mt-8' />}
                                </Col>
                                <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                    <p className='text-small font-color-slate-blue-05'>Ayarlar</p>
                                </Col>
                            </Row>
                        </NavLink>
                    </div>
                )}
                {/* <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'bg-slate-blue-02 border-radius-md w-full menu-fc-slate-blue' : ''
                    } to={'/profile'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                {location.pathname === '/profile' ? <ProfileActive className='mt-8' /> : <ProfileIcon className='mt-8' />}
                            </Col>
                            <Col style={{ color: 'black', fontWeight: 'bold' }}>
                                <p className='text-small font-color-slate-blue-05'>Profil</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div> */}
            </Row >
        </>
    )
}

export default Menu