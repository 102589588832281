import { Button, Col, Divider, InputNumber, Modal, Popconfirm, Row, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import ServicesService from '../../../services/ServicesService';
import type { PopconfirmProps } from 'antd';
import { formatMoney, openNotification } from '../../../utils';
import ClaimOrderService from '../../../services/ClaimOrderService';
import { useNavigate } from 'react-router-dom';

interface ServiceListProps {
    product: any,
}

const OrderDetailServiceList: React.FC<ServiceListProps> = ({ product }) => {

    const navigate = useNavigate();



    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderItemServices, setOrderItemServices] = useState<any>([]);

    useEffect(() => {
        getOrderItemServices();
    }, [])

    const setQty = (orderItemId: number, serviceId: number, value: number) => {
        const orderItemServiceUpdated = orderItemServices.map((service: any) => {
            console.log(service);
            if (serviceId == service.id) {
                service.qty = value;
            }
            return service;
        });

        console.log(orderItemServiceUpdated);
        setOrderItemServices(orderItemServiceUpdated);
    };

    const getOrderItemServices = () => {
        ServicesService.getOrderItemServices()
            .then((response: any) => {
                let items = response.data;
                items = items.sort((a: any, b: any) => a.id - b.id);
                const orderItemServiceUpdated = items.map((service: any) => ({
                    ...service,
                    qty: 0
                }));
                setOrderItemServices(orderItemServiceUpdated);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const confirm: PopconfirmProps['onConfirm'] = (e) => {
        let list = [];
        for (let i = 0; i < orderItemServices.length; i++) {
            const serviceItem = orderItemServices[i];
            if (serviceItem.qty > 0) {
                list.push({ "generalServiceId": serviceItem.id, "qty": serviceItem.qty});
            }
        }

        ClaimOrderService.addService(product.id, { "claimOrderItemGeneralServices": list })
            .then((response: any) => {
                openNotification('success', 'Başarılı', "Ek hizmet eklendi");
                setTimeout(() => {
                navigate(0);
                }, 1000);
            })
            .catch((e: Error) => {
                console.log(e);
            });

    };

    const cancel: PopconfirmProps['onCancel'] = (e) => {
    };

    return (
        <>
            <Button
                className='btn bg-slate-blue font-color-cloud btn-hover-white btn-text border-radius-lg' size="small" type="primary"
                onClick={showModal}
                icon={<PlusOutlined />}
            >
                Ekle
            </Button>
            <Modal title="Ek Hizmetler"
                onCancel={handleOk} // onCancel da handleOk ile aynı işlevi görecek şekilde ayarlandı
                footer={[
                ]}
                open={isModalOpen} onOk={handleOk}>
                <div className='px-16'>
                    <Row>
                        <Col span={9}><p className='text'><b>Hizmet Adı</b></p></Col>
                        <Col span={5}><p className='text'><b>Birim Fiyatı</b></p></Col>
                        <Col span={5}><p className='text'><b>Adet</b></p></Col>
                        <Col span={5}><p className='text'><b>Toplam</b></p></Col>
                    </Row>
                    {orderItemServices.map((service: any, index: any) => (
                        (service.isOptional && service.id !== 1) ? (
                            <React.Fragment key={index}>
                                <Row align={'middle'}>
                                    <Col span={9}><p className='text'>{service?.name}</p></Col>
                                    <Col span={5}>
                                        <Row justify={'center'}>
                                            <Col>
                                                <p className='text'>{formatMoney(service.fee)}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={5}>
                                        {(service.id === 1) ? <>
                                            <Switch defaultChecked={service.qty > 0} onChange={(value: any) => { }} />
                                        </> : <p>
                                            <InputNumber name='serviceQty' style={{ marginRight: '10px' }} min={0} max={(!service.isPerUnit) ? 1 : product.qty} onChange={(value: any) => setQty(product.id, service.id, value)} defaultValue={service.qty} />
                                        </p>}
                                    </Col>
                                    <Col span={5}>
                                        <Row justify={'center'}>
                                            <Col>
                                                <p className='text'>{formatMoney(service.fee * service.qty)}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider className='m-0' />
                            </React.Fragment>) : (<></>)
                    ))}
                    <Row justify={'end'} gutter={16} className="mt-24">
                        <Col>
                            <Button onClick={handleCancel} className='btn btn-hover-dark btn-text border-radius-lg bg-cloud border-slate-blue font-color-slate-blue'>İptal</Button>
                        </Col>
                        <Col>
                            <Popconfirm
                                title="İşleme devam etmek istiyor musunuz?"
                                onConfirm={confirm}
                                onCancel={cancel}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                                <Button className='btn btn-hover-white btn-text border-radius-lg bg-slate-blue font-color-cloud'>Kaydet</Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

export default OrderDetailServiceList