import { Card, Col, Row } from 'antd'
import TotalBalance from './TotalBalance'
import AddCredit from './AddCredit'

const Wallet = () => {
    
    return (
        <>
            <Card className='bg-cloud border-radius-md'>
                <Row>
                    <Col>
                        <h3 className='h-3 m-0'>Cüzdan</h3>
                    </Col>
                </Row>
                <Row gutter={[24,24]} className='mt-24'>
                    <Col lg={12} md={24}>
                        <TotalBalance></TotalBalance>
                    </Col>
                    <Col lg={12} md={24}>
                        <AddCredit></AddCredit>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default Wallet