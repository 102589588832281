import { Avatar, Card, Col, Row, } from 'antd'
import React from 'react'
import { formatDateTime } from '../../../utils';
import { NavLink } from 'react-router-dom';
import { IResponseOrder } from '../../../types/Order';
import AmazonProductImageAvatar from '../../Common/AmazonProductImageAvatar';
import { ReactComponent as CopyIcon } from '../../../assets/V2/CardIcons/copy-icon.svg'
import { ReactComponent as StickerIcon } from '../../../assets/V2/CardIcons/sticker-icon.svg'
import { ReactComponent as PrinterIcon } from '../../../assets/V2/CardIcons/printer-icon.svg'
import { ReactComponent as DeleteFileIcon } from '../../../assets/V2/CardIcons/delete-file-icon.svg'
import { ReactComponent as DetailIcon } from '../../../assets/V2/CardIcons/detail-icon.svg'

interface ShipmentListCardProps {
    order: IResponseOrder;
}

const ShipmentListCard: React.FC<ShipmentListCardProps> = ({ order }) => {
    const statusList = [
        { value: '', label: 'Seçiniz' },
        { value: 'INCOMING', label: (order.orderType === 'FBM_SHIPMENT') ? 'Depo Onayı bekliyor' : 'OneAmz Deposuna Ulaşması Bekleniyor' },
        { value: 'WAITING', label: 'OneAmz Deposunda Beklemede' },
        { value: 'PROCESSING', label: 'OneAmz Deposunda İşlemde' },
        { value: 'COMPLETE', label: 'OneAmz Deposundan Gönderilmeye Hazır' },
        { value: 'APPROVED', label: 'OneAmz Deposu Gönderiyi Onayladı.' },
        { value: 'SHIPPED', label: 'Kargo Firması Teslim Aldı' }
    ];

    const statusColors: any = {
        'INCOMING': 'red',
        'WAITING': 'orange',
        'PROCESSING': 'purple',
        'COMPLETE': 'blue',
        'APPROVED': 'lime',
        'SHIPPED': 'green',
        '': 'default' // Boş durumlar için varsayılan renk
    };

    const statusLabels = statusList.reduce((acc: any, status) => {
        acc[status.value] = status.label;
        return acc;
    }, {});

    return (
        <>
            <Card className='border-radius-md w-full p-24'>
                <Row align={'middle'} justify={'space-between'} gutter={[16, 16]}>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text-sm fs-12 bg-cloud p-8 d-inline border-radius-xs'><b>GÖNDERİ KODU</b></p>
                        <p className='pretitle fs-16 mb-0'>{order.orderCode}</p>
                    </Col>
                    <Col xl={4} md={8} xs={24}>
                        <Avatar.Group maxCount={2}>
                            {order.items.map((item: any) => (
                                <AmazonProductImageAvatar orderItem={item}></AmazonProductImageAvatar>
                            ))}
                        </Avatar.Group>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text-sm fs-12 m-0'><b>OLUŞTURMA TARİHİ</b></p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'>{formatDateTime(order.createdAt.toLocaleString())}</p>
                    </Col>
                    <Col xl={2} md={8} xs={12}>
                        <p className='text-sm fs-12 m-0'><b>MÜŞTERİ</b></p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'><b>#{order?.user?.id}</b> {order?.user?.firstName} {order?.user?.lastName}</p>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <Row align={'middle'}>
                            <Col span={3}>
                                <div className={`bg-${statusColors[order.orderStatus]} wh-8 border-radius-lg`}></div>
                            </Col>
                            <Col span={21}>
                                <p className='text-small '>{statusLabels[order.orderStatus] || order.orderStatus}</p>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col xl={2}>
                        <OrderAlert order={order} />
                    </Col> */}
                    <Col xl={5}>
                        <Row className='pt-12' justify={'space-between'}>
                            <Col>
                                <CopyIcon className='pointer' />
                            </Col>
                            <Col>
                                <StickerIcon className='pointer' />
                            </Col>
                            <Col>
                                <NavLink to={order.printUrl}>
                                    {(order.printUrl === '') ? <PrinterIcon style={{opacity: '0.3'}} /> : <PrinterIcon />}
                                    
                                </NavLink>
                            </Col>
                            <Col>
                                <DeleteFileIcon className='pointer' />
                            </Col>
                            <Col>
                                <NavLink to={order.detailUrl}>
                                    <DetailIcon />
                                </NavLink>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default ShipmentListCard