import { Avatar, Button, Card, Col, Row, Image } from 'antd'
import UpImg from '../../../assets/V2/WalletV2/Up.svg'
import DownImg from '../../../assets/V2/WalletV2/down.svg'

interface TransactionCardProps {
    transaction: any;
}

const TransactionCard: React.FC<TransactionCardProps> = ({ transaction }) => {
    return (
        <>
            <Card className='border-radius-md bg-pure-white w-full mt-24'>
                <Row align={'middle'} justify={'space-between'}>
                    <Col xl={4} md={8} xs={12}>
                        <Row align={'middle'} gutter={24}>
                            <Col>
                                {transaction.amount > 0 ? (
                                    <Avatar className='bg-success-02 pt-4' size={48}><Image src={UpImg} alt="up" /></Avatar>
                                ) : (
                                    <Avatar className='bg-error-02 pt-4' size={48}><Image src={DownImg} alt="up" /></Avatar>
                                )}
                            </Col>
                            <Col>
                                <p className='text mb-0'><b>{transaction.amount > 0 ? "Bakiye Ekleme" : "Gönderi"}</b></p>
                                <p className='text-small mt-0'>{transaction.date}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text mb-0'><b>Açıklama</b></p>
                        <p className='text-small mt-0'>{transaction.description}</p>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text mb-0'><b>Ücret</b></p>
                        <p className='text-small  mt-0'>{transaction.amount} $</p>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text mb-0'><b>Kalan Bakiye</b></p>
                        <p className='text-small mt-0'>${transaction.balance}</p>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default TransactionCard