import { Avatar, Button, Card, Col, Divider, Empty, Modal, Radio, RadioChangeEvent, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import OneamzAppService from '../../../services/OneamzAppService';
import { openNotification } from '../../../utils';

interface ProgressCardProps {
    currentStep: number;
    setFBAShipmentId: (data: any, selectUserAccount: any) => void;
}

const ProgressCard: React.FC<ProgressCardProps> = ({ currentStep, setFBAShipmentId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userAccounts, setUserAccounts] = useState([]);
    const [selectUserAccount, setSelectUserAccount] = useState("");
    const [FBAShipmentList, setFBAShipmentList] = useState([]);
    const [shipmentId, setShipmentId] = useState<any>("");
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUserAccounts();
    }, [])

    useEffect(() => {
        setFBAShipmentList([]);
        if (selectUserAccount != "") {
            getAmazonFbaShipmentsByUserAccountId(selectUserAccount, "WORKING");
        }
    }, [selectUserAccount])

    const getAmazonFbaShipmentsByUserAccountId = (userAccountId: string, status: string) => {
        setLoading(true);
        OneamzAppService.getAmazonFbaShipmentsByUserAccountId(selectUserAccount, status)
            .then((response: any) => {
                let data = response.data.payload.shipmentData;
                setFBAShipmentList(data)
                setLoading(false);
            })
            .catch((e: Error) => {
                setLoading(false);
            });
    };

    const getUserAccounts = () => {
        OneamzAppService.getUserAccounts()
            .then((response: any) => {
                let data = response.data.userAccounts;
                const newArray = data.map((item: any) => {
                    return { value: item.id, label: `${item.marketplaceName} ` };
                });
                setUserAccounts(newArray);
            })
            .catch((e: Error) => {
            });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const getStepBgColor = (step: number) => {
        return currentStep >= step ? 'bg-slate-blue' : 'bg-slate-blue-05';
    };

    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setShipmentId(e.target.value)
    };

    const save = () => {
         FBAShipmentList.map((FBAShipment: any) => {
            if(FBAShipment.shipmentId === shipmentId){
                setFBAShipmentId(FBAShipment, selectUserAccount);
                openNotification('success', 'Başarılı', 'Amazon deposu seçildi ve FBA Label eklendi.')
                setIsModalOpen(false);
            }
        });
    };

    return (
        <>
            <Modal title='Amazon FBA Shipment List'
                onCancel={handleOk} // onCancel da handleOk ile aynı işlevi görecek şekilde ayarlandı
                footer={[
                ]}
                open={isModalOpen} onOk={handleOk}
            >
                <p className='text font-color-slate-blue'>FBA Gönderilerinizi Otomatik Eklemek İçin Mağaza Seçin</p>
                <Select onChange={(userAccount) => setSelectUserAccount(userAccount)}
                    options={[{ value: "", label: "Seçiniz" }, ...userAccounts]}
                    className='border-slate-blue border-radius-8 w-full btn amz-select-input' placeholder={<p className='text font-color-slate-blue'>Mağazanızı Seçin</p>}></Select>
                <Card loading={loading} className='w-full mt-24 mb-24'>
                    {(FBAShipmentList.length > 0) && <Radio.Group className='w-full' onChange={onChange} value={shipmentId} >
                        {FBAShipmentList.map((FBAShipment: any) => (
                            <>
                                <Row align={'top'} justify={'space-between'}>
                                    <Col span={2}>
                                        <Radio className='amz-radio border-slate-blue-02 border-radius-lg mt-16' value={FBAShipment.shipmentId}></Radio>
                                    </Col>
                                    <Col span={6}>
                                        <p className='table-title m-0'>SHIPMENT ID</p>
                                        <p className='text fs-12 font-color-slate-blue-05 m-0'> {FBAShipment.shipmentId}</p>
                                    </Col>
                                    <Col span={5}>
                                        <p className='table-title m-0'>GÖNDERİLEN DEPO KODU</p>
                                        <p className='text fs-12 font-color-slate-blue-05 m-0'> {FBAShipment.destinationFulfillmentCenterId}</p>
                                    </Col>
                                    <Col span={8}>
                                        <p className='table-title m-0'>SHIPMENT NAME</p>
                                        <p className='text fs-12 font-color-slate-blue-05 m-0'> {FBAShipment.shipmentName}</p>
                                    </Col>

                                </Row>
                            </>
                        ))}
                    </Radio.Group>}
                    {(selectUserAccount == '') ? <Empty description={<h3>FBA Gönderilerini listelemek için mağaza seçiniz</h3>} image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <></>}
                    {(FBAShipmentList.length == 0 && selectUserAccount) ? <Empty description={<h3>Seçtiğiniz mağazada, FBA gönderisi mevcut değil</h3>} image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <></>}
                </Card>
                <Row gutter={16}>
                    <Col span={12}>
                        <Button onClick={() => setIsModalOpen(false)} className='btn border-slate-blue btn-hover-dark btn-text w-full'>İptal</Button>
                    </Col>
                    <Col span={12}>
                        <Button className='btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud w-full' onClick={save}>Onayla</Button>
                    </Col>
                </Row>
            </Modal>
            <Card className='create-fba-progress-card bg-slate-blue border-radius-md'>
                <Row justify={'space-between'} align={'middle'}>
                    <Col span={24}>
                        <p className='pretitle font-color-oneamz-yellow'>OneAMZ Fulfillment Uygulamasını Kullanarak Amazonda Büyü!</p>
                        {currentStep === 0 &&
                            <Row justify={'space-between'}>
                                <Col>
                                    <div>
                                        <h3 className='h-3 font-color-pure-white m-0'>FBA Gönderim Sürecinizi</h3>
                                        <h3 className='h-3 font-color-pure-white mt-0'>Hemen Başlatın!</h3>
                                    </div>
                                </Col>
                                <Col>
                                    <Button onClick={showModal} className='btn btn-hover-dark btn-text font-color-slate-blue border-radius-lg'>
                                        Amazon FBA Shipment List
                                    </Button>
                                </Col>
                            </Row>
                        }
                        {currentStep === 1 &&
                            <div>
                                <h3 className='h-3 font-color-pure-white m-0'>Özel Hizmet ve Kargo </h3>
                                <h3 className='h-3 font-color-pure-white mt-0'>Firması Seçimi</h3>
                            </div>
                        }
                        {currentStep === 2 &&
                            <div>
                                <h3 className='h-3 font-color-pure-white m-0'>Paket Önizlemesi ve </h3>
                                <h3 className='h-3 font-color-pure-white mt-0'>Etiketleme</h3>
                            </div>
                        }
                        {currentStep === 3 &&
                            <div>
                                <h3 className='h-3 font-color-pure-white m-0'>Tebrikler! Gönderi Sürecinde</h3>
                                <h3 className='h-3 font-color-pure-white mt-0'>Son Adıma Ulaştınız</h3>
                            </div>
                        }

                        <Card>
                            <p className='text m-0'><b>FBA Gönderi Süreci</b></p>
                            <p className='text fs-12 mt-0'>4 adımdan
                                {currentStep === 0 && " 1'i "}
                                {currentStep === 1 && " 2'si "}
                                {currentStep === 2 && " 3'ü "}
                                {currentStep === 3 && " 4'ü "}
                                tamamlandı.</p>
                            <Row gutter={5}>
                                {[0, 1, 2, 3].map(step => (
                                    <Col key={step} span={6}>
                                        <div className={`height-6 ${getStepBgColor(step)} border-radius-md`}></div>
                                    </Col>
                                ))}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default ProgressCard