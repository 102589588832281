import React from 'react'
import getItemImg from '../../../assets/V2/FBAV2/fba-create-card.png';
import { Card, Col, Row, Image } from 'antd';

const GetItemtoWarehouse = () => {
    return (
        <>
            <Card className='bg-slate-blue-05 pointer border-radius-md'>
                <Row align={'middle'} gutter={24}>
                    <Col xxl={6} lg={12}>
                        <Image preview={false} src={getItemImg}></Image>
                    </Col>
                    <Col xxl={18} lg={12}>
                        <p className='subtitle font-color-pure-white'><b>Depo</b>'ya Ürün Çek</p>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default GetItemtoWarehouse