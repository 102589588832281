import http from "../http-common";
import { IProductRequest, IProductResponse } from "../types/Product";

const getAll = () => {
  return http.get<Array<IProductResponse>>(`/products`);
};

const getAllCount = () => {
  return http.get<Array<IProductResponse>>(`/products/count`);
};

const get = (productId: number) => {
  return http.get<IProductResponse>(`/products/${productId}`);
};

const getbyASIN = (asinCode: number) => {
  const obj = {
    asin: asinCode
  };
  return http.put<IProductResponse>(`/products`, obj);
};

const update = (productId: any, data: IProductRequest) => {
  return http.patch<any>(`/products/${productId}`, data);
};

const create = ( data: IProductRequest) => {
  return http.post<any>(`/products`, data);
};

const remove = (productId: any) => {
  return http.delete<any>(`/products/${productId}`);
};

const ProductService = {
  getAll,
  getAllCount,
  get,
  getbyASIN,
  create,
  update,
  remove,
};

export default ProductService;