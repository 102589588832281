import React, { useEffect, useState } from 'react';
import { Col, Row, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { openNotification } from '../../utils';
import { ReactComponent as UploadImage } from '../../assets/V2/OrderDetailV2/upload.svg';
import { ReactComponent as SuccessIcon } from '../../assets/V2/OrderDetailV2/succes-icon-outline.svg';


interface LabelUploadProps {
    setLabel: (file: any) => void;
    fbaLabel: any,
    product?: any;
    type: any;
}

const LabelUpload: React.FC<LabelUploadProps> = ({ setLabel, product, type, fbaLabel }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    useEffect(() => {
        if (type === 'fnsku' && product?.fnskuLabel != null) {
            setFileList([product?.fnskuLabel])
        }

        if (type === 'fba' && fbaLabel != null) {
            setFileList([fbaLabel])
        }
    }, [])


    useEffect(() => {
        if (fileList.length > 0) {
            setLabel(fileList[0]);
        } else {
            setLabel(null);
        }
    }, [fileList])

    const props: UploadProps = {
        onRemove: (file) => {
            setFileList([]);
        },
        beforeUpload: (file) => {
            if (fileList.length >= 1) {
                openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
                // If there's already a file in the list, reject the new file
                return Upload.LIST_IGNORE;
            }
            // If not, add the new file to the list
            setFileList([file]);
            return false;
        },
        fileList,
        defaultFileList: fileList, // Show the initial file list
        accept: '.pdf', // Accept only PDF files
        multiple: false, // Disable multiple file selection
    };

    return (
        <>
            <Upload {...props} className='fnsku-label-upload'>
                {(fileList.length > 0) ?
                    <Row gutter={16} align={'middle'} className='pointer'>
                        <Col>
                            <div className='wh-48 pl-8 pt-8 bg-cloud border-radius-lg'>
                                <SuccessIcon ></SuccessIcon>
                            </div>
                        </Col>
                        <Col>
                            <p className='link mb-0 pointer' onClick={() => {
                                setFileList([]);
                            }}>{(type == 'fnsku') ? "FNSKU" : "FBA"} Etiketini Değiştir</p>
                            <p className='text fs-14 mt-0 font-color-slate-blue'>{fileList[0]?.name}</p>
                        </Col>
                    </Row>
                    : <Row gutter={16} align={'middle'} className='pointer'>
                        <Col>
                            <div className='wh-48 pl-12 pt-12 bg-slate-blue-02 border-radius-lg'>
                                <UploadImage ></UploadImage>
                            </div>
                        </Col>
                        <Col>
                            <p className='link mb-0 pointer'>{(type == 'fnsku') ? "FNSKU" : "FBA"} Etiketini Yükleyin</p>
                            <p className='text fs-14 mt-0 font-color-slate-blue'>PDF formatı, maksimum 5MB</p>
                        </Col>
                    </Row>}
            </Upload>
        </>
    );
}

export default LabelUpload;
