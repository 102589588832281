import { Avatar, Button, Card, Col, Row } from 'antd'
import React from 'react'
import { formatDateTime } from '../../../utils'

interface CustomerCardProps {
    user: any;
}
const CustomersCard: React.FC<CustomerCardProps> = ({ user }) => {
    return (
        <>
            <Card className='border-radius-md w-full'>
                <Row align={'middle'} justify={'space-between'}>
                    <Col xl={4} md={8} xs={24}>
                        <Row align={'middle'} gutter={24}>
                            <Col>
                                <Avatar size={48}>{user?.firstName?.charAt(0)}</Avatar>
                            </Col>
                            <Col>
                                <p className='text fs-16 mb-0'><b>{user?.firstName} {user?.lastName}</b></p>
                                <p className='text-sm fs-14 mt-0 text font-color-slate-blue-05'>#{user?.id}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4} md={8} xs={24}>
                        <p className='text fs-16 mb-0'><b>E-Posta</b></p>
                        <p className='text-sm fs-14 text mt-0 font-color-slate-blue-05'>{user?.email}</p>
                    </Col>
                    <Col xl={4} md={8} xs={24}>
                        <p className='text fs-16 mb-0'><b>Cep Telofonu</b></p>
                        <p className='text-sm fs-14 text mt-0 font-color-slate-blue-05'>{user?.phoneNumber}</p>
                    </Col>
                    <Col xl={4} md={8} xs={24}>
                        <p className='text fs-16 mb-0'><b>Storage Box</b></p>
                        <p className='text-sm fs-14 text mt-0 font-color-slate-blue-05'>{user?.storageLevel}</p>
                    </Col>
                    <Col xl={4} md={8} xs={24}>
                        <p className='text fs-16 mb-0'><b>Oluşturulma Tarihi</b></p>
                        {user?.createdAt === null ?
                            <p className='text-sm fs-14 text mt-0 font-color-slate-blue-05'>Oluşturulma Tarihi yok</p> :
                            <p className='text-sm fs-14 text mt-0 font-color-slate-blue-05'>{formatDateTime(user?.createdAt)}</p>
                        }
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default CustomersCard