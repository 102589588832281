
import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Image, Empty, Alert, Divider, Avatar, Tooltip } from 'antd';
import AmazonFba from '../../assets/amazon-fba.png'
import OneamzShip from '../../assets/oneamz-ship.svg'
import TextArea from 'antd/es/input/TextArea';
import { formatMoney } from '../../utils';
import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg';
import { ReactComponent as InfoIconWhite } from '../../assets/info-icon-white.svg';
import AmazonProductImage from '../Common/AmazonProductImage';
import AmazonProductInfo from '../Common/AmazonProductInfo';
import { ReactComponent as WarehouseIcon } from '../../assets/V2/FBACreateV2/warehouse-icon.svg'

interface FBACreateStepProps {
    productList: []
    totalQty: 0,
    totalWeight: 0,
    country: null,
    totalDeci: 0,
    warehouse: null,
    warehouseList: [],
    totalFulfillmentCost: 0,
    FBAPrepServiceFee: 0,
    totalCustomServiceCost: 0,
    shipmentFee: any,
    totalFBACost: any,
    totalServiceCost: any,
    totalFulfillmentCostPerUnit: 0,
    shipmentCompany: any,
    setNote: (note: any) => void;
    orderServices: []
}

const FBACreateStep4: React.FC<FBACreateStepProps> = ({ warehouse, totalFBACost, totalServiceCost, country, orderServices, FBAPrepServiceFee, totalCustomServiceCost, setNote, shipmentCompany, shipmentFee, productList, totalQty, totalFulfillmentCost, totalFulfillmentCostPerUnit, warehouseList }) => {
    const [warehouseText, setWarehouseText] = useState<any>(null)

    useEffect(() => {

        const foundWarehouse = warehouseList.find((item: any) => item.value === warehouse);
        console.log('warehouse', warehouse);
        setWarehouseText(foundWarehouse);

    }, [warehouse])

    return <>
        <Alert
            className='border-none border-radius-md p-24 mt-24'
            description={<p className='text fs-14 m-0'>
                Kargo ödeme tutarı Amazon'dan alınan ölçü bilgilerine göre hesaplanmaktadır.
                Paket depoya ulaştığı zaman ölçüler arasında bazen farklar oluşabilmektedir.
                Oluşacak olan farklardan dolayı kullanıcıya ekstra kargo ücreti ile ilgili
                ödeme yapması için bildirim yapılacaktır.
            </p>}
            type="info"
            showIcon
        />

        <Card className='bg-cloud border-radius-md mt-24'>
            {productList.map((item: any, key) => (
                <Card key={key} className='border-radius-md w-full mt-24'>
                    <Row align={'top'} justify={'space-between'}>
                        <Col span={9}>
                            <Row gutter={[24, 24]}>
                                <Col>
                                    <AmazonProductImage asin={item.asin} ></AmazonProductImage>
                                </Col>
                                <Col span={16}>
                                    <AmazonProductInfo asin={item.asin} name={item.name} ></AmazonProductInfo>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <p className='text-sm fs-12 m-0'><b>BOYUTLAR</b></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className='mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0'>
                                        {item.length} x {item.width} x {item.height} in
                                        <br />
                                        {item.weight} lb
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={2} style={{ textAlign: 'left' }}>
                            <Row justify={'center'}>
                                <Col>
                                    <p className='text-sm fs-12 m-0'><b>ADET</b></p>
                                </Col>
                            </Row>
                            <Row justify={'center'}>
                                <Col>
                                    <p className='mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0'>
                                        {item.qty}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            ))}
            {(productList.length === 0) ? <Empty description="Ürün ekleyiniz" image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <></>}
        </Card>

        <Row justify={'center'} className='mt-48' gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={8} span={8}>
                <Card className='border-radius-md border-slate-blue-02'>
                    <Row justify={"space-between"} align={"middle"} gutter={[8, 8]}>
                        <Col span={6}>
                            <Row justify={"center"} align={"middle"} className='wh-64 bg-slate-blue-02 border-radius-lg'>
                                <WarehouseIcon />
                            </Row>
                        </Col>
                        <Col span={18}>
                            <h4 className='text font-color-slate-blue mb-0'>
                                <b>Gönderileceği Amazon Deposu</b>
                            </h4>
                            <h4 className='mt-8 font-color-slate-blue-06 mb-0 mt-0'>
                                {warehouseText?.code} <br /> {warehouseText?.label}
                            </h4>
                        </Col>
                    </Row>
                    <Divider></Divider>
                    <Row align={'middle'} justify={'space-between'}>
                        <Col>
                            <Avatar size={64} src={(shipmentCompany === 'AMAZON') ? <Image src={AmazonFba} preview={false} /> : <Image src={OneamzShip} preview={false} />}
                                className='border-slate-blue-02 pt-8 border-radius-sm'>
                            </Avatar>
                        </Col>
                        <Col>
                            <p className='table-title m-0'>ÜLKE</p>
                            <p className='text fs-12 font-color-slate-blue-05 m-0'>{country}</p>
                        </Col>
                        <Col>
                            <p className='table-title m-0'>TOPLAM TUTAR</p>
                            <p className='text fs-12 font-color-slate-blue-05 m-0'>${shipmentFee}</p>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} span={8}>
                <Card className='bg-cloud h-full border-radius-md'>
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col>
                                    <p className='text fs-12 mt-0'><b>NOT </b></p>
                                </Col>
                                <Col>
                                    <InfoIcon className='wh-8 mb-4 ml-8' />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <TextArea showCount
                                        placeholder="Ürünle İlgili bilgilendirme İçin Not Yazın..."
                                        onChange={(e) => setNote(e.target.value)} style={{ height: '280px' }}> </TextArea>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} span={8}>
                <Card className='border-cloud border-radius-md antd-card-body-p-0'>
                    <h4 className='text font-color-slate-blue fs-14 mx-24 mt-24'><b>AMAZON FBA MALİYETİ
                        <Tooltip title="AMAZON FBA MALİYETİ">
                            <InfoIcon className='wh-12 ml-8' />
                        </Tooltip>
                    </b></h4>
                    <Row className='mx-24' justify={'space-between'}>
                        <Col>
                            <p className='text-small font-color-slate-blue-05'><b>Nakliye Maliyeti</b></p>
                        </Col>
                        <Col>
                            <p className='text-small font-color-slate-blue'><b>{formatMoney(shipmentFee)}</b></p>
                        </Col>
                    </Row>
                    <div className='mx-24'>
                        <Divider className='bg-cloud m-0'></Divider>
                    </div>
                    <Row className='mx-24' justify={'space-between'}>
                        <Col>
                            <p className='text-small font-color-slate-blue-05'><b>FBA Zorunlu Hizmetlerin Toplamı</b></p>
                        </Col>
                        <Col>
                            <p className='text-small font-color-slate-blue'><b>{formatMoney(totalFBACost)}</b></p>
                        </Col>
                    </Row>
                    <div className='mx-24'>
                        <Divider className='bg-cloud m-0'></Divider>
                    </div>
                    <Row className='mx-24' justify={'space-between'}>
                        <Col>
                            <p className='text-small font-color-slate-blue-05'><b>FBA Ek Hizmetler Toplamı</b></p>
                        </Col>
                        <Col>
                            <p className='text-small font-color-slate-blue'><b>{formatMoney(totalCustomServiceCost)}</b></p>
                        </Col>
                    </Row>
                    <div className="mx-24">
                        <Divider className='bg-cloud m-0'></Divider>
                    </div>
                    <Row className='mx-24' justify={'space-between'}>
                        <Col>
                            <p className='text-small font-color-slate-blue-05'><b>Ürün Başı Maliyet</b></p>
                        </Col>
                        <Col>
                            <p className='text-small font-color-slate-blue'><b>{formatMoney(totalFulfillmentCostPerUnit)}</b></p>
                        </Col>
                    </Row>
                    <Row className='bottom-border-radius-md bg-slate-blue p-24' justify={'space-between'} align={'middle'}>
                        <Col>
                            <h4 className='text font-color-cloud fs-14 m-0'><b>TOPLAM MALİYET
                                <Tooltip className='font-color-cloud' title="TOPLAM MALİYET">
                                    <InfoIconWhite className='wh-12 ml-8' />
                                </Tooltip>
                            </b></h4>
                        </Col>
                        <Col>
                            <h3 className='h-3 font-color-cloud m-0'>{formatMoney(totalFulfillmentCost)}</h3>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </>
}

export default FBACreateStep4