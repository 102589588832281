import { Col, Divider, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import { formatMoney } from '../../../utils';
import { ReactComponent as InfoIcon } from '../../../assets/info-icon.svg';

interface OrderFeeInfoProps {
    order: any,
}

const FBAShipmentCostDetail: React.FC<OrderFeeInfoProps> = ({ order }) => {
    const [shippingFee, setShippingFee] = useState<any>(0);
    const [totalFBACost, setTotalFBACost] = useState<any>(0);
    const [totalFBAPrepFee, setTotalFBAPrepFee] = useState<any>(0);
    const [totalServiceCost, setTotalServiceCost] = useState<any>(0);
    const [totalFulfillmentCost, setTotalFulfillmentCost] = useState<any>(0);
    const [totalFulfillmentCostPerUnit, setTotalFulfillmentCostPerUnit] = useState<any>(0);

    const calcorderFee = () => {
        const orderItems = order?.items;
        const orderGeneralServices = order?.generalServices;

        const shippingCost = order?.shippingFee || 0;
        setShippingFee(shippingCost);

        const fbaCost = calculateTotal(orderGeneralServices || []) || 0;
        setTotalFBACost(fbaCost);

        const serviceCost = calculateTotalItemCost(orderItems || []) || 0;
        setTotalServiceCost(serviceCost);

        const qty = calculateTotalQty(orderItems || []) || 1;

        const fulfillmentCost = (fbaCost + serviceCost + shippingCost).toFixed(2);
        setTotalFulfillmentCost(fulfillmentCost);

        const FBAPrepFee = (fbaCost + serviceCost).toFixed(2);
        setTotalFBAPrepFee(FBAPrepFee);

        setTotalFulfillmentCostPerUnit((fulfillmentCost / qty).toFixed(2));
    };


    const calculateTotalQty = (items: any[]): number => {
        return items.reduce((total, item) => total + item.qty, 0);
    };

    const calculateTotalItemCost = (items: any[]): number => {
        return items.reduce((total, item) => total + item.amount, 0);
    };

    const calculateTotal = (items: any[]): number => {
        return items.reduce((total, item) => total + item.qty * item.amount, 0);
    };

    useEffect(() => {
        calcorderFee();
    }, [order])

    return (
        <>
            <Divider></Divider>
            <Row justify={'space-between'}>
                <Col>
                    <p className='text fs-12 font-color-slate-blue-05'><b>FBA PREP FEE </b></p>
                    <p className='text fs-12 font-color-slate-blue-05'><b>{formatMoney(totalFBAPrepFee)}</b></p>
                </Col>
                <Col>
                    <p className='text fs-12 font-color-slate-blue-05'><b>NAKLİYE MALİYETİ </b></p>
                    <p className='text fs-12 font-color-slate-blue-05'><b>{formatMoney(shippingFee)}</b></p>
                </Col>
                <Col>
                    <p className='text fs-12 font-color-slate-blue-05'><b>ÜRÜN BAŞI MALİYET </b></p>
                    <p className='text fs-12 font-color-slate-blue-05'><b>{formatMoney(totalFulfillmentCostPerUnit)}</b></p>
                </Col>
                <Col>
                    <h4 className='text font-color-slate-blue fs-14 mb-0 mt-8'><b>TOPLAM MALİYET <InfoIcon className='wh-12 ml-8' /></b></h4>
                    <h3 className='h-3 font-color-slate-blue m-0'>{formatMoney(totalFulfillmentCost)}</h3>
                </Col>
            </Row>
            {/* <Card className='border-cloud border-radius-md'>
                <h4 className='text font-color-slate-blue fs-14 mt-0'><b>AMAZON FBA MALİYETİ <InfoIcon className='wh-12 ml-8' /></b></h4>
                <Row justify={'space-between'}>
                    <Col>
                        <p className='text-small font-color-slate-blue-05'><b>Nakliye Maliyeti</b></p>
                    </Col>
                    <Col>
                        <p className='text-small font-color-slate-blue'><b>{formatMoney(shippingFee)}</b></p>
                    </Col>
                </Row>
                <Divider className='bg-cloud m-0'></Divider>
                <Row justify={'space-between'}>
                    <Col>
                        <p className='text-small font-color-slate-blue-05'><b>FBA Prep Fee:</b></p>
                    </Col>
                    <Col>
                        <p className='text-small font-color-slate-blue'><b>{formatMoney(totalServiceCost + totalFBACost)}</b></p>
                    </Col>
                </Row>
                <Divider className='bg-cloud m-0'></Divider>
                <Row justify={'space-between'}>
                    <Col>
                        <p className='text-small font-color-slate-blue-05'><b>Ürün Başı Maliyet</b></p>
                    </Col>
                    <Col>
                        <p className='text-small font-color-slate-blue'><b>{formatMoney(totalFulfillmentCostPerUnit)}</b></p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className='text font-color-slate-blue fs-14 mb-0'><b>TOPLAM MALİYET <InfoIcon className='wh-12 ml-8' /></b></h4>
                        <h3 className='h-3 font-color-slate-blue m-0'>{formatMoney(totalFulfillmentCost)}</h3>
                    </Col>
                </Row>
            </Card> */}
        </>
    )
}

export default FBAShipmentCostDetail