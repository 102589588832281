import { Card, Col, Empty, Row, Tabs, TabsProps } from 'antd'
import { IResponseOrder } from '../../types/Order';
import ClaimOrderService from '../../services/ClaimOrderService';
import ShipmentListCard from './ShipmentList/ShipmentListCard';
import { useEffect, useState } from 'react';
import ShipmentListCardManager from './ShipmentList/ShipmentListCardManager';


const ShipmentList = () => {
    const [claimOrderList, setClaimOrderList] = useState<IResponseOrder[]>([]);
    const [activeKey, setActiveKey] = useState("1");
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState<'ADMIN' | 'WAREHOUSE_MANAGER' | 'CUSTOMER'>('CUSTOMER');

    const currentRole = localStorage.getItem('userRole');

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'FBA',
        },
        {
            key: '2',
            label: 'FBM',
        },
        ...(currentRole === 'CUSTOMER' ? [{
            key: '3',
            label: 'DEPO',
        }] : []),
    ];

    useEffect(() => {
        console.log(activeKey);
        if (activeKey === "1") {
            getClaimOrderList("?orderType=FBA_SHIPMENT&status=INCOMING");
        } else if (activeKey === "2") {
            getClaimOrderList("?orderType=FBM_SHIPMENT&status=INCOMING");
        } else if (activeKey === "3") {
            getClaimOrderList("?orderType=STORAGE&status=INCOMING");
        }
    }, [activeKey]);

    const getClaimOrderList = (filter = "") => {
        setClaimOrderList([]);
        setLoading(true);
        ClaimOrderService.getAll(filter)
            .then((response: any) => {
                const data: IResponseOrder[] = response.data.map((itemData: any) => new IResponseOrder(itemData));
                data.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
                setClaimOrderList(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };



    const onChange = (key: string) => {
        setActiveKey(key)
    };

    return (
        <>
            <Card className='bg-cloud border-radius-md'>
            <Row justify={'space-between'}>
            <Col xs={24} md={12}>
                <h3 className='h-4 m-0'>Gönderi Listesi</h3>
            </Col>
            <Col xs={24} md={12}>
                <Tabs defaultActiveKey="1" className='w-full antd-tab-right antd-tab-text' items={items} onChange={onChange}>
                </Tabs>
            </Col>
        </Row>
                <Row>
                    {(activeKey === "1") && <Card className='antd-card-body-p-0 bg-transparent w-full' loading={loading}>
                        <Row gutter={[16, 16]}>
                            {claimOrderList.map(order => (
                                <Col span={24} key={order.id}>
                                    {(currentRole === "CUSTOMER") && <ShipmentListCard order={order}></ShipmentListCard>}
                                    {(currentRole === "ADMIN") && <ShipmentListCardManager order={order}></ShipmentListCardManager>}
                                </Col>
                            ))}
                            {(claimOrderList.length === 0) ? <Col span={24}><Empty description={<h3>FBA Gönderisi verisi yok</h3>} image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> : <></>}
                        </Row>
                    </Card>}
                    {(activeKey === "2") && <Card className='antd-card-body-p-0 bg-transparent w-full' loading={loading}>
                        <Row gutter={[16, 16]}>
                            {claimOrderList.map(order => (
                                <Col span={24} key={order.id}>
                                    {(currentRole === "CUSTOMER") && <ShipmentListCard order={order}></ShipmentListCard>}
                                    {(currentRole === "ADMIN") && <ShipmentListCardManager order={order}></ShipmentListCardManager>}                                </Col>
                            ))}
                            {(claimOrderList.length === 0) ? <Col span={24}><Empty description={<h3>FBM Gönderisi verisi yok</h3>} image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> : <></>}
                        </Row>
                    </Card>}
                    {(activeKey === "3") && <Card className='antd-card-body-p-0 bg-transparent w-full' loading={loading}>
                        <Row gutter={[16, 16]}>
                            {claimOrderList.map(order => (
                                <Col span={24} key={order.id}>
                                    {(currentRole === "CUSTOMER") && <ShipmentListCard order={order}></ShipmentListCard>}
                                    {(currentRole === "ADMIN") && <ShipmentListCardManager order={order}></ShipmentListCardManager>}                                </Col>
                            ))}
                            {(claimOrderList.length === 0) ? <Col span={24}><Empty description={<h3>Depo Gönderisi verisi yok</h3>} image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> : <></>}
                        </Row>
                    </Card>}
                </Row>
            </Card>
        </>
    )
}

export default ShipmentList