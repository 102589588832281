import { Tooltip } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg'


interface AmazonProductInfoProps {
    asin: string;
    name: string
}

const AmazonProductInfo: React.FC<AmazonProductInfoProps> = ({ asin, name }) => {

    return (
        <NavLink to={`https://www.amazon.com/dp/${asin}`} target='_blank' >
            <p className='text font-color-slate-blue bg-cloud p-8 d-inline border-radius-sm'><b>{asin}</b>
                <Tooltip title="Amazon Standart Kimlik Numarası (ASIN), Amazon.com ve ortakları için Amazon.com organizasyonu kapsamında ürün tanımlama için atanan bir benzersiz kimlik numarasıdır.">
                    <InfoIcon className='ml-8 wh-14'/>
                </Tooltip>
            </p>
            <Tooltip title={name}>
                <p className='text-small font-color-slate-blue mb-0 product-title'>{name}</p>
            </Tooltip>
        </NavLink>
    );
}

export default AmazonProductInfo
