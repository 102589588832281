import React, { useEffect, useState } from 'react'
import FbaImg from '../../../assets/V2/PrintV2/fba-order-yellow.png'
import { Card, Col, Row, Image } from 'antd'
import { ReactComponent as CustomerIcon } from '../../../assets/V2/LayoutV2/customer-active.svg'
import { ReactComponent as WarehouseIcon } from '../../../assets/V2/LayoutV2/inventory-active.svg'
import { ReactComponent as ShipmentIcon } from '../../../assets/V2/AdminDashboardV2/waiting-icon.svg'
import { useParams } from 'react-router-dom'
import AmazonProductImage from '../../Common/AmazonProductImage'
import AmazonProductInfo from '../../Common/AmazonProductInfo'
import ClaimOrderService from '../../../services/ClaimOrderService'

const FBADetailPrint = () => {
  const [order, setOrder] = useState<any>()
  const [loading, setLoading] = useState<any>()
  const [orderItemList, setOrderItemList] = useState<any>()
  let { id } = useParams();

  useEffect(() => {
    getOrderDetail()
  }, [])


  const getOrderDetail = () => {
    setLoading(true);
    ClaimOrderService.get(id)
      .then((response: any) => {
        let data = response.data;
        let claimOrderObj = data.claimOrderItems.map((updatedItem: any) => {
          updatedItem.approvalQty = updatedItem.qty;
          return updatedItem;
        });
        data.claimOrderItems = claimOrderObj;
        setOrder(data);
        setOrderItemList(data.claimOrderItems)
        setLoading(false);
      }).catch((e: Error) => {
        setLoading(false);
        console.log(e)
      });
  }

  return (
    <>
      <Row className='m-24'>
        <Col xxl={18} xs={24}>
          <Card loading={loading} className='border-radius-md'>
            <Row justify={'space-between'}>
              <Col xl={8}>
                <Row gutter={24}>
                  <Col className='pt-8'>
                    <Image src={FbaImg}></Image>
                  </Col>
                  <Col>
                    <h2 className='h-2 m-0'>ONESHIP-{order?.user.id}</h2>
                    <h2 className='h-2 m-0'>FBA-{id}</h2>
                  </Col>
                </Row>
              </Col>
              <Col xl={6}>
                <Row gutter={[24, 24]} justify={'end'}>
                  <Col>
                    <CustomerIcon className='wh-25 mt-8' />
                  </Col>
                  <Col>
                    <h3 className="h-3 m-0">Kullanıcı Bilgileri</h3>
                    <p className='m-0 fs-12'><b>{order?.user.firstName} {order?.user.lastName}</b></p>
                    <p className='mt-0 fs-12'><b>#{order?.user.id}</b></p>
                    <p className='m-0 fs-14'>{order?.user.phoneNumber}</p>
                    <p className='m-0 fs-14'>{order?.user.email}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <h3 className='h-3 mb-0 mt-64'>Gönderilecek Ürünler</h3>
              {orderItemList?.map((item: any) => (
                <Col span={24}>
                  <Card className='border-slate-blue-02 border-radius-md'>
                    <Row align={'middle'} justify={'space-between'}>
                      <Col xs={16}>
                        <Row>
                          <Col className='mr-24'>
                            <AmazonProductImage asin={item.product.asin}></AmazonProductImage>
                          </Col>
                          <Col span={16}>
                            <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={3}>
                        <p className='btn-text font-color-slate-blue-05 m-0'>
                          Adet
                        </p>
                        <h3 className='h-3 font-color-slate-blue m-0'>
                          {item?.qty}
                        </h3>
                      </Col>
                      <Col xs={4}>
                        <p className='btn-text font-color-slate-blue-05 m-0'>
                          Box
                        </p>
                        <h3 className='h-3 font-color-slate-blue m-0'>
                          -
                        </h3>
                      </Col>
                      <Col xs={1}>
                        <div className='wh-32 border-slate-blue-02 border-radius-8'>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row gutter={[24, 24]}>
              <h3 className='h-3 mb-0 mt-48'>Ek Hizmetler</h3>
              {orderItemList?.map((item: any) => (
                <Col span={24}>
                  <Card className='border-slate-blue-02 border-radius-md'>
                    <Row align={'middle'} justify={'space-between'}>
                      <Col xs={16}>
                        <Row>
                          <Col className='mr-24'>
                            <AmazonProductImage asin={item.product.asin}></AmazonProductImage>
                          </Col>
                          <Col span={16}>
                            <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={3}>
                        <p className='btn-text font-color-slate-blue-05 m-0'>
                          Adet
                        </p>
                        <h3 className='h-3 font-color-slate-blue m-0'>
                          {item.qty}
                        </h3>
                      </Col>
                      <Col xs={4}>
                        <p className='btn-text font-color-slate-blue-05 m-0'>
                          Ek Hizmet
                        </p>
                        {item.services?.map((service: any) => (
                          <p className='text font-color-slate-blue m-0'>
                            <b>{service.qty} x {service.generalService.name}</b>
                          </p>
                        ))}
                      </Col>
                      <Col xs={1}>
                        <div className='wh-32 border-slate-blue-02 border-radius-8'>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <h3 className='h-3 mt-48'>Paket Bilgileri</h3>
              <Col span={24}>
                <Card className='border-slate-blue-02 border-radius-md'>
                  <Row gutter={[24,24]} align={'middle'} justify={'space-between'}>
                    {orderItemList?.map((item: any, key: any) => (
                      <>
                        <Col xl={12}>
                          <Row justify={'space-between'}>
                            <Col>
                              <h3 className='m-0 h-3 font-color-slate-blue'>PAKET {key + 1}</h3>
                            </Col>
                            <Col>
                              <p className='m-0 text font-color-slate-blue'><b>- LBS</b></p>
                              <p className='m-0 text fs-12 font-color-slate-blue'><b> . x . x .</b></p>
                            </Col>
                            <Col className='mt-24' span={24}>
                              <Row>
                                <Col span={24}>
                                  <Card className='bg-cloud-05 border-radius-md'>
                                    <Row>
                                      <Col xs={20}>
                                        <Row>
                                          <Col span={8} className='mr-24'>
                                            <AmazonProductImage asin={item.product.asin}></AmazonProductImage>
                                          </Col>
                                          <Col span={16}>
                                            <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col xs={4}>
                                        <Row justify={'end'}>
                                          <Col>
                                            <p className='m-0 text fs-14'><b>Adet</b></p>
                                            <p className='text fs-24 m-0'><b>{item.qty}</b></p>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <h3 className='h-3 mt-48'>Gönderilecek Amazon Depo Bilgisi</h3>
              <Col span={24}>
                <Card className='bg-cloud border-radius-md'>
                  <Row gutter={[48,24]} align={'middle'}>
                    <Col>
                      <WarehouseIcon className='wh-24' />
                    </Col>
                    <Col>
                      <p className='text font-color-slate-blue m-0'><b>Gönderilen Amazon Deposu</b></p>
                    </Col>
                    <Col>
                      <p className='text font-color-slate-blue m-0 upper-case'><b>{order?.warehouse.countryCode}</b></p>
                    </Col>
                    <Col>
                      <p className='text font-color-slate-blue m-0'>{order?.warehouse.addressLine1}</p>
                    </Col>
                    <Col>
                      <p className='text font-color-slate-blue m-0'>{order?.warehouse.addressLine2}</p>
                    </Col>
                  </Row>
                </Card>
                <Card className='border-slate-blue-02 border-radius-md mt-24'>
                  <Row gutter={48} align={'middle'}>
                    <Col>
                      <ShipmentIcon className='wh-24' />
                    </Col>
                    <Col>
                      <p className='text font-color-slate-blue m-0'><b>Gönderilecek Kargo Firması</b></p>
                    </Col>
                    <Col xl={8}>
                      <Row>
                        <Col span={4}>
                          {/* <p className='text font-color-slate-blue m-0'><b>LOGO</b></p> */}
                        </Col>
                        <Col span={20}>
                          <p className='text fs-12 font-color-slate-blue m-0 upper-case'><b>Kargo Firması</b></p>
                          <p className='text fs-12 font-color-slate-blue-05 m-0 upper-case'>{order?.shippingProvider}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={24} justify={'end'} className='mt-48'>
              <Col>
                <p className='text m-0'><b>Sevkiyat Yekilisi</b></p>
                <p className='text m-0'><b>Ad Soyad</b></p>
                <p className='mt-48 mb-0 text fs-14'>09/02/2024</p>
              </Col>
              <Col>
                <Card className='width-200 h-full border-slate-blue-05'>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FBADetailPrint