import FbaImg from '../../../assets/V2/PrintV2/fba-order-yellow.png'
import { Card, Col, Row, Image, Avatar } from 'antd'
import { ReactComponent as CustomerIcon } from '../../../assets/V2/LayoutV2/customer-active.svg'
import { ReactComponent as WarehouseIcon } from '../../../assets/V2/LayoutV2/inventory-active.svg'
import { ReactComponent as ShipmentIcon } from '../../../assets/V2/AdminDashboardV2/waiting-icon.svg'
import ClaimOrderService from '../../../services/ClaimOrderService'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AmazonProductInfo from '../../Common/AmazonProductInfo'
import AmazonProductImage from '../../Common/AmazonProductImage'

const FBMDetailPrint = () => {
    const [order, setOrder] = useState<any>()
    const [loading, setLoading] = useState<any>()
    const [orderItemList, setOrderItemList] = useState<any>()
    let { id } = useParams();
    const [receiver, setReceiver] = useState<any>(null)

    useEffect(() => {
        getOrderDetail()
        getReceiver(id);
    }, [])

    const getOrderDetail = () => {
        setLoading(true);
        ClaimOrderService.get(id)
            .then((response: any) => {
                let data = response.data;
                let claimOrderObj = data.claimOrderItems.map((updatedItem: any) => {
                    updatedItem.approvalQty = updatedItem.qty;
                    return updatedItem;
                });

                data.claimOrderItems = claimOrderObj;

                setOrder(data);
                setOrderItemList(data.claimOrderItems)
                setLoading(false);
            }).catch((e: Error) => {
                setLoading(false);
                console.log(e)
            });
    }

    const getReceiver = (id: any) => {
        ClaimOrderService.getCustomer(id)
            .then((response: any) => {
                setReceiver(response.data);
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    return (
        <>
            <Row className='m-24'>
                <Col xxl={14} xs={24}>
                    <Card className='border-radius-md'>
                        <Row justify={'space-between'}>
                            <Col xl={8}>
                                <Row gutter={24}>
                                    <Col className='pt-8'>
                                        <Image src={FbaImg}></Image>
                                    </Col>
                                    <Col>
                                        <h2 className='h-2 m-0'>ONESHIP-{order?.user.id}</h2>
                                        <h2 className='h-2 m-0'>FBM-{order?.id}</h2>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={6}>
                                <Row gutter={[24, 24]} justify={'end'}>
                                    <Col>
                                        <CustomerIcon className='wh-25 mt-8' />
                                    </Col>
                                    <Col>
                                        <h3 className="h-3 m-0">Kullanıcı Bilgileri</h3>
                                        <p className='m-0 fs-12'><b>{order?.user.firstName} {order?.user.lastName}</b></p>
                                        <p className='mt-0 fs-12'><b>#{order?.user.id}</b></p>
                                        <p className='m-0 fs-14'>{order?.user.phoneNumber}</p>
                                        <p className='m-0 fs-14'>{order?.user.email}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <h3 className='h-3 mb-0 mt-64'>Gönderilecek Ürünler</h3>
                            {orderItemList?.map((item: any) => (
                                <Col span={24}>
                                    <Card className='border-slate-blue-02 border-radius-md'>
                                        <Row align={'middle'} justify={'space-between'}>
                                            <Col xs={16}>
                                                <Row>
                                                    <Col className='mr-24'>
                                                        <AmazonProductImage asin={item.product.asin}></AmazonProductImage>
                                                    </Col>
                                                    <Col span={16}>
                                                        <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={3}>
                                                <p className='btn-text font-color-slate-blue-05 m-0'>
                                                    Adet
                                                </p>
                                                <h3 className='h-3 font-color-slate-blue m-0'>
                                                    {item?.qty}
                                                </h3>
                                            </Col>
                                            <Col xs={3}>
                                                <p className='btn-text font-color-slate-blue-05 m-0'>
                                                    Box
                                                </p>
                                                <h3 className='h-3 font-color-slate-blue m-0'>
                                                    -
                                                </h3>
                                            </Col>
                                            <Col xs={1}>
                                                <div className='wh-32 border-slate-blue-02 border-radius-8'>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <Row gutter={[24, 24]}>
                            <h3 className='h-3 mb-0 mt-48'>Ek Hizmetler</h3>
                            {orderItemList?.map((item: any) => (
                                <Col span={24}>
                                    <Card className='border-slate-blue-02 border-radius-md'>
                                        <Row align={'middle'} justify={'space-between'}>
                                            <Col xs={15}>
                                                <Row>
                                                    <Col className='mr-24'>
                                                        <AmazonProductImage asin={item.product.asin}></AmazonProductImage>
                                                    </Col>
                                                    <Col span={16}>
                                                        <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={2}>
                                                <p className='btn-text font-color-slate-blue-05 m-0'>
                                                    Adet
                                                </p>
                                                <h3 className='h-3 font-color-slate-blue m-0'>
                                                    {item.qty}
                                                </h3>
                                            </Col>
                                            <Col xs={6}>
                                                <p className='btn-text font-color-slate-blue-05 m-0'>
                                                    Ek Hizmet
                                                </p>
                                                {item.services?.map((service: any) => (
                                                    <p className='text font-color-slate-blue m-0'>
                                                        <b>{service.qty} x {service.generalService.name}</b>
                                                    </p>
                                                ))}
                                            </Col>
                                            <Col xs={1}>
                                                <div className='wh-32 border-slate-blue-02 border-radius-8'>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <h3 className='h-3 mt-48'>Alıcı Bilgisi</h3>
                            <Col span={24}>
                                <Card className='bg-cloud border-radius-md'>
                                    <Row gutter={48}>
                                        <Col>
                                            <Row className='mt-8' justify={'space-around'}>
                                                <Col className='mr-16'>
                                                    <WarehouseIcon className='wh-24' />
                                                </Col>
                                                <Col>
                                                    <p className='text font-color-slate-blue m-0'><b>Alıcı Bilgileri</b></p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xxl={5}>
                                            <p className='text fs-12 font-color-slate-blue m-0'>Adı Soyadı</p>
                                            <p className='text font-color-slate-blue m-0'><b>{receiver?.fullName}</b></p>

                                            <p className='text fs-12 font-color-slate-blue mt-48 mb-0'>Ülke</p>
                                            <p className='text font-color-slate-blue m-0'><b>{receiver?.country} </b></p>
                                        </Col>
                                        <Col xxl={5}>
                                            <p className='text fs-12 font-color-slate-blue m-0'>Adres </p>
                                            <p className='text font-color-slate-blue m-0'><b>{receiver?.addressLine1} {receiver?.addressLine2}</b></p>

                                            <p className='text fs-12 font-color-slate-blue mt-48 mb-0'>Eyalet</p>
                                            <p className='text font-color-slate-blue m-0'><b>{receiver?.state}</b></p>
                                        </Col>
                                        <Col xxl={5}>
                                            <p className='text fs-12 font-color-slate-blue m-0'>Şehir</p>
                                            <p className='text font-color-slate-blue m-0'><b>{receiver?.city}</b></p>

                                            <p className='text fs-12 font-color-slate-blue mt-48 mb-0'>Posta Kodu</p>
                                            <p className='text font-color-slate-blue m-0'><b>{receiver?.postCode}</b></p>
                                        </Col>
                                        <Col xxl={5}>
                                            <p className='text fs-12 font-color-slate-blue m-0'>Telefon</p>
                                            <p className='text font-color-slate-blue m-0'><b>{receiver?.phoneNumber}</b></p>

                                            <p className='text fs-12 font-color-slate-blue mt-48 mb-0'>E-Posta</p>
                                            <p className='text font-color-slate-blue m-0'><b>{receiver?.email}</b></p>
                                        </Col>
                                    </Row>
                                </Card>
                                <Card className='border-slate-blue-02 border-radius-md mt-24'>
                                    <Row gutter={48} align={'middle'}>
                                        <Col>
                                            <Row align={'middle'} className='mt-8' justify={'space-around'}>
                                                <Col className='mr-16'>
                                                    <ShipmentIcon className='wh-24' />
                                                </Col>
                                                <Col>
                                                    <p className='text pb-8 font-color-slate-blue m-0'><b>Kargo Bilgileri</b></p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row gutter={16}>
                                                <Col>
                                                    {/* <p className='text font-color-slate-blue m-0'><b>LOGO</b></p> */}
                                                </Col>
                                                <Col>
                                                    <p className='text fs-12 font-color-slate-blue m-0 upper-case'><b>Kargo Firması</b></p>
                                                    <p className='text fs-12 font-color-slate-blue-05 m-0'>{order?.shippingProvider}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <p className='text fs-12 font-color-slate-blue m-0 upper-case'><b>Toplam Tutar</b></p>
                                            <p className='text fs-12 font-color-slate-blue-05 m-0'>${order?.shippingFee}</p>
                                        </Col>
                                        <Col>
                                            <p className='text'><b>Kargo Takip Numarası:</b> {order?.amazonShipmentId}</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={24} justify={'end'} className='mt-48'>
                            <Col>
                                <p className='text m-0'><b>Sevkiyat Yekilisi</b></p>
                                <p className='text m-0'><b>Ad Soyad</b></p>
                                <p className='mt-48 mb-0 text fs-14'>09/02/2024</p>
                            </Col>
                            <Col>
                                <Card className='width-200 h-full border-slate-blue-05'>
                                </Card>
                            </Col>
                        </Row>
                    </Card >
                </Col >
            </Row >
        </>
    )
}

export default FBMDetailPrint