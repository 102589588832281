import { Card, Col, Row } from 'antd'
import AmazonProductInfo from '../../../components/Common/AmazonProductInfo'
import AmazonProductImageAvatar from '../../../components/Common/AmazonProductImageAvatar'


interface OrderDetailCardProps {
    orderItem: any
}
const StorageItemCard:React.FC<OrderDetailCardProps> = ({orderItem}) => {
    return (
        <>
            <Card className='border-radius-md w-full p-24 mt-24'>
                <Row align={'top'} justify={'start'}>
                    <Col xl={16} md={8} xs={24}>
                        <Row>
                            <Col className='mr-24'>
                                <AmazonProductImageAvatar orderItem={orderItem}></AmazonProductImageAvatar>
                            </Col>
                            <Col span={16}>
                                <AmazonProductInfo asin={orderItem.product.asin} name={orderItem.product.name}></AmazonProductInfo>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text-sm fs-12 m-0'><b>BOYUTLAR</b></p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'>
                            {orderItem.product.width} X {orderItem.product.length} X {orderItem.product.height} <b>IN</b>
                        </p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'>
                            {orderItem.product.weight} <b>LB</b>
                        </p>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default StorageItemCard