import { Col, Row } from 'antd'
import StarterCard from '../../components/V2/ProfileV2/StarterCard'
import ProfileCard from '../../components/V2/ProfileV2/ProfileCard'

const ProfileV2 = () => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <StarterCard></StarterCard>
                </Col>
            </Row>
            <Row className='mt-24'>
                <Col xl={14}>
                    <ProfileCard></ProfileCard>
                </Col>
            </Row>
        </>
    )
}

export default ProfileV2