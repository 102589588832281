import React from 'react';
import { Button, Col, Form, Input, Row, Image, Divider } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import loginLogo from '../../assets/loginLogo.svg'
import loginImage from '../../assets/loginImage2.png'
import { useNavigate } from "react-router-dom";
import AuthService from '../../services/AuthService';
import { openNotification } from '../../utils';

const VerifyAgain: React.FC = () => {
    const navigate = useNavigate();

    const createAccount = () => {
        navigate('/login')
    }

    const SignIn = (values: any) => {

        let userData: any = {
            "email": values.email,
            "password": values.password,
        };

        AuthService.verifyAgain(userData)
            .then((response: any) => {
                console.log(response)
                alert('Emailinizi Kontrol Edin')
            })
            .catch((e) => {
                console.log(e);
                if (e.response.data.code === "oneamz-ff-not-found") {
                    openNotification("error", "Başarısız", "Bu e-posta adresi sisteme kayıtlı değil.")
                }
            });
        console.log(values)
    };

    const onFinish = (values: any) => {
        SignIn(values)
    };

    return (
        <>
            <Row justify={'space-between'}>
                <Col xs={0} sm={0} md={0} lg={16} xl={16} style={{ position: 'fixed', top: 0, left: 0, height: '100vh', overflow: 'hidden' }}>
                    <Image style={{ width: "54vw", height: "100vh" }} preview={false} src={loginImage} />
                </Col>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 9, offset: 14 }} xl={{ span: 9, offset: 14 }}>
                    <Row justify={'center'}>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 20 }} md={{ span: 14 }} lg={{ span: 22 }} xl={18}>
                            <Row justify={'center'} style={{ margin: "5em 0 0 0", width: "100%" }}>
                                <Col>
                                    <Image style={{ height: "15vh" }} preview={false} src={loginLogo} />
                                </Col>
                            </Row>
                            <Row style={{ height: "30vh" }} justify={'center'}>
                                <Col span={20}>
                                    <Form
                                        name="normal_login"
                                        className="login-form"
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}
                                    >
                                        <h1 style={{ fontSize: "3em", margin: "1em 0 1em 0" }}>E-posta Aktivasyonu</h1>
                                        <p style={{ fontSize: "1.2em", marginBottom: "3em" }}>
                                            E-posta adresinizi kontrol edin. Eğer E-posta adresinize aktivasyon linki gelmediyse emailinizi girip gönder butonuna tıklayın.
                                        </p>
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: 'Email adresi bulunamadı.' }]}
                                        >
                                            <Input
                                                placeholder='örnekoneamz@gmail.com'
                                                size='large'
                                                style={{ padding: "0.7em", margin: "0 0 2em 0" }}
                                                prefix={<MailOutlined className="site-form-item-icon" />}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button style={{ width: "100%", background: "black", height: "4em" }} type="primary" htmlType="submit" className="login-form-button">
                                                <p style={{ fontSize: "1.2em", margin: "0", color: "#FFCB2B" }}><b>Gönder</b></p>
                                            </Button>
                                        </Form.Item>
                                        <Divider style={{ margin: "0" }} />
                                        <Row justify={'center'}>
                                            <Col>
                                                <h4 style={{ fontSize: "1.15em", fontWeight: "400" }}> <a href='/login' onClick={createAccount} style={{ color: "#FFCB2B" }}>Giriş</a></h4>
                                            </Col>
                                            <Col style={{ margin: "0 0 0 0.5em" }}>
                                                <h4 style={{ color: "#777777", fontSize: "1.15em", fontWeight: "400" }}>Ekranına Geri Dön</h4>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default VerifyAgain;