import http from "../http-common";

const create = (data: any) => {
    return http.post<any>(`/users/me/claim-orders`, data);
};

const postFbaLabel = (id: any, data: any) => {
    return http.post<any>(`/claim-orders/${id}/fba/upload`, data);
};

const getReport = (filter = "") => {
    return http.get<Array<any>>(`/users/me/claim-orders/reports/daily${filter}`);
};


const getAll = (filter = "") => {
    let role = localStorage.getItem("userRole");
    if (role == "WAREHOUSE_MANAGER" || role == "ADMIN") {
        return http.get<Array<any>>(`/claim-orders${filter}`);
    }
    return http.get<Array<any>>(`/users/me/claim-orders${filter}`);
};

const cancel = (claimOrderId: any) => {
    return http.put<any>(`/claim-orders/${claimOrderId}/cancel`);
};

const addService = (claimOrderId: any, list:any) => {
    return http.post<any>(`/claim-order-items/${claimOrderId}/services`, list);
};

const updateTrackingCode = (claimOrderId: any, trackingCode: any) => {

    const postObj = {
        trackingCode: trackingCode
    }

    return http.patch<any>(`/claim-order-items/${claimOrderId}`, postObj);
};

const updateNote = (claimOrderId: any, note: any) => {

    const postObj = {
        note: note
    }

    return http.patch<any>(`/claim-order-items/${claimOrderId}`, postObj);
};

const updateDamage = (claimOrderId: any, damage: any) => {

    const postObj = {
        damage: damage
    }

    return http.patch<any>(`/claim-order-items/${claimOrderId}`, postObj);
};

const getFBALabel = (claimOrderId: any) => {
    return http.get<any>(`/claim-orders/${claimOrderId}/fba/download`, { responseType: 'arraybuffer' });
};

const getShippingLabel = (claimOrderId: any) => {
    return http.get<any>(`/claim-orders/${claimOrderId}/shipping-label/download`, { responseType: 'arraybuffer' });
};

const getFnskuLabel = (claimOrderItemId: any) => {
    return http.get<any>(`claim-order-items/${claimOrderItemId}/fnsku/download`, { responseType: 'arraybuffer' });
};


const getAllCount = (filter = "") => {
    let role = localStorage.getItem("userRole");
    if (role == "WAREHOUSE_MANAGER" || role == "ADMIN") {
        return http.get<Array<any>>(`/claim-orders/count${filter}`);
    }
    return http.get<Array<any>>(`/users/me/claim-orders/count${filter}`);
};


const get = (id: any) => {
    return http.get<any>(`/claim-orders/${id}`);
};

const getCustomer = (id: any) => {
    return http.get<any>(`/claim-orders/${id}/customer`);
};

const ClaimOrderService = {
    get,
    getReport,
    getCustomer,
    cancel,
    postFbaLabel,
    getAll,
    create,
    getAllCount,
    getFnskuLabel,
    getFBALabel,
    updateTrackingCode,
    getShippingLabel,
    updateNote,
    updateDamage,
    addService
};

export default ClaimOrderService;