import { Card, Col, Row } from 'antd'
import { ReactComponent as WarehouseIcon } from '../../../assets/V2/AdminDashboardV2/warehouse-icon-white.svg'

interface TotalProductsProps {
    value: any
    loading: boolean
}
const TotalProducts:React.FC<TotalProductsProps> = ({ value, loading }) => {
  return (
    <>
        <Card loading={loading} className='bg-slate-blue border-radius-md h-full'>
            <Row justify={'end'}>
                <Col>
                <div className='icon-bg-12 bg-cloud-02 border-radius-lg'>
                    <WarehouseIcon className='mt-4' />
                </div>
                </Col>
            </Row>
            <Row>
                <Col>
                <h2 className='h-2 mb-0 font-color-cloud'>{value}</h2>
                <p className='m-0 text font-color-cloud'>Depodaki Toplam Ürün</p>
                </Col>
            </Row>
        </Card>
    </>
  )
}

export default TotalProducts