import { Col, Row } from 'antd'
import './dashboard-v2.scss'
import QuickAccess from '../../components/DashboardV2/QuickAccess'
import Graph from '../../components/DashboardV2/Graph'
import ShipmentList from '../../components/DashboardV2/ShipmentList'
import StarterCard from '../../components/V2/ProfileV2/StarterCard'
import AdminStarterCard from '../../components/V2/LayoutV2/StarterCard'
import { useEffect, useState } from 'react'
import TotalProducts from '../../components/V2/AdminDashboardV2/TotalProducts'
import ProductService from '../../services/ProductService'
import IncompleteFBA from '../../components/V2/AdminDashboardV2/IncompleteFBA'
import IncompleteFBM from '../../components/V2/AdminDashboardV2/IncompleteFBM'
import WaitingProducts from '../../components/V2/AdminDashboardV2/WaitingProducts'
import ClaimOrderService from '../../services/ClaimOrderService'

const DashboardV2 = () => {
    const [role, setRole] = useState<string | null>(null);
    const [storageLevel, setStorageLevel] = useState<any>(0);
    const [productCountLoading, setProductCountLoading] = useState(false);
    const [productCount, setProductCount] = useState<any>(0);
    const [waitingShipmentCount, setWaitingShipmentCount] = useState<any>(0);
    const [fbmCount, setFbmCount] = useState<any>(0);
    const [fbaCount, setFbaCount] = useState<any>(0);

    useEffect(() => {
        const storedRole = localStorage.getItem('userRole');
        setStorageLevel(localStorage.getItem('storageLevel'))
        setRole(storedRole);
    }, []);

    useEffect(() => {
        getProductCount();
        getWaitingShipmentCount("?status=INCOMING");
        getFbmCount("?status=INCOMING&orderType=FBM_SHIPMENT&");
        getFbaCount("?status=INCOMING&orderType=FBA_SHIPMENT&");
    }, [])
    
    const getWaitingShipmentCount = (filter = "") => {
        ClaimOrderService.getAllCount(filter)
            .then((response: any) => {
                setWaitingShipmentCount(response.data);
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    const getFbmCount = (filter = "") => {
        ClaimOrderService.getAllCount(filter)
            .then((response: any) => {
                setFbmCount(response.data);
            }).catch((e: Error) => {
                console.log(e)
            });
    }


    const getFbaCount = (filter = "") => {
        ClaimOrderService.getAllCount(filter)
            .then((response: any) => {
                setFbaCount(response.data);
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    const getProductCount = () => {
        ProductService.getAllCount()
            .then((response: any) => {
                setProductCount(response.data);
            }).catch((e: Error) => {
                console.log(e)
                setProductCountLoading(false);
            });
    }
    
    return (
        <div className='dashboard-v2'>
            {
                (role === 'CUSTOMER') ?
                    <>
                        <Row>
                            <Col span={24}>
                                <StarterCard />
                            </Col>
                        </Row>
                        <Row className='mt-24' gutter={[24,24]}>
                            <Col xl={16} lg={24} md={24} sm={24} >
                                <QuickAccess></QuickAccess>
                            </Col>
                            <Col  xl={8} lg={24} md={24} sm={24}>
                                <Graph></Graph>
                            </Col>
                        </Row>
                        <Row className='mt-24'>
                            <Col span={24}>
                                <ShipmentList></ShipmentList>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Row>
                            <Col span={24}>
                                <AdminStarterCard />
                            </Col>
                        </Row>
                        <Row className='mt-24' gutter={[24,24]}>
                            <Col xl={6} md={12} sm={12} xs={24}>
                                <TotalProducts loading={productCountLoading} value={productCount} />
                            </Col>
                            <Col xl={6} md={12} sm={12} xs={24}>
                                <IncompleteFBA loading={productCountLoading} value={fbaCount} />
                            </Col>
                            <Col xl={6} md={12} sm={12} xs={24}>
                                <IncompleteFBM loading={productCountLoading} value={fbmCount} />
                            </Col>
                            <Col xl={6} md={12} sm={12} xs={24}>
                                <WaitingProducts loading={productCountLoading}  value={waitingShipmentCount} />
                            </Col>
                        </Row>
                        <Row className='mt-24'>
                            <Col span={24}>
                                <ShipmentList></ShipmentList>
                            </Col>
                        </Row>
                    </>
            }
        </div>
    )
}

export default DashboardV2