import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Row, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { openNotification } from '../../utils';
import { ReactComponent as UploadImage } from '../../assets/V2/OrderDetailV2/upload.svg';
import { ReactComponent as SuccessIcon } from '../../assets/V2/OrderDetailV2/succes-icon-outline.svg';
import Dragger from 'antd/es/upload/Dragger';


interface FBALabelUploadProps {
    setLabel: (file: any) => void;
    fbaLabel: any,
    product?: any;
    type: any;
}

const FBALabelUpload: React.FC<FBALabelUploadProps> = ({ setLabel, product, type, fbaLabel }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    useEffect(() => {
        if (type === 'fnsku' && product?.fnskuLabel != null) {
            setFileList([product?.fnskuLabel])
        }

        if (type === 'fba' && fbaLabel != null) {
            setFileList([fbaLabel])
        }
    }, [])

    useEffect(() => {
        if (fileList.length > 0) {
            setLabel(fileList[0]);
        } else {
            setLabel(null);
        }
        console.log('fileList', fileList);
    }, [fileList])

    const props: UploadProps = {
        onRemove: (file) => {
            setFileList([]);
        },
        beforeUpload: (file) => {
            if (fileList.length >= 1) {
                openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
                // If there's already a file in the list, reject the new file
                return Upload.LIST_IGNORE;
            }
            // If not, add the new file to the list
            setFileList([file]);
            return false;
        },
        fileList,
        defaultFileList: fileList, // Show the initial file list
        accept: '.pdf', // Accept only PDF files
        multiple: false, // Disable multiple file selection
    };

    return (
        <>
            <Dragger {...props} className='bg-oneamz-yellow-08 fba-label-upload' >
                {(fileList.length > 0) ?
                    <Row gutter={16} align={'middle'} justify={"center"} className='pointer'>
                        <Col>
                            <Row className='wh-48 bg-pure-white border-radius-lg' align={'middle'} justify={"center"} >
                                <SuccessIcon ></SuccessIcon>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <h3 className='h3 mt-24 mb-0 pointer'>{(type == 'fnsku') ? "FNSKU" : "FBA"} Etiketini Değiştir</h3>
                        </Col>
                        <Col span={24}>
                            <p className='text fs-14 mt-8 font-color-slate-blue-06'>{fileList[0]?.name}</p>
                        </Col>
                        <Col span={24}>
                            <Button
                                onClick={() => {
                                    setFileList([]);
                                }}
                                className='btn bg-slate-blue mt-16 font-color-cloud btn-hover-white btn-text border-radius-lg' type="primary"
                            >
                                Değiştir
                            </Button>
                        </Col>
                    </Row>
                    :
                    <Row gutter={16} align={'middle'} justify={"center"} className='pointer'>
                        <Col>
                            <Row className='wh-48 bg-slate-blue-02 border-radius-lg' align={'middle'} justify={"center"} >
                                <UploadImage ></UploadImage>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <h3 className='h3 mt-24 mb-0 pointer mb-16'>{(type == 'fnsku') ? "FNSKU" : "FBA"} Etiketini Yükleyin</h3>
                        </Col>
                        <Col span={24}>
                            <Alert message={<b>*Fba Print box label format</b>} description={
                            <p className="mt-0 mb-0">Thermal Printing - 
                            <br />102 x 152 mm (4 x 6 in)
                            <br />PDF formatı, maksimum 5MB
                            </p>}
                                type="warning" />
                            <br />
                        </Col>
                        <Col span={24}>
                            <Button
                                className='btn bg-slate-blue mt-16 font-color-cloud btn-hover-white btn-text border-radius-lg' type="primary"
                            >
                                Dosya Seç
                            </Button>
                        </Col>
                    </Row>
                }

            </Dragger>
        </>
    );
}

export default FBALabelUpload;
