import { Col, Row } from 'antd'
import StarterCard from '../../components/V2/ProfileV2/StarterCard'
import FbmShipmentList from '../../components/V2/FBMV2/FbmShipmentList'

const FBMPageV2 = () => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <StarterCard></StarterCard>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FbmShipmentList></FbmShipmentList>
                </Col>
            </Row>
        </>
    )
}

export default FBMPageV2