import React, { ReactNode, useEffect, useState } from 'react';
import { Col, Layout, Row, Card, Image } from 'antd';

import './layout.scss'
// import DiscordSupport from '../components/Layout/DiscordSupport';
import NewTicket from '../components/V2/LayoutV2/NewTicket';
import Announcement from '../components/V2/LayoutV2/Announcement';
import Menu from '../components/V2/LayoutV2/Menu';
import Logo from '../assets/oneship-logo.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { refrestToken } from '../utils';
import { ReactComponent as Logout } from '../assets/icons/logout-icon.svg';
import ProfilePart from '../components/V2/LayoutV2/ProfilePart';
import Search from '../components/Common/Search';


interface CustomLayoutProps {
    children: ReactNode;
}

const { Sider, Content } = Layout;

const MainLayout: React.FC<CustomLayoutProps> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [role, setRole] = useState<'ADMIN' | 'WAREHOUSE_MANAGER' | 'CUSTOMER'>('CUSTOMER');
    const [urlPath, setUrlPath] = useState('');

    useEffect(() => {
        refrestToken();
    }, [location]);

    useEffect(() => {
        const storedRole = localStorage.getItem('userRole');
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken || accessToken == 'null' || accessToken === null) {
            logout();
        }

        if (storedRole === 'ADMIN' || storedRole === 'WAREHOUSE_MANAGER' || storedRole === 'CUSTOMER') {
            setRole(storedRole);
        }
        setUrlPath(location.pathname);
    }, [location.pathname]);

    function logout() {
        localStorage.removeItem('externalAccessToken');
        localStorage.removeItem('externalRefreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('email');
        localStorage.removeItem('accessTokenExpiredAt');
        localStorage.removeItem('refreshTokenExpiredAt');
        navigate('/login')
    }

    const Header = () => (
        <Layout style={{ height: "20vh", background: "pure-white" }}>
            <Row>
                <Col span={24}>
                    <Layout>
                        <Content
                            style={{
                                padding: '1.6em',
                                minHeight: 'calc(100vh )',
                                background: 'white',
                                marginLeft: "304px"
                            }}
                        >
                            {children}
                        </Content>
                    </Layout>
                </Col>
            </Row>
        </Layout>
    )

    const View = () => (
        <>
            <Layout>
                {Header()}
                <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={(broken) => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                    width={320} style={{ background: "#ffffff", position: 'fixed', height: '100vh', zIndex: 1 }} theme="dark">
                    {MenuControl()}
                </Sider>
            </Layout>
        </>
    )

    const MenuControl: any = () => {
        return (
            <>
                <div className='p-24'>
                    <Card style={{ height: '100vh' }} className='bg-cloud border-radius-md oneamz-layout-menu'>
                        <Row className='mb-32 mt-12' justify={'center'}>
                            <Col>
                                <NavLink to={'/'}>
                                    <Image preview={false} src={Logo}></Image>
                                </NavLink>
                            </Col>
                        </Row>
                        <Row className='mb-24' justify={'center'}>
                            <Col>
                                <ProfilePart />
                            </Col>
                        </Row>
                        {['ADMIN'].includes(role) && (
                            <Row>
                                <Search></Search>
                            </Row>
                        )}
                        <Row>
                            <Col>
                                <Menu />
                            </Col>
                            <Col span={24}>
                                <div className='menu-wrapper ml-16'>
                                    <NavLink to={'javascript:;'} onClick={() => (logout())}>
                                        <Row gutter={16} justify={"start"} align={"middle"}>
                                            <Col>
                                                <Logout className='mt-8' />
                                            </Col>
                                            <Col>
                                                <p className='text fs-14 font-color-slate-blue-05'>Çıkış</p>
                                            </Col>
                                        </Row>
                                    </NavLink>
                                </div>
                            </Col>
                        </Row>
                        <Announcement />
                        <NewTicket />
                    </Card>
                </div>
            </>
        )
    }
    return (
        <div>{View()}</div>
    );
};

export default MainLayout;