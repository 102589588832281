import { Col, Row } from 'antd'
import StarterCard from '../../components/V2/ProfileV2/StarterCard'
import FbaShipmentList from '../../components/V2/FBAV2/FbaShipmentList'

const FBAPageV2 = () => {


    return (
        <>
            <Row>
                <Col span={24}>
                    <StarterCard></StarterCard>
                </Col>
            </Row>
            <FbaShipmentList></FbaShipmentList>
        </>
    )
}

export default FBAPageV2