import http from "../http-common";
import { IServicesRequest, IServicesResponse } from "../types/Service";

const getAll = () => {
  return http.get<Array<IServicesResponse>>(`/services`);
};

const getOrderItemServices = () => {
  return http.get<Array<IServicesResponse>>(`/services?type=ORDER_ITEM`);
};

const getOrderServices = () => {
  return http.get<Array<IServicesResponse>>(`/services?type=ORDER`);
};

const get = (serviceId: number) => {
  return http.get<IServicesResponse>(`/services/${serviceId}`);
};

const update = (serviceId: any, data: IServicesRequest) => {
  return http.put<any>(`/services/`, data);
};

const create = ( data: IServicesRequest) => {
  return http.post<any>(`/services`, data);
};

const remove = (serviceId: any) => {
  return http.delete<any>(`/services/`);
};

const ServicesService = {
  getAll,
  getOrderItemServices,
  getOrderServices,
  get,
  create,
  update,
  remove,
};

export default ServicesService;