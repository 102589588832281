import { Button, Card, Col, Row, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { NavLink } from 'react-router-dom';
import { ReactComponent as SupportIcon } from '../../../assets/support.svg';


const StarterCard = () => {
    const [time, setTime] = useState('');

    useEffect(() => {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        const updateTime = () => {
            setTime(dayjs().tz('America/New_York').format('HH:mm:ss'));
        };

        // Update time every second
        const intervalId = setInterval(updateTime, 1000);
    }, [])

    return (
        <>
            <Card className='profile-starter-card bg-slate-blue border-radius-md'>
                <Row justify={'space-between'} align={'middle'}>
                    <Col>
                        <p className='pretitle font-color-oneamz-yellow'>Grow on Amazon using the OneAMZ web app!</p>
                        <h3 className='h-3 font-color-pure-white mt-0'>Elevate your storage experience with confidence</h3>
                    </Col>
                    <Col>
                        <NavLink to={'/tickets'}>
                            <Button className='btn btn-hover-dark mr-24 btn-text bg-pure-white font-color-slate-blue border-radius-lg z-index-1'>
                                <Row align={'middle'}>
                                    <Col span={4}>
                                        <SupportIcon className='wh-16 mr-8' />
                                    </Col>
                                    <Col span={20}>
                                        <p className='m-0'> <b>Çözüm Merkezi</b></p>
                                    </Col>
                                </Row>
                            </Button>
                        </NavLink>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default StarterCard