import { Button, Card, Col, Divider, Modal, Radio, Row, Select } from 'antd'
import React, { useState } from 'react'

interface ProgressCardProps {
    currentStep: number;
}

const ProgressBar: React.FC<ProgressCardProps> = ({ currentStep }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const getStepBgColor = (step: number) => {
        return currentStep >= step ? 'bg-slate-blue' : 'bg-slate-blue-05';
    };

    return (
        <>
            <Modal title='Amazon FBA Shipment List'
                onCancel={handleOk} // onCancel da handleOk ile aynı işlevi görecek şekilde ayarlandı
                footer={[
                ]}
                open={isModalOpen} onOk={handleOk}
            >
                <p className='text font-color-slate-blue'>FBA Siparişinizi Otomatik Eklemek İçin Mağaza Seçin</p>
                <Select options={[
                    { value: 'cd', label: 'Canada' },
                    { value: 'us', label: 'USA' },
                    { value: 'uk', label: 'UK' },

                ]} className='border-slate-blue border-radius-8 w-full btn amz-select-input' placeholder={<p className='text font-color-slate-blue'>Mağazanızı Seçin</p>}></Select>
                <Radio.Group className='w-full mt-24' >
                    <>
                        <Row align={'middle'} justify={'space-between'}>
                            <Col span={2}>
                                <Radio className='amz-radio border-slate-blue-02 border-radius-lg' value="1"></Radio>
                            </Col>
                            <Col span={5}>
                                <p className='table-title m-0'>SHIPMENT ID</p>
                                <p className='text fs-12 font-color-slate-blue-05 m-0'> YXU1</p>
                            </Col>
                            <Col span={9}>
                                <p className='table-title m-0'>SHIPMENT NAME</p>
                                <p className='text fs-12 font-color-slate-blue-05 m-0'> FBA STA (02/01/2024 06:31) - YXU1</p>
                            </Col>
                            <Col span={5}>
                                <p className='table-title m-0'>GÖNDERİLEN DEPO KODU</p>
                                <p className='text fs-12 font-color-slate-blue-05 m-0'> YXU1</p>
                            </Col>
                            <Col span={3}>
                                <p className='table-title m-0'>ÜLKE KODU</p>
                                <p className='text fs-12 font-color-slate-blue-05 m-0'> CA</p>
                            </Col>
                        </Row>
                        <Divider className='bg-slate-blue-02'></Divider>
                        <Row align={'middle'} justify={'space-between'}>
                            <Col span={2}>
                                <Radio className='amz-radio border-slate-blue-02 border-radius-lg' value="2"></Radio>
                            </Col>
                            <Col span={5}>
                                <p className='table-title m-0'>SHIPMENT ID</p>
                                <p className='text fs-12 font-color-slate-blue-05 m-0'> YXU1</p>
                            </Col>
                            <Col span={9}>
                                <p className='table-title m-0'>SHIPMENT NAME</p>
                                <p className='text fs-12 font-color-slate-blue-05 m-0'> FBA STA (02/01/2024 06:31) - YXU1</p>
                            </Col>
                            <Col span={5}>
                                <p className='table-title m-0'>GÖNDERİLEN DEPO KODU</p>
                                <p className='text fs-12 font-color-slate-blue-05 m-0'> YXU1</p>
                            </Col>
                            <Col span={3}>
                                <p className='table-title m-0'>ÜLKE KODU</p>
                                <p className='text fs-12 font-color-slate-blue-05 m-0'> CA</p>
                            </Col>
                        </Row>
                    </>
                </Radio.Group>
                <Divider className='bg-slate-blue-02'></Divider>
                <Row gutter={16}>
                    <Col span={12}>
                        <Button onClick={() => setIsModalOpen(false)} className='btn border-slate-blue btn-hover-dark btn-text w-full'>İptal</Button>
                    </Col>
                    <Col span={12}>
                        <Button className='btn btn-hover-white btn-text w-full bg-slate-blue-05 font-color-cloud'>Onayla</Button>
                    </Col>
                </Row>
            </Modal>
            <Card className='create-fba-progress-card bg-slate-blue border-radius-md'>
                <Row justify={'space-between'} align={'middle'}>
                    <Col span={24}>
                        <p className='pretitle font-color-oneamz-yellow'>OneAMZ Fulfillment Uygulamasını Kullanarak Amazonda Büyü!</p>
                        {currentStep === 0 &&
                            <Row justify={'space-between'}>
                                <Col>
                                    <div>
                                        <h3 className='h-3 font-color-pure-white m-0'>Kaliteli Ürünleriniz İçin Hızlı ve </h3>
                                        <h3 className='h-3 font-color-pure-white mt-0'>Güvenilir Depolama</h3>
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Card>
                            <p className='text m-0'><b>Depoya Ürün Gönderme Süreci</b></p>
                            <p className='text fs-12 mt-0'>1 adımdan
                                {currentStep === 0 && " 1'i "}
                                tamamlandı.</p>
                            <Row gutter={5}>
                                {[0].map(step => (
                                    <Col key={step} span={24}>
                                        <div className={`height-6 ${getStepBgColor(step)} border-radius-md`}></div>
                                    </Col>
                                ))}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default ProgressBar;