import { Button, Card, Col, Row, Image, Avatar, Modal, Divider } from 'antd'
import React, { useState } from 'react'
import { formatDateTime } from '../../../utils';
import { NavLink } from 'react-router-dom';
import AmazonProductInfo from '../../Common/AmazonProductInfo';
import AmazonProductImage from '../../Common/AmazonProductImage';
import closeIcon from '../../../assets/XCircle.svg';

interface InventoryItemCardProp {
    item: any;
}
const InventoryItemCard: React.FC<InventoryItemCardProp> = ({ item }) => {
    const displayText = item.product.name.length > 100 ? `${item.product.name.substring(0, 100)}...` : item.product.name;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedItemDetails, setSelectedItemDetails] = useState<any>(null);

    const currentRole = localStorage.getItem('userRole');

    const showDetailModal = (details: any) => {
        const sortedDetails = details.sort((a: any, b: any) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });

        setSelectedItemDetails(sortedDetails);
        setIsModalVisible(true);
    };

    return (
        <>
            <Card className='border-radius-md w-full p-24 mt-24'>
                <Row align={'middle'} justify={'space-between'}>
                    <Col xl={6} md={8} xs={24}>
                        <Row>
                            <Col className='mr-24'>
                                <AmazonProductImage asin={item.product.asin}></AmazonProductImage>
                            </Col>
                            <Col span={16}>
                                <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text-sm fs-12 m-0'><b>BOYUTLAR</b></p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'>
                            {item.product.width} X {item.product.length} X {item.product.height} <b>IN</b>
                        </p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'>
                            {item.product.weight} <b>LB</b>
                        </p>
                    </Col>
                    {(currentRole === "ADMIN") && <Col xl={2} md={8} xs={12}>
                        <p className='text-sm fs-12 m-0'><b>MÜŞTERİ</b></p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'><b>#{item?.user?.id}</b> {item?.user?.firstName} {item?.user?.lastName}</p>
                    </Col>}

                    <Col xl={4} md={8} xs={12}>
                        <p className='text-sm fs-12 m-0'><b>STOK</b></p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'>
                            {item.totalQty}
                        </p>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text-sm fs-12 m-0'><b>OLUŞTURMA TARİHİ</b></p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'>{formatDateTime(item.createdAt.toLocaleString())}</p>
                    </Col>
                    <Col>
                        <Button className='btn btn-hover-dark bg-cloud font-color-slate-blue btn-text border-radius-lg' onClick={() => showDetailModal(item.details)}>
                            Detay
                        </Button>
                    </Col>
                </Row>
            </Card>
            <Modal
                title="Stok Geçmişi"
                visible={isModalVisible}
                className='antd-modal-secondary '
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                closeIcon={<img src={closeIcon} height={24} alt="close" />}
            >
                <Row>
                    <Col span={24}>
                        <Card className='border-radius-md w-full p-24 mt-24 antd-card-body-p-0'>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <AmazonProductImage asin={item.product.asin}></AmazonProductImage>
                                </Col>
                                <Col span={18}>
                                    <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-24' justify={'space-between'} gutter={24}>
                    <Col>
                        <p className='text font-color-slate-blue'>
                            <b>Eklenen / Çıkan Ürün Adedi</b>
                        </p>
                    </Col>
                    <Col>
                        <p className='text font-color-slate-blue'>
                            <b>Güncellenme Tarihi</b>
                        </p>
                    </Col>
                </Row>
                {item.details?.map((detail: any) => (
                    <Row justify={'space-between'}>
                        <Col>
                            {detail.qty > 0 ?
                                <p>
                                    Envantere eklendi: <b className='font-color-success'>+{detail.qty}</b>
                                </p>
                                :
                                <p>
                                    Envanterden Gönderildi: <b className='font-color-error'>{detail.qty}</b>
                                </p>
                            }
                        </Col>
                        <Col>
                            <p className='text fs-14'>
                                {formatDateTime(detail.createdAt)}
                            </p>
                        </Col>
                        <Divider className='m-0'></Divider>
                    </Row>
                ))}
                <Row>
                    <Col span={24}>
                        <Button onClick={() => setIsModalVisible(false)} className='btn btn-text btn-hover-white bg-slate-blue font-color-cloud w-full border-radius-lg mt-24'>
                            Kapat
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default InventoryItemCard