import http from "../http-common";

const getAll = (filter = "") => {
    let role = localStorage.getItem("userRole");

    if (role === "CUSTOMER") {
        return http.get<Array<any>>(`/users/me/inventories${filter}`);
    }

    return http.get<Array<any>>(`/inventories${filter}`);
};

const inventorySearch = (input:any) => {
    return http.get(`/users/me/inventories?search=${input}`);
};

const getTotalStorageVolume = () => {
    return http.get(`/users/me/inventories/total-storage-volume`);
};

const getAllCount = (filter = "") => {
    let role = localStorage.getItem("userRole");
    if (role === "CUSTOMER") {
        return http.get<Array<any>>(`/users/me/orders/count${filter}`);
    }
    return http.get<Array<any>>(`/orders/count${filter}`);
};

const InventoryService = {
    getAll,
    inventorySearch,
    getAllCount,
    getTotalStorageVolume
};

export default InventoryService;