import { Avatar, Button, Card, Col, Divider, Form, Input, Row } from 'antd'
import { useEffect, useState } from 'react'
import UserService from '../../../services/UserService'
import { IUserRequest, IUserResponse } from '../../../types/User'
import { openNotification } from '../../../utils'

const ProfileCard = () => {
    const [form] = Form.useForm();
    const [user, setUser] = useState<IUserResponse>()
    const [firstLetter, setFirstLetter] = useState("");
    const [loading, setLoading] = useState(false);

    const onFinish = (values: any) => {
        const { email, id, ...rest } = values;
        const postObj: IUserRequest = rest;
        updateMe(postObj);
    };

    useEffect(() => {
        getMe()
    }, [])

    const updateMe = (postObj: IUserRequest) => {
        setLoading(true);
        UserService.updateMe(postObj)
            .then((response: any) => {
                setLoading(false);
                openNotification('success', 'Başarılı', "Profil bilgileri güncellendi");
                getMe();
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const getMe = () => {
        setLoading(true);
        UserService.getMe()
            .then((response: any) => {
                let data: IUserResponse = response.data;
                form.setFieldsValue({
                    firstName: data?.firstName,
                    lastName: data?.lastName,
                    email: data?.email,
                    phoneNumber: data?.phoneNumber,
                    id: data?.id,
                });
                setFirstLetter(data.firstName.charAt(0));
                setUser(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    return (
        <>
            <Card loading={loading} className='bg-cloud border-radius-md'>
                <Row gutter={24} align={'middle'}>
                    <Col>
                        <Avatar size={88}>{firstLetter}</Avatar>
                    </Col>
                    <Col>
                        <p className='h-3 font-color-slate-blue m-0'>{user?.firstName} {user?.lastName}</p>
                        <p className='text font-color-slate-blue-02 m-0'>{user?.email}</p>
                    </Col>
                </Row>
                <Row className='mt-24'>
                    <Col span={24}>
                        <p className='text m-0'><b>Profil Detayları</b></p>
                    </Col>
                    <Col span={24}>
                        <p className='text'>
                            Burada sağladığınız bilgiler, kullanıcı hesabınızın yönetimi
                            ve destek süreçlerimizin iyileştirilmesi için kullanılacaktır.
                            Güvenliğiniz ve gizliliğiniz bizim için en önemli önceliktir.
                        </p>
                    </Col>
                </Row>
                <Divider className='bg-slate-blue-02'></Divider>
                <Form
                    form={form}
                    className='mb-64'
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col>
                            <p className='table-title mt-0 mb-4'>İSİM</p>
                            <Form.Item
                                name="firstName"
                                rules={[
                                    { required: true, message: 'Lütfen İsminizi girin!' },
                                    {
                                        pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇ\s]+$/,
                                        message: 'İsim özel karakter içermemelidir!'
                                    }
                                ]}
                            >
                                <Input placeholder='İsim' size='large' style={{ padding: "0.7em" }} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <p className='table-title mt-0 mb-4'>SOYİSİM</p>
                            <Form.Item
                                name="lastName"
                                rules={[
                                    { required: true, message: 'Lütfen İsminizi girin!' },
                                    {
                                        pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇ\s]+$/,
                                        message: 'Soyisim özel karakter içermemelidir!'
                                    }
                                ]}
                            >
                                <Input placeholder='Soyisim' size='large' style={{ padding: "0.7em" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='table-title mt-0 mb-4'>ID</p>
                            <Form.Item
                                name="id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen Soyisminizi girin!'
                                    },
                                ]}
                            >
                                <Input disabled placeholder='ID' size='large' style={{ padding: "0.7em" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Card loading={loading} className='bg-pure-white border-radius-md'>
                            <p className='text font-color-slate-blue'>
                                <b>
                                    İletişim Bilgileri
                                </b>
                            </p>
                            <p className='text-small'>
                                Bu bölümdeki iletişim bilgileriniz, hesabınızla ilgili önemli güncellemeleri,
                                bildirimleri ve destek taleplerinizi yönetmek için kullanılacaktır.
                                Doğru ve güncel iletişim bilgileri sağlamanız, sizinle hızlı ve etkili bir şekilde
                                iletişim kurmamıza olanak tanır, böylece herhangi bir sorun veya ihtiyaç durumunda
                                size en iyi şekilde yardımcı olabiliriz.
                            </p>
                            <Row>
                                <Col>
                                    <p className='table-title mt-0 mb-4'>E-POSTA</p>
                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true, message: 'Lütfen geçerli bir email girin!', type: 'email' }]}
                                    >
                                        <Input readOnly placeholder='Email' size='large' style={{ padding: "0.7em" }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Col>
                                        <p className='table-title mt-0 mb-4'>TELEFON</p>
                                        <Form.Item
                                            name="phoneNumber"
                                            rules={[{ required: true, message: 'Lütfen telefon numaranızı girin!' }]}
                                        >
                                            <Input placeholder='Telefon' size='large' style={{ padding: "0.7em" }} />
                                        </Form.Item>
                                    </Col>
                                </Col>
                            </Row>
                            <Divider className='bg-slate-blue-02'></Divider>
                        </Card>
                    </Row>
                    <Row className='mt-24' justify={'end'}>
                        <Col>
                            <Button htmlType='submit' className='btn btn-hover-white btn-text bg-slate-blue border-radius-lg font-color-pure-white'>
                                Güncelle
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

export default ProfileCard