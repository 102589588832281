import http from "../http-common";

const create = (data: any) => {
    return http.post<any>(`/orders`, data);
};

const statusUpdate = (id: any, data: any) => {
    return http.put<any>(`/orders/${id}/status`, data);
};

const getAll = (filter = "") => {
    let role = localStorage.getItem("userRole");
    if (role == "WAREHOUSE_MANAGER" || role == "ADMIN") {
        return http.get<Array<any>>(`/orders${filter}`);
    }
    return http.get<Array<any>>(`/users/me/orders${filter}`);
};

const getFnskuLabel = (orderItemId: any) => {
    return http.get<any>(`order-items/${orderItemId}/fnsku/download`, { responseType: 'arraybuffer' });
};

const getFBALabel = (orderItemId: any) => {
    return http.get<any>(`orders/${orderItemId}/fba/download`, { responseType: 'arraybuffer' });
};

const getShippingLabel = (orderItemId: any) => {
    return http.get<any>(`orders/${orderItemId}/shipping-label/download`, { responseType: 'arraybuffer' });
};


const get = (id: any) => {
    return http.get<any>(`/orders/${id}`);
};

const getClaimOrderId = (claimOrderId: any) => {
    return http.get<any>(`/orders/claim-order/${claimOrderId}`);
};

const getAllCount = (filter = "") => {
    let role = localStorage.getItem("userRole");
    if (role == "WAREHOUSE_MANAGER" || role == "ADMIN") {
        return http.get<Array<any>>(`/orders/count${filter}`);
    }
    return http.get<Array<any>>(`/users/me/orders/count${filter}`);
};

const orderPay = (id: any) => {
    return http.post<any>(`/orders/${id}/payments`);
};

const updateTrackingCode = (id: any, trackingCode: any) => {

    const postObj = {
        trackingCode: trackingCode
    }

    return http.put<any>(`/order-items/${id}/tracking-code`, postObj);
};

const OrderService = {
    getAll,
    orderPay,
    statusUpdate,
    get,
    create,
    getAllCount,
    getClaimOrderId,
    updateTrackingCode,
    getFnskuLabel,
    getFBALabel,
    getShippingLabel
};

export default OrderService;