import http from "../http-common";

const authToken = (data: any) => {
    return http.post<any>("/auth/token", data);
};

const verificationCallback = (data: any) => {
    return http.post("/auth/email-verification", data);
};

const login = (data: any) => {
    return http.post("/auth/login", data);
};

const register = (data: any) => {
    return http.post("/auth/register", data);
};

const forgotPassword = (data: any) => {
    return http.post("/auth/reset-password-requests", data);
};

const verifyAgain = (data: any) => {
    return http.post("/auth/verification-send", data);
};

const resetPassword = (data: any) => {
    return http.post("/auth/reset-passwords", data);
};
const AuthService = {
    verificationCallback,
    login,
    register,
    forgotPassword,
    resetPassword,
    verifyAgain,
    authToken
};

export default AuthService;