
import React, { useState } from 'react'
import { Button, Card, Col, Form, Input, Row, InputNumber, Popconfirm, Empty, Collapse, Divider, Alert } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { formatMoney, openNotification } from '../../utils';
import InventoryService from '../../services/InvertoryService';
import ServiceList from './ServiceList';
import AmazonProductInfo from '../Common/AmazonProductInfo';
import AmazonProductImage from '../Common/AmazonProductImage';

interface FBMCreateStepProps {
    addProduct: (data: any) => void;
    removeProduct: (id: any) => void;
    setQty: (id: any, qty: any) => void;
    setPurchasePrice: (id: any, purchasePrice: any) => void;
    setServiceQty: (productId: any, serviceId: any, qty: any) => void;
    productList: [],
    totalQty: 0,
    totalWeight: 0,
    totalDeci: 0,
    warehouseList: [],
    warehouse: any,
    country: any,
}

const FBMCreateStep1: React.FC<FBMCreateStepProps> = ({ addProduct, removeProduct, setQty, setServiceQty, productList, totalQty, totalWeight, totalDeci }) => {
    const [productLoad, setProductLoad] = useState(false)
    const [asinFind] = Form.useForm();

    const handleSubmit = (values: any) => {
        setProductLoad(true);
        InventoryService.inventorySearch(values.asin)
            .then((response: any) => {
                let data: any = response.data;
                let product: any = { ...data[0].product, totalQty: data[0].totalQty };
                addProduct(product);
                setProductLoad(false);
                asinFind.resetFields();
            })
            .catch((e: Error) => {
                setProductLoad(false);
                openNotification('warning', 'Bulunamadı', 'Ürün envanterinizde mevcut değil')
            });
    };

    return <>
        <Card className='border-radius-md border-slate-blue-02 mt-24'>
            <h3 className='h-3 font-color-slate-blue mt-0'>
                Asın Kodu ile Envanterden Ürün Ekleyin!
            </h3>
            <p className='text fs-14'>
                FBM (Fulfillment by Merchant) yöntemi kullanılarak depodan direkt olarak teslim edilen ürünler, hızlı ve güvenilir bir alışveriş deneyimi sunar.Her ürün, kalite standartlarına uygun şekilde seçilir ve dikkatle paketlenir, böylece müşterilere zamanında ve sorunsuz bir şekilde ulaşır.
            </p>
            <Form form={asinFind} onFinish={handleSubmit}>
                <Row className='mt-24'>
                    <Col >
                        <Form.Item
                            className='mb-0'
                            name="asin"
                            rules={[
                                {
                                    required: true,
                                    message: 'Asin kodu giriniz',
                                }
                            ]}
                        >
                            <Input className='amz-input-44px' placeholder='Asin' ></Input>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button htmlType="submit" loading={productLoad} className='ml-24 btn btn-hover-dark bg-slate-blue font-color-slate-blue btn-text font-color-cloud'>
                            Ekle
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
        <Alert className='border-none border-radius-md mt-24'
            description={<p className='text fs-14 m-0'> Kargo ödeme tutarı Amazon'dan alınan ölçü bilgilerine göre hesaplanmaktadır.
                Paket depoya ulaştığı zaman ölçüler arasında bazen farklar oluşabilmektedir.
                Oluşacak olan farklardan dolayı kullanıcıya ekstra kargo ücreti ile ilgili
                ödeme yapması için bildirim yapılacaktır.
            </p>}
            type="info"
            showIcon
        />
        <h3 className='h-3 font-color-slate-blue mt-24 mb-24'>
            Sipariş Bilgileri
        </h3>
        <Row justify={"center"}>
            <Col span={24}>
                <Card className='bg-cloud border-radius-md' >
                    {productList.map((item: any, key) => (
                        <Collapse
                            key={key}
                            className='antd-order-detail-collapse bg-white border-radius-md mb-24 bg-cloud'
                            expandIconPosition={'end'}
                            items={[{
                                key: '1',
                                label:
                                    <>
                                        <Row onClick={(event) => {
                                            event.stopPropagation();
                                        }} align={"middle"} justify={"space-between"} gutter={[24, 24]}>
                                            <Col span={10}>
                                                <Row align={'middle'} justify={"space-between"} gutter={[24, 24]}>
                                                    <Col>
                                                        <AmazonProductImage asin={item.asin}></AmazonProductImage>
                                                    </Col>
                                                    <Col span={19} >
                                                        <AmazonProductInfo asin={item.asin} name={item.name}></AmazonProductInfo>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <p className='text-sm fs-12 m-0'><b>BOYUTLAR</b></p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p className='mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0'>
                                                            {item.length} x {item.width} x {item.height} in
                                                            <br />
                                                            {item.weight} lb
                                                        </p>
                                                    </Col>
                                                </Row>

                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <p className='text-sm fs-12 m-0'><b>STOK</b></p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {item.totalQty}
                                                    </Col>
                                                </Row>

                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <p className='text-sm fs-12 m-0'><b>Adet</b></p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col><InputNumber min={1} max={item.totalQty} onChange={(value: any) => setQty(item.id, value)} defaultValue={item.qty} /></Col>
                                                </Row>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }}>
                                                <ServiceList setQty={setServiceQty} product={item}></ServiceList>
                                            </Col>
                                            <Col >
                                                <Popconfirm
                                                    title="Ürün listeden çıkarılsın mı?"
                                                    onConfirm={() => removeProduct(item.id)}
                                                    okText="Evet"
                                                    cancelText="Hayır"
                                                >
                                                    <DeleteOutlined
                                                        className='cursor-pointer icon-size-20 mr-16' />
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    </>,
                                children:
                                    <>
                                        <Row justify={'space-between'} align={'top'}>
                                            <Col xl={12}>
                                                <h4 className='text mt-0 mb-8'><b>FBM ZORUNLU HİZMETLER</b></h4>
                                                <Row>
                                                    <Col span={9}>
                                                        <p className='text-small font-color-slate-blue mb-8'><b>Hizmet</b></p>
                                                    </Col>
                                                    <Col className='text-center' span={5}>
                                                        <p className='text-small font-color-slate-blue mb-8'><b>Birim Fiyatı</b></p>
                                                    </Col>
                                                    <Col className='text-center' span={5}>
                                                        <p className='text-small font-color-slate-blue mb-8'><b>Adet</b></p>
                                                    </Col>
                                                    <Col className='text-center' span={5}>
                                                        <p className='text-small font-color-slate-blue mb-8'><b>Toplam</b></p>
                                                    </Col>
                                                </Row>
                                                {item.services.map((service: any) => (
                                                    (service.qty > 0 && !service.isOptional) && <>
                                                        <Row key={service.id}>
                                                            <Col span={9}>
                                                                <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.name}</b></p>
                                                            </Col>
                                                            <Col className='text-center' span={5}>
                                                                <p className='text-small font-color-slate-blue-05 mb-8'><b> {formatMoney(service.fee)}</b></p>
                                                            </Col>
                                                            <Col className='text-center' span={5}>
                                                                <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.qty}</b></p>
                                                            </Col>
                                                            <Col className='text-center' span={5}>
                                                                <p className='text-small font-color-slate-blue mb-8'><b> {formatMoney((service.qty * service.fee))}</b></p>
                                                            </Col>
                                                        </Row>
                                                        <Divider className='m-0 mt-8' />
                                                    </>
                                                ))}
                                            </Col>
                                            <Col xl={12}>
                                                <h4 className='text mt-0 mb-8'><b>FBM EK HİZMETLER</b></h4>
                                                <Row>
                                                    <Col span={9}>
                                                        <p className='text-small font-color-slate-blue mb-8'><b>Hizmet</b></p>
                                                    </Col>
                                                    <Col className='text-center' span={5}>
                                                        <p className='text-small font-color-slate-blue mb-8'><b>Birim Fiyatı</b></p>
                                                    </Col>
                                                    <Col className='text-center' span={5}>
                                                        <p className='text-small font-color-slate-blue mb-8'><b>Adet</b></p>
                                                    </Col>
                                                    <Col className='text-center' span={5}>
                                                        <p className='text-small font-color-slate-blue mb-8'><b>Toplam</b></p>
                                                    </Col>
                                                </Row>
                                                {item.services.map((service: any) => (
                                                    (service.qty > 0 && service.isOptional) && <>
                                                        <Row key={service.id}>
                                                            <Col span={9}>
                                                                <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.name}</b></p>
                                                            </Col>
                                                            <Col className='text-center' span={5}>
                                                                <p className='text-small font-color-slate-blue-05 mb-8'><b> {formatMoney(service.fee)}</b></p>
                                                            </Col>
                                                            <Col className='text-center' span={5}>
                                                                <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.qty}</b></p>
                                                            </Col>
                                                            <Col className='text-center' span={5}>
                                                                <p className='text-small font-color-slate-blue mb-8'><b> {formatMoney((service.qty * service.fee))}</b></p>
                                                            </Col>
                                                        </Row>
                                                        <Divider className='m-0 mt-8' />
                                                    </>
                                                ))}
                                            </Col>
                                        </Row>
                                    </>
                            }]}></Collapse>
                    ))}
                    {(productList.length === 0) ? <Empty description="Ürün ekleyiniz" image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <></>}
                    <Row justify={'end'}>
                        <Col span={6}>
                            <p className='text-align-right font-color-slate-blue'>
                                <b>Toplam Ürün Miktarı: {totalQty}</b>
                            </p>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </>
}

export default FBMCreateStep1