import http from "../http-common";
import { IAisleRequest, IAisleResponse } from "../types/Aisle";

const getAll = () => {
    return http.get<Array<IAisleResponse>>(`/aisles`);
};

const get = (aisleId: number) => {
    return http.get<IAisleResponse>(`/aisles/${aisleId}`);
};

const update = (aisleId: any, data: IAisleRequest) => {
    return http.put<any>(`/aisles/${aisleId}`, data);
};

const create = (data: IAisleRequest) => {
    return http.post<any>(`/aisles`, data);
};

const remove = (aisleId: any) => {
    return http.delete<any>(`/aisles/${aisleId}`);
};

const AisleService = {
    getAll,
    get,
    update,
    create,
    remove
};

export default AisleService;