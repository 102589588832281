import { Button, Card, Col, Form, Input, Modal, Popconfirm, Row, Select, Switch, message } from 'antd'
import React, { useEffect, useState } from 'react'
import closeIcon from '../../../../assets/XCircle.svg';
import { DeleteOutlined } from '@ant-design/icons'
import ServicesService from '../../../../services/ServicesService';
import { formatMoney, openNotification } from '../../../../utils';

interface ServiceItemCardProps {
    service: any
    getServices: () => void
}

const { Item } = Form;

const ServiceItemCard: React.FC<ServiceItemCardProps> = ({ service, getServices }) => {
    // const displayText = item.product.name.length > 100 ? `${item.product.name.substring(0, 100)}...` : item.product.name;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedItemDetails, setSelectedItemDetails] = useState<any>(null);
    const [form] = Form.useForm();
    const [isOptional, setIsOptional] = useState(false);
    const [isPerUnit, setPerUnit] = useState(false);

    const showDetailModal = (details: any) => {
        setIsModalVisible(true);
    };

    const cancel = () => {
    };

    const confirm = (id: any) => {
        ServicesService.remove(id)
            .then((response: any) => {
                message.success('Servis Başarıyla Silindi');
                getServices()
            }).catch((e: Error) => {
                console.log(e)
            });
    };

    useEffect(() => {
        if (isModalVisible) {
            getService();
        }
    }, [isModalVisible])

    const getService = () => {
        form.setFieldsValue({
            name: service?.name,
            description: service?.description,
            isOptional: service?.isOptional,
            isPerUnit: service?.isPerUnit,
            type: service?.type,
            fee: service?.fee,
        });

        setIsOptional(service?.isOptional);
        setPerUnit(service?.isPerUnit);
    };

    const update = (values: any) => {

        let postObj = {
            "name": values.name,
            "description": values.description,
            "isOptional": values.isOptional,
            "isPerUnit": values.isPerUnit,
            "type": values.type,
            "fee": values.fee,
        }


        ServicesService.update(service.id, postObj)
            .then((response: any) => {
                getServices();
                setIsModalVisible(false)
                openNotification('success', 'Başarılı', 'Service Başarıyla Güncellendi')
            }).catch((e: Error) => {
            });
    }

    return (
        <>
            <Card className='border-radius-md w-full p-24 antd-card-body-p-0 p-8'>
                <Row align={'middle'} justify={'space-between'} gutter={[0,12]}>
                    <Col xl={4} md={8} xs={24}>
                        <p className='text-sm fs-16 m-0'><b>Hizmet</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {service.name}
                        </p>
                    </Col>
                    <Col xl={3} md={8} sm={12} xs={24}>
                        <p className='text-sm fs-16 m-0'><b>Açıklama</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {service.description}
                        </p>
                    </Col>
                    <Col xl={3} md={8} xs={12}>
                        <p className='text-sm fs-16 m-0'><b>Opsiyonel</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {service.isOptional === true ? 'Evet' : 'Hayır'}
                        </p>
                    </Col>
                    <Col xl={3} md={8} xs={12}>
                        <p className='text-sm fs-16 m-0'><b>Adet Başına</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {service.isPerUnit === true ? 'Evet' : 'Hayır'}
                        </p>
                    </Col>
                    <Col xl={3} md={8} xs={12}>
                        <p className='text-sm fs-16 m-0'><b>Hizmet Tipi</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {service.type === 'ORDER' ? 'Gönderi' : 'Ürün'}
                        </p>
                    </Col>
                    <Col xl={3} md={8} xs={12}>
                        <p className='text-sm fs-16 m-0'><b>Adet Ücret</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {formatMoney(service.fee)}
                        </p>
                    </Col>
                    <Col xl={3} md={8} xs={12}>
                        <Button className='btn btn-hover-dark bg-slate-blue-02 font-color-slate-blue btn-text border-radius-lg' onClick={() => showDetailModal(service)}>
                            Detay
                        </Button>
                    </Col>
                    <Col xl={3} md={8} xs={12} >
                        <Row justify={'start'}>
                            <Popconfirm
                                title="Servisi Sil"
                                description="Servisi Silmek istediğinize emin misiniz"
                                onConfirm={() => (confirm(service.id))}
                                onCancel={cancel}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                                <DeleteOutlined
                                    style={{ marginLeft: '10px', fontSize: "1.5em", cursor: "pointer" }} />
                            </Popconfirm>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <Modal
                title={<>{service.name}</>}
                visible={isModalVisible}
                className='antd-modal-secondary '
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                closeIcon={<img src={closeIcon} height={24} alt="close" />}
            >
                <Form form={form} onFinish={update} layout="vertical">
                    <Item
                        name="name"
                        label="Hizmet Adı"
                        rules={[
                            {
                                required: true,
                                message: 'Hizmet adı zorunlu',
                            }]}
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Item
                        name="description"
                        label="Açıklama"
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Row gutter={24}>
                        <Col>
                            <Item
                                name="isOptional"
                                label="Opsiyonel"
                            >
                                <Switch className='switch-slate-blue' checked={isOptional} onChange={() => setIsOptional(!isOptional)} />
                            </Item>

                        </Col>
                        <Col>
                            <Item
                                name="isPerUnit"
                                label="Adet Başına"
                            >
                                <Switch className='switch-slate-blue' checked={isPerUnit} onChange={() => setPerUnit(!isPerUnit)} />
                            </Item>
                        </Col>
                    </Row>
                    <Item
                        name="type"
                        label="Hizmet Tipi"
                        rules={[
                            {
                                required: true,
                                message: 'Hizmet Tipi',
                            }]}
                    >
                        <Select
                            className='amz-select-input border-slate-blue border-radius-8 amz-input'
                            style={{ width: "100%" }}
                            options={[
                                { value: 'ORDER', label: 'Gönderi' },
                                { value: 'ORDER_ITEM', label: 'Ürün' },
                            ]}
                        />
                    </Item>
                    <Item
                        name="fee"
                        label="Ücret"
                        rules={[
                            {
                                required: true,
                                message: 'Ücret zorunlu',
                            }]}
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Item>
                        <Row gutter={24}>
                           <Col span={12}>
                           <Button className='btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8' onClick={() => setIsModalVisible(false)} style={{ marginRight: '10px' }}>
                                İptal
                            </Button>
                           </Col>
                           <Col span={12}>
                           <Button className='btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8' htmlType="submit">
                                Güncelle
                            </Button>
                           </Col>
                        </Row>
                    </Item>
                </Form>
            </Modal>
        </>
    )
}

export default ServiceItemCard