import { Card, Col, Row, Image } from 'antd'
import FBAQuickAccessImg from '../../assets/dashboard-v2/fba-quickaccess.png'
import FBMQuickAccessImg from '../../assets/dashboard-v2/fbm-quickaccess.png'
import AddIcon from '../../assets/dashboard-v2/add-icon.png'
import { NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
import OneamzAppService from '../../services/OneamzAppService'
import { formatMoney } from '../../utils'

const QuickAccess = () => {
    const [balance, setBalance] = useState<number>()
    const [loading, setLoading] = useState(false);
    const externalToken = localStorage.getItem('externalAccessToken');
    const externalRefreshToken = localStorage.getItem('externalRefreshToken');
    
    useEffect(() => {
        getBalance();
    }, [])

    const getBalance = () => {
        setLoading(true)
        OneamzAppService.getBalance()
            .then((response: any) => {
                let CurrentBalance: number = response.data.balance
                setBalance(CurrentBalance);
                setLoading(false);
            }).catch((e: Error) => {
            });
    }

    return (
        <>
            <Card className='bg-cloud border-radius-md h-full' >
                <Row justify={'space-between'}>
                    <Col>
                        <h3 className='h-3 m-0'>Hızlı Erişim</h3>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col className='mt-24' xl={8} sm={12} xs={24}>
                        <NavLink to={'/fba/new'}>
                            <Card className='bg-oneamz-yellow border-radius-md h-full pointer'>
                                <Row justify={'center'}>
                                    <h3 className='text fs-24'><b className=''>FBA</b> Gönderisi Oluştur</h3>
                                </Row>
                                <Row justify={'center'}>
                                    <Image preview={false} className='img-fluid' src={FBAQuickAccessImg}></Image>
                                </Row>
                            </Card>
                        </NavLink>
                    </Col>
                    <Col className='mt-24' xl={8} sm={12} xs={24}>
                        <NavLink to={'/fbm/new'}>
                            <Card className='bg-slate-blue-02 border-radius-md h-full pointer'>
                                <Row justify={'center'}>
                                    <h3 className='text fs-24'><b>FBM</b> Gönderisi Oluştur</h3>
                                </Row>
                                <Row justify={'center'}>
                                    <Image preview={false} className='img-fluid' src={FBMQuickAccessImg}></Image>
                                </Row>
                            </Card>
                        </NavLink>
                    </Col>
                    <Col className='mt-24' xl={8} sm={12} xs={24}>
                        <NavLink target='_blank' to={`${process.env.REACT_APP_EXTERNAL_BASE_URL}/public/module/callback?accessToken=${externalToken}&refreshToken=${externalRefreshToken}&redirectUrl=/balance?amount%3D100%26openPaymentModal%3Dtrue`}>
                            <Card loading={loading} className='bg-pure-white border-radius-md card-h-full h-full pointer pb-48'>
                                <Row className='h-full'>
                                    <Col span={24}>
                                        <Row align={'top'}>
                                            <Image preview={false} height={48} width={48} src={AddIcon}></Image>
                                        </Row>
                                    </Col>
                                    <Col className='h-full' span={24}>
                                        <Row className='h-full' align={'bottom'}>
                                            <Col>
                                                <h3 className='h-3 m-0'>{formatMoney(balance)}</h3>
                                                <p className='m-0 text fs-14'>Toplam Bakiye</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </NavLink>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default QuickAccess