import { Col, Row } from 'antd';
import React from 'react';

interface ReceiverProps {
    receiver: any,
}

const Receiver: React.FC<ReceiverProps> = ({ receiver }) => {
    return (
        <>
            <Row>
                <Col md={6} sm={12} xs={12}>
                    <p className='text-small font-color-slate-blue-06 m-0'><b>Ad Soyad</b></p>
                    <h3 className='mt-0'><b>{receiver?.fullName} </b></h3>
                </Col>
                <Col md={6} sm={12} xs={12}>
                    <p className='text-small font-color-slate-blue-06 m-0'><b>Ülke</b></p>
                    <h3 className='mt-0'><b>{receiver?.country} </b></h3>
                </Col>
                <Col md={6} sm={24} xs={24}>
                    <p className='text-small font-color-slate-blue-06 m-0'><b>Adres 1 - 2</b></p>
                    <p className='mt-0 mb-0 h-4'><b>{receiver?.addressLine1} </b></p>
                    <p className='mt-0 h-4'><b>{receiver?.addressLine2} </b></p>

                </Col>
                <Col md={6} sm={12} xs={12}>
                    <p className='text-small font-color-slate-blue-06 m-0'><b>Eyalet</b></p>
                    <h3 className='mt-0'><b>{receiver?.state} </b></h3>
                </Col>
                <Col md={6} sm={12} xs={12}>
                    <p className='text-small font-color-slate-blue-06 m-0'><b>Şehir</b></p>
                    <h3 className='mt-0'><b>{receiver?.city} </b></h3>
                </Col>
                <Col md={6} sm={12} xs={12}>
                    <p className='text-small font-color-slate-blue-06 m-0'><b>Posta Kodu</b></p>
                    <h3 className='mt-0'><b>{receiver?.postCode} </b></h3>
                </Col>
                <Col md={6} sm={12} xs={12}>
                    <p className='text-small font-color-slate-blue-06 m-0'><b>Telefon</b></p>
                    <h3 className='mt-0'><b>{receiver?.phoneNumber} </b></h3>
                </Col>
                <Col md={6} sm={12}xs={12}>
                    <p className='text-small font-color-slate-blue-06 m-0'><b>E-Posta</b></p>
                    <h3 className='mt-0'><b>{receiver?.email} </b></h3>
                </Col>
            </Row>
        </>
    );
}

export default Receiver;
