import { Input, Form, Button, Tooltip } from 'antd'
import React, { useState } from 'react'
import ClaimOrderService from '../../services/ClaimOrderService';
import { openNotification } from '../../utils';
import OrderService from '../../services/OrderService';
import { SaveOutlined } from '@ant-design/icons';

interface TrackingCodeUpdateProps {
    order: any;
    orderItem: any;
}

const TrackingCodeUpdate: React.FC<TrackingCodeUpdateProps> = ({ order, orderItem }) => {
    const [trackingCode] = Form.useForm();
    const [loading, setLoading] = useState(false)

    const handleSubmit = (values: any) => {
        setLoading(true);

        if (order?.type === "CLAIM_ORDER") {
            ClaimOrderService.updateTrackingCode(orderItem.id, values.trackingCode)
                .then((response: any) => {
                    setLoading(false);
                    openNotification('success', 'Başarılı', "Kargo Takip Kodu kaydedildi");
                }).catch((e: Error) => {
                    setLoading(false);
                    openNotification('error', 'Hata', "Kargo Takip Kodu kaydedilmedi");
                });

        } else {
            OrderService.updateTrackingCode(orderItem.id, values.trackingCode)
                .then((response: any) => {
                    setLoading(false);
                    openNotification('success', 'Başarılı', "Kargo Takip Kodu kaydedildi");
                }).catch((e: Error) => {
                    setLoading(false);
                    openNotification('error', 'Hata', "Kargo Takip Kodu kaydedilmedi");
                });
        }
    };

    return (
        <>
            <p className='text-small mb-0 font-color-slate-blue-05 mt-16'>
                <Tooltip title={orderItem?.product?.name}>
                    *{orderItem?.product?.asin} - Ürün İçin Tracking Code Yazın
                </Tooltip>
            </p>
            <Form form={trackingCode} layout="vertical" onFinish={handleSubmit}>
                <Form.Item
                    name="trackingCode"
                    rules={[
                        {
                            required: true,
                            message: 'Kargo Takip Kodu giriniz',
                        }
                    ]}
                >
                    <Input.Search
                        size='large'
                        loading={loading}
                        defaultValue={orderItem?.trackingCode}
                        placeholder="Tracking Code"
                        enterButton={<Button loading={loading} size="large" className='bg-slate-blue font-color-cloud' htmlType="submit"><SaveOutlined /></Button>}
                    />
                </Form.Item>
            </Form>
        </>
    )
}

export default TrackingCodeUpdate