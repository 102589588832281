import StarterCard from '../../components/V2/ProfileV2/StarterCard'
import { Card, Col, Row } from 'antd'
import Graph from '../../components/DashboardV2/Graph'
import Wallet from '../../components/V2/WalletV2/Wallet'
import TransactionCard from '../../components/V2/WalletV2/TransactionCard'
import OneamzAppService from '../../services/OneamzAppService'
import { useEffect, useState } from 'react'

const WalletV2 = () => {
  const [transactions, setTransactions] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getBalanceHistory();
  }, [])

  const getBalanceHistory = () => {
    setLoading(true)
    let query = '?draw=1start=0&length=100&timezone=%2B01:00&orderColumn=0&orderDirection=desc';
    OneamzAppService.getBalanceHistory(query)
      .then((response: any) => {
        setTransactions(response.data.data)
        setLoading(false)
      }).catch((e: Error) => {
        setLoading(false)
      });
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard></StarterCard>
        </Col>
      </Row>
      <Row className='mt-24' gutter={24}>
        <Col md={16} sm={24}>
          <Wallet></Wallet>
        </Col>
        <Col md={8} sm={24}>
          <Graph></Graph>
        </Col>
      </Row>
      <Row className='mt-24'>
        <Col span={24}>
          <Card loading={loading} className='bg-cloud border-radius-md'>
            <h3 className='h-3 m-0'>İşlemler</h3>
            {transactions.map((transaction) => (
              <TransactionCard transaction={transaction}></TransactionCard>
            ))}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default WalletV2