import { Button, Card, Col, Popconfirm, Radio, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react';
import { IResponseOrder } from '../../../types/Order';
import ClaimOrderService from '../../../services/ClaimOrderService';
import { openNotification } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import OrderService from '../../../services/OrderService';

interface OrderDetailStatusProps {
    order: IResponseOrder
}
const OrderDetailStatus: React.FC<OrderDetailStatusProps> = ({ order }) => {
    const navigate = useNavigate();
    const [value, setValue] = useState<string[]>([]);
    // const [effectiveStatus, setEffectiveStatus] = useState<string>("");
    const statusOrder = ["INCOMING", "WAITING", "APPROVED", "PROCESSING", "COMPLETE", "SHIPPED"];
    const currentRole = localStorage.getItem('userRole');

    const statusTranslations: any = {
        "INCOMING": "BEKLEMEDE",
        "WAITING": "ONEAMZ DEPODA",
        "APPROVED": "ÜRÜN KABUL",
        "PROCESSING": "İŞLEMDE",
        "COMPLETE": "TAMAMLANDI",
        "SHIPPED": "KARGOLANDI",
    };

    const optionStatus: any[] =
        [
            // {key: "INCOMING", value: 'BEKLEMEDE'},
            { value: "WAITING", label: 'ONEAMZ DEPODA' },
            { value: "APPROVED", label: 'ÜRÜN KABUL' },
            { value: "PROCESSING", label: 'İŞLEMDE' },
            { value: "COMPLETE", label: 'TAMAMLANDI' },
            { value: "SHIPPED", label: 'KARGOLANDI' },
        ];

    const radioButtonChecker = (status: string) => {
        return value.includes(status);
    }

    useEffect(() => {
        // INCOMING durumu için özel bir kontrol ekliyoruz
        // const effectiveStatus = order?.orderStatus === "INCOMING" ? "WAITING" : order?.orderStatus;
        const statusIndex = statusOrder.indexOf(order?.orderStatus);
        if (statusIndex !== -1) {
            setValue(statusOrder.slice(0, statusIndex + 1));
        }
    }, [order?.orderStatus]);

    const confirm = () => {
        ClaimOrderService.cancel(order.id)
            .then((response: any) => {
                openNotification('success', 'Başarılı', "Gönderi iptal edildi");
                if (order?.orderType == 'FBA_SHIPMENT') {
                    navigate('/fba')
                } else if (order?.orderType == 'FBM_SHIPMENT') {
                    navigate('/fbm')
                } else if (order?.orderType == 'STORAGE') {
                    navigate('/storage')
                }
            });
    }

    const changeStatus = (value: any) => {
        let postObj = {
            "status": value
        }
        OrderService.statusUpdate(order.orderId, postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', "Gönderi statüsü değiştirildi");
            })
            .catch((e: Error) => {
            });
    }

    return (
        <>
            <Card className='border-radius-md bg-slate-blue'>
                <Row justify={'space-between'} align={"middle"}>
                    <Col span={24}>
                        <Row justify={"space-between"}>
                            {statusOrder.map((status, index) => (
                                <Col key={index}>
                                    <Radio
                                        className={`cursor-default amz-radio-oneamz-yellow table-title ${order?.orderStatus === status || (order?.orderStatus === "INCOMING" && status === "WAITING") ? 'font-color-pure-white' : 'font-color-amz-gray'}`}
                                        checked={radioButtonChecker(status)}
                                    >
                                        {statusTranslations[status]}
                                    </Radio>
                                </Col>
                            ))}
                        </Row>
                        <Row justify={"end"}>
                            <Popconfirm
                                title="Gönderi İptal"
                                description="Gönderiyi iptal etmek istiyor musunuz?"
                                onConfirm={confirm}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                                <Button className='btn btn-hover-dark border-radius-12 bg-cloud btn-text font-color-slate-blue'>İptal Et</Button>
                            </Popconfirm>
                        </Row>
                    </Col>
                   
                    {(order?.type === 'ORDER' && currentRole === 'ADMIN') && <Col span={4}>
                        <Select className='amz-input w-full' placeholder="Statu Değiştir" options={optionStatus} defaultValue={order?.orderStatus} onChange={(value: any) => { changeStatus(value) }}>
                        </Select>
                    </Col>}
                </Row>
            </Card>
        </>
    )
}

export default OrderDetailStatus