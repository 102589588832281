import ClaimOrderService from '../../services/ClaimOrderService'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IResponseOrder } from '../../types/Order';
import OrderDetailCard from '../../components/V2/OrderDetailV2/OrderDetailCard';
import { Col, Row } from 'antd';
import OrderDetailStatus from '../../components/V2/OrderDetailV2/OrderDetailStatus';
import StorageDetail from './OrderDetail/StorageDetail';
import StorageDetailManager from './OrderDetail/StorageDetailManager';
import OrderService from '../../services/OrderService';
import FBAShipmentDetail from './OrderDetail/FBAShipmentDetail';
import FBAShipmentDetailManager from './OrderDetail/FBAShipmentDetailManager';
import FBMShipmentDetailManager from './OrderDetail/FBMShipmentDetailManager';
import FBMShipmentDetail from './OrderDetail/FBMShipmentDetail';

const ClaimOrderDetail = () => {
  let { id, claimOrderId } = useParams();
  const [order, setOrder] = useState<any>();
  const [title, setTitle] = useState<any>("");
  let currentRole = localStorage.getItem("userRole");

  useEffect(() => {
    if (claimOrderId !== undefined) {
      getOrderDetail(claimOrderId);
    }
    if (id !== undefined) {
      getClaimOrderDetail(id);
    }
  }, [])

  useEffect(() => {
    switch (order?.orderType) {
      case "STORAGE":
        setTitle("DEPO GÖNDERİ DETAYI")
        break;
      case "FBA_SHIPMENT":
        setTitle("FBA GÖNDERİ DETAYI")
        break;
      case "FBM_SHIPMENT":
        setTitle("FBM GÖNDERİ DETAYI")
        break;
      default:
        break;
    }

  }, [order])

  const getClaimOrderDetail = (id: string) => {
    ClaimOrderService.get(id)
      .then((response: any) => {
        const data: IResponseOrder = new IResponseOrder(response.data);
        setOrder(data);
      }).catch((e: Error) => {
        console.log(e)
      });
  }

  const getOrderDetail = (id: string) => {
    OrderService.getClaimOrderId(id)
      .then((response: any) => {
        const data: IResponseOrder = new IResponseOrder(response.data);
        setOrder(data);
      }).catch((e: Error) => {
        console.log(e)
      });
  }

  const changeQty = (id: any, qty: any) => {
    if (qty === null) {
      qty = 0;
    }
    let data = order;
    let orderObj = order.items.map((updatedItem: any) => {
      if (updatedItem.id === id) {
        updatedItem.approvalQty = qty;
        return updatedItem;
      }
      return updatedItem;
    });

    data.items = orderObj;
    setOrder(data);
  }

  return (
    <>
      <OrderDetailCard title={title} order={order}></OrderDetailCard>
      <Row className='mt-24'>
        <Col span={24}>
          <OrderDetailStatus order={order}></OrderDetailStatus>
        </Col>
      </Row>
      {(order?.orderType) === 'STORAGE' &&
        <>
          {(currentRole === "CUSTOMER") && <StorageDetail order={order}></StorageDetail>}
          {(currentRole === "ADMIN") && <StorageDetailManager order={order} setQty={changeQty}></StorageDetailManager>}
        </>
      }
      {(order?.orderType) === 'FBA_SHIPMENT' &&
        <>
          {(currentRole === "CUSTOMER") && <FBAShipmentDetail order={order} setQty={changeQty}></FBAShipmentDetail>}
          {(currentRole === "ADMIN") && <FBAShipmentDetailManager order={order} setQty={changeQty}></FBAShipmentDetailManager>}
        </>
      }
      {(order?.orderType) === 'FBM_SHIPMENT' &&
        <>
          {(currentRole === "CUSTOMER") && <FBMShipmentDetail order={order} ></FBMShipmentDetail>}
          {(currentRole === "ADMIN") && <FBMShipmentDetailManager order={order} setQty={changeQty}></FBMShipmentDetailManager>}
        </>
      }
    </>
  )
}

export default ClaimOrderDetail