import { Card, Col, Input, Row } from 'antd'
import AmazonProductInfo from '../../../components/Common/AmazonProductInfo'
import TextArea from 'antd/es/input/TextArea'
import ClaimOrderService from '../../../services/ClaimOrderService'
import { IResponseOrder } from '../../../types/Order'
import AmazonProductImageAvatar from '../../../components/Common/AmazonProductImageAvatar'

interface OrderDetailCardProps {
    order: IResponseOrder,
    orderItem: any,
    setQty: (id: any, qty: any) => void;
}

const StorageItemCardManager: React.FC<OrderDetailCardProps> = ({ order, orderItem, setQty }) => {

    const readOnly = order.type === "ORDER" ? true : false;

    const updateDamageQty = (orderItemId: any, value: any) => {
        console.log(value);
        if (value === 0 || value > 0) {
            ClaimOrderService.updateDamage(orderItemId, value)
                .then((response: any) => {
                }).catch((e: Error) => {
                });
        }
        return false;
    };

    const updateNote = (orderItemId: any, e: any) => {
        ClaimOrderService.updateNote(orderItemId, e.target.value)
            .then((response: any) => {
            }).catch((e: Error) => {
            });
    };

    return (
        <>
            <Card className='border-radius-md w-full p-24 mt-24'>
                <Row align={'top'} justify={'start'}>
                    <Col xl={16} md={8} xs={24}>
                        <Row>
                        <Col className='mr-24'>
                                <AmazonProductImageAvatar orderItem={orderItem}></AmazonProductImageAvatar>
                            </Col>
                            <Col span={16}>
                                <AmazonProductInfo asin={orderItem.product.asin} name={orderItem.product.name}></AmazonProductInfo>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text-sm fs-12 m-0'><b>BOYUTLAR</b></p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'>
                            {orderItem.product.width} X {orderItem.product.length} X {orderItem.product.height} <b>IN</b>
                        </p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'>
                            {orderItem.product.weight} <b>LB</b>
                        </p>
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className='mt-24'>
                    <Col span={12}>
                        <p className='text-sm fs-12 m-0'><b>HASARLI</b></p>
                        <Input
                            className='amz-input w-full'
                            placeholder="Hasarlı"
                            readOnly={readOnly}
                            defaultValue={orderItem.damage} onChange={(e) => updateDamageQty(orderItem.id, e.target.value)}
                        />
                    </Col>
                    <Col span={12}>
                        <p className='text-sm fs-12 m-0'><b>ONAYLI</b></p>
                        <Input
                            className='amz-input w-full'
                            placeholder="Onaylı"
                            readOnly={readOnly}
                            defaultValue={0}
                            onChange={(e) => setQty(orderItem.id, e.target.value)}
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className='mt-24'>
                    <Col span={24}>
                        <p className='text-sm fs-12 m-0'><b>NOT</b></p>
                        <TextArea showCount
                            readOnly={readOnly}
                            placeholder="Ürünle İlgili bilgilendirme İçin Not Yazın..."
                            defaultValue={orderItem.note}
                            onChange={(value) => updateNote(orderItem.id, value)}
                            style={{ height: '150px' }}
                        > </TextArea>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default StorageItemCardManager