import { Card, Col, Divider, Row } from 'antd'
import { CopyClipboard } from './CopyClipboard';

interface WarehouseCardProps {
    shipmentCode: "",
}

const WarehouseCard: React.FC<WarehouseCardProps> = ({ shipmentCode }) => {

    return (
        <>
            <Card>
                <h3 className='font-color-slate-blue mb-16 fs-24 mt-0'><b>OneAmz Depo Adresi</b></h3>
                <Row justify={"space-between"} align={"middle"}>
                    <Col><b>Full/Company Name:</b></Col>
                    <Col> <CopyClipboard text={shipmentCode} /></Col>
                </Row>
                <Divider className='bg-slate-blue-01 m-0'></Divider>

                <Row justify={"space-between"} align={"middle"}>
                    <Col><b>Phone:</b></Col>
                    <Col> <CopyClipboard text={'917 306 6033'} /></Col>
                </Row>
                <Divider className='bg-slate-blue-01 m-0'></Divider>

                <Row justify={"space-between"} align={"middle"}>
                    <Col><b>Address Line 1:</b></Col>
                    <Col> <CopyClipboard text={'356 GETTY AVE'} /></Col>
                </Row>
                <Divider className='bg-slate-blue-01 m-0'></Divider>

                <Row justify={"space-between"} align={"middle"}>
                    <Col><b>Address Line 2:</b></Col>
                    <Col> <CopyClipboard text={'BLD-5A'} /></Col>
                </Row>
                <Divider className='bg-slate-blue-01 m-0'></Divider>

                <Row justify={"space-between"} align={"middle"}>
                    <Col><b>City:</b></Col>
                    <Col> <CopyClipboard text={'CLIFTON'} /></Col>
                </Row>
                <Divider className='bg-slate-blue-01 m-0'></Divider>

                <Row justify={"space-between"} align={"middle"}>
                    <Col><b>State:</b></Col>
                    <Col> <CopyClipboard text={'NJ'} /></Col>
                </Row>
                <Divider className='bg-slate-blue-01 m-0'></Divider>

                <Row justify={"space-between"} align={"middle"}>
                    <Col><b>ZIP Code:</b></Col>
                    <Col> <CopyClipboard text={'07011'} /></Col>
                </Row>
                <Divider className='bg-slate-blue-01 m-0'></Divider>

                <Row justify={"space-between"} align={"middle"}>
                    <Col><b>Country:</b></Col>
                    <Col> <CopyClipboard text={'United States'} /></Col>
                </Row>
            </Card>
        </>
    )
}

export default WarehouseCard