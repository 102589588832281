import { Col, Row, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { openNotification } from '../../utils';
import FileUploadService from '../../services/FileUploadService';
import OrderService from '../../services/OrderService';
import ClaimOrderService from '../../services/ClaimOrderService';
import { ReactComponent as UploadIcon } from '../../assets/V2/OrderDetailV2/upload.svg';


interface FBALabelProps {
    order: any,
}

const FBALabel: React.FC<FBALabelProps> = ({ order }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploaded, setUploaded] = useState(false);
    const [fbaLabel, setFbaLabel] = useState(null);

    const getFBALabel = (id: any) => {

        if (order?.claimOrderId === undefined) {
            ClaimOrderService.getFBALabel(id)
                .then((response: any) => {
                    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                    const pdfUrl = URL.createObjectURL(pdfBlob);

                    // Yeni bir yazdırma penceresi oluştur
                    const printWindow = window.open(pdfUrl);
                    if (printWindow) {
                        printWindow.onload = () => {
                            printWindow.print(); // PDF yüklendikten sonra yazdır
                            URL.revokeObjectURL(pdfUrl); // Kullanılmayan URL'yi temizle
                        };
                    }
                }).catch((e: Error) => {

                });
        } else {
            OrderService.getFBALabel(id)
                .then((response: any) => {
                    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                    const pdfUrl = URL.createObjectURL(pdfBlob);

                    const printWindow = window.open(pdfUrl);
                    if (printWindow) {
                        printWindow.onload = () => {
                            printWindow.print(); // PDF yüklendikten sonra yazdır
                            URL.revokeObjectURL(pdfUrl); // Kullanılmayan URL'yi temizle
                        };
                    }
                }).catch((e: Error) => {
                    
                });
        }

    };

    const props: UploadProps = {
        onRemove: (file) => {
            setFileList([]);
        },
        beforeUpload: (file) => {
            if (fileList.length >= 1) {
                openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
                // If there's already a file in the list, reject the new file
                return Upload.LIST_IGNORE;
            }
            // If not, add the new file to the list
            setFileList([file]);
            return false;
        },
        fileList,
        defaultFileList: fileList, // Show the initial file list
        accept: '.pdf', // Accept only PDF files
        multiple: false, // Disable multiple file selection
    };

    useEffect(() => {
        if (fileList.length > 0) {
            if (order?.claimOrderId === undefined) {
                FileUploadService.uploadFbaLabel(order?.id, fileList[0])
                    .then((response: any) => {
                        openNotification('success', 'Başarılı', "FBA Label yüklendi");
                        setUploaded(true);
                    })
                    .catch((e: Error) => {
                        console.log(e);
                        openNotification('warning', 'Hata', "FBA Label yüklenemedi");
                    });
            } else {
                FileUploadService.uploadOrderFbaLabel(order?.id, fileList[0])
                    .then((response: any) => {
                        openNotification('success', 'Başarılı', "FBA Label yüklendi");
                        setUploaded(true);
                    })
                    .catch((e: Error) => {
                        console.log(e);
                        openNotification('warning', 'Hata', "FBA Label yüklenemedi");
                    });
            }
        }
    }, [fileList])

    useEffect(() => {
        setFbaLabel(order?.fbaLabel)
    }, [order])

    return (
        <>
            {(fbaLabel != null || uploaded) ?
                <Row justify={"start"} align={"middle"}>
                    <Col xl={2}>
                        <UploadIcon className='pointer'></UploadIcon>
                    </Col>
                    <Col xl={20}>
                        <p className='link mb-0 pointer' onClick={() => getFBALabel(order?.id)}>FBA Etiketini Göster</p>
                        <p className='text fs-12 mt-0 font-color-success'>
                            FBA Etiketi Yüklendi</p>
                    </Col>
                    <Col>
                        <Tooltip title="FBA Etiketini Sil">
                            <DeleteOutlined onClick={() => { setUploaded(false); setFbaLabel(null); setFileList([]); }} className='cursor-pointer icon-size-20' style={{ marginLeft: '5px' }} />
                        </Tooltip>
                    </Col>
                </Row>
                : <Upload {...props}>
                    <Row gutter={[16,16]} justify={"start"} align={"middle"}>
                    <Col span={4}>
                            <UploadIcon className='pointer'></UploadIcon>
                        </Col>
                        <Col span={20}>
                            <p className='link mb-0 pointer'>FBA Etiketini Yükleyin</p>
                            <p className='text fs-12 mt-0 font-color-error'>FBA Etiketi Bekleniyor</p>
                        </Col>
                    </Row>
                </Upload>}
        </>
    )
}

export default FBALabel