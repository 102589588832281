import { Card } from 'antd'
import { IResponseOrder } from '../../../types/Order';
import { formatDateTime } from '../../../utils';


interface OrderDetailCardProps {
    title: String,
    order: IResponseOrder
}
const OrderDetailCard:React.FC<OrderDetailCardProps> = ({title, order}) => {
    return (
        <>
            <Card className='bg-cloud order-detail-card border-radius-md'>
                <h3 className='h-3 mb-0'>{title}</h3>
                <p className='text font-color-slate-blue mt-0 mb-0'><b>{order?.orderCode}</b></p>
                <p className='text font-color-slate-blue-06 mt-8'><b>{formatDateTime(order?.createdAt.toString())}</b></p>
            </Card>
        </>
    )
}

export default OrderDetailCard