import http from "../http-common";
import { IBinRequest, IBinResponse } from "../types/Bin";

const getAll = () => {
    return http.get<Array<IBinResponse>>(`/bins`);
};

const get = (binId: number) => {
    return http.get<IBinResponse>(`/bins/${binId}`);
};

const update = (binId: any, data: IBinRequest) => {
    return http.put<any>(`/bins/${binId}`, data);
};

const create = (data: IBinRequest) => {
    return http.post<any>(`/bins`, data);
};

const remove = (binId: any) => {
    return http.delete<any>(`/bins/${binId}`);
};

const BinService = {
    getAll,
    get,
    update,
    create,
    remove
};

export default BinService;