import { useEffect, useState } from 'react'
import SettingsRouter from '../components/Settings/SettingsRouter'
import { Card, Col, Row } from 'antd'
import RoleService from '../services/RoleService';
import { IRoleResponseData } from '../types/Role';
import { ColumnsType } from 'antd/es/table';
import RoleDetail from './RoleDetail';
import SettingsCard from '../components/V2/SettingsV2/SettingsCard';
import RoleItemCard from '../components/V2/SettingsV2/RolesV2/RoleItemCard';

const Roles = () => {
    const [roles, setRoles] = useState<IRoleResponseData[]>([]);
    const [loading, setLoading] = useState(true);

    const columns: ColumnsType<IRoleResponseData> = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'action',
        },
        {
            title: 'Show',
            dataIndex: 'Show',
            key: 'action',
            render: (_, row) => (
                <RoleDetail id={row.id} />
            ),
        },

    ];

    useEffect(() => {
        getRoles();
    }, [])

    const getRoles = () => {
        setLoading(true);
        RoleService.getAll()
            .then((response: any) => {
                let data = response.data;
                setRoles(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <SettingsCard />
                </Col>
            </Row>
            <SettingsRouter></SettingsRouter>
            <Card className='bg-cloud border-radius-md' loading={loading}>
                <Row gutter={[24, 24]}>
                    {roles.map((role: any) => (
                        <Col span={24}>
                            <RoleItemCard getRoles={getRoles} role={role} />
                        </Col>
                    ))}
                </Row>
            </Card>
        </>
    )
}

export default Roles