import { Card, Col, Row } from 'antd'
import { ReactComponent as FBMActive } from '../../../assets/V2/LayoutV2/fbm-active.svg'

interface IncompleteFBMProps {
    value: any
    loading: boolean
}
const IncompleteFBM:React.FC<IncompleteFBMProps> = ({ value, loading }) => {
  return (
    <>
        <Card loading={loading} className='bg-slate-blue-01 border-radius-md h-full'>
            <Row justify={'end'}>
                <Col>
                <div className='pt-16 pb-12 pr-16 pl-16 bg-slate-blue-02 border-radius-lg'>
                    <FBMActive  />
                </div>
                </Col>
            </Row>
            <Row>
                <Col>
                <h2 className='h-2 mb-0 font-color-slate-blue'>{value}</h2>
                <p className='m-0 text font-color-slate-blue'>Tamamlanmamış FBM Siparişi</p>
                </Col>
            </Row>
        </Card>
    </>
  )
}

export default IncompleteFBM