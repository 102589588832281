import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { useNavigate } from 'react-router-dom';

const EmailVerificationCallback = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const navigate = useNavigate()

    useEffect(() => {
        verification(token)
    }, []);

    const verification = (value: any) => {
        let verifyData: any = {
            "token": value,
        };

        AuthService.verificationCallback(verifyData)
            .then((response: any) => {
                console.log(response)
                navigate('/verify')
            })
            .catch((e: Error) => {
                console.log(e);
            });
        console.log(value)
    };

    return (
        <>
        </>
    )
}

export default EmailVerificationCallback