import { useEffect, useState } from 'react'
import { Card, Col, Row, Tag } from 'antd';
import UserService from '../services/UserService';
import SettingsRouter from '../components/Settings/SettingsRouter';
import SettingsCard from '../components/V2/SettingsV2/SettingsCard';
import UserItemCard from '../components/V2/SettingsV2/UsersV2/UserItemCard';

const UserList = () => {
    const [customerList, setCustomerList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        setLoading(true);
        UserService.getAll("?roleName=ADMIN")
            .then((response: any) => {
                let data = response.data;
                UserService.getAll("?roleName=WAREHOUSE_MANAGER")
                    .then((response: any) => {
                        setCustomerList([...data, ...response.data]);
                        setLoading(false);
                    })
                    .catch((e: Error) => {
                        console.log(e);
                        setLoading(false);
                    });
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    return (
        <div>
            <Row>
                <Col span={24}>
                    <SettingsCard />
                </Col>
            </Row>
            <SettingsRouter></SettingsRouter>
            <Card loading={loading} className='bg-cloud border-radius-md'>
               <Row gutter={[24,24]}>
               {customerList.map((user: any) => (
                    <Col span={24}>
                        <UserItemCard user={user} />
                    </Col>
                ))}
               </Row>
            </Card>
        </div>
    )
}

export default UserList