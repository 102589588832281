import { Col, Row } from 'antd'
import StarterCard from '../components/V2/ProfileV2/StarterCard'

function Education() {
    return (
        <>
            <Row>
                <Col span={24}>
                    <StarterCard></StarterCard>
                </Col>
            </Row>
            <Row gutter={[24, 48]} className='mt-24 education-container'>
                <Col md={12} sm={24}>
                    <h3>1. OneShip Giriş</h3>
                    <iframe src="https://embed.voomly.com/embed/assets/embed.html?videoId=kRFbHJoKTwiPwiIIzpFOpceRIQjbFlSjntwg8s_OTQIfTRiG6&amp;videoRatio=1.548387&amp;type=v&amp;skinColor=%23008EFF" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" height="100%" width="100%"></iframe>
                </Col>
                <Col md={12} sm={24}>
                    <h3>2. Fbm - Depoya Ürün Çek</h3>
                    <iframe src="https://embed.voomly.com/embed/assets/embed.html?videoId=HFbZYtpNXd3MAM_ch4gws1vj0RmH4Dccyo4BCEAjXMayfAw1N&amp;videoRatio=1.548387&amp;type=v&amp;skinColor=%23008EFF" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  height="100%" width="100%"></iframe>
                </Col>
                <Col md={12} sm={24}>
                    <h3>3. Fba Gönderisi Oluştur</h3>
                    <iframe src="https://embed.voomly.com/embed/assets/embed.html?videoId=HEOIJvKMlU6Om4kIxUUnIWEzJpAbHySFUEwq6XAjCJEicy6SZ&amp;videoRatio=1.548387&amp;type=v&amp;skinColor=%23008EFF" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"height="100%" width="100%"></iframe>
                </Col>
            </Row>
        </>
    )
}

export default Education