import React from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Image, notification } from 'antd';
import { LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import loginLogo from '../assets/loginLogo.svg'
import loginImage from '../assets/loginImage2.png'
import { useNavigate } from "react-router-dom";
import AuthService from '../services/AuthService';
import { openNotification } from '../utils';

const LoginPage: React.FC = () => {
    const navigate = useNavigate();

    const createUser = (values: any) => {
        let userData: any = {
            "email": values.email,
            "firstName": values.firstName,
            "lastName": values.lastName,
            "password": values.password,

        };

        AuthService.register(userData)
            .then((response: any) => {
                console.log(response)
                navigate('/verify-email')
            })
            .catch((e) => {
                console.log(e);
                if (e.response.data.code === "oneamz-ff-email-already-exists") {
                    openNotification("error", "Başarısız", "Bu e-posta adresi zaten kullanımda.")
                }
            });
        console.log(values)
    };

    const onFinish = (values: any) => {
        console.log('Received values of form:', values);
        // Do your authentication here
        // If successful:
        createUser(values)
    };

    const passwordValidator = (_: any, value: any) => {
        if (!value) {
            return Promise.reject(new Error('Şifre boş bırakılamaz!'));
        }
        if (value.length < 8) {
            return Promise.reject(new Error('Şifre en az 8 karakter olmalıdır!'));
        }
        if (!value.match(/[A-Z]/)) {
            return Promise.reject(new Error('En az bir büyük harf içermelidir!'));
        }
        if (!value.match(/[a-z]/)) {
            return Promise.reject(new Error('En az bir küçük harf içermelidir!'));
        }
        if (!value.match(/[0-9]/)) {
            return Promise.reject(new Error('En az bir sayı içermelidir!'));
        }
        if (!value.match(/[^A-Za-z0-9]/)) {
            return Promise.reject(new Error('En az bir özel karakter içermelidir!'));
        }
        return Promise.resolve();
    };

    return (
        <>
            <Row justify={'space-between'}>
                <Col xs={0} sm={0} md={0} lg={16} xl={16} style={{ position: 'fixed', top: 0, left: 0, height: '100vh', overflow: 'hidden' }}>
                    <Image style={{ width: "54vw", height: "100vh" }} preview={false} src={loginImage} />
                </Col>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 11, offset: 13 }} xl={{ span: 9, offset: 14 }}>
                    <Row justify={'center'}>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 20 }} md={{ span: 14 }} lg={{ span: 24 }} xl={24} xxl={18}>
                            <Row justify={'center'} style={{ margin: "5em 0 0 0", width: "100%" }}>
                                <Col>
                                    <Image style={{ height: "15vh" }} preview={false} src={loginLogo} />
                                </Col>
                            </Row>
                            <Row justify={'center'}>
                                <Col span={20}>
                                    <Form
                                        style={{ marginBottom: "10em" }}
                                        name="normal_login"
                                        className="login-form"
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}
                                    >
                                        <h1 style={{ fontSize: "3em", margin: "1em 0 1em 0" }}>Hesap Oluştur</h1>
                                        <Row justify={'space-between'} >
                                            <Col span={11} >
                                                <p style={{ margin: "0 0 0.4em 0", fontSize: "1.3em" }}>İsim</p>
                                                <Form.Item
                                                    name="firstName"
                                                    rules={[
                                                        { required: true, message: 'Lütfen İsminizi girin!' },
                                                        {
                                                            pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇ\s]+$/,
                                                            message: 'İsim özel karakter içermemelidir!'
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder='İsim' size='large' style={{ padding: "0.7em" }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <p style={{ margin: "0 0 0.4em 0", fontSize: "1.3em" }}>Soyisim</p>
                                                <Form.Item
                                                    name="lastName"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Lütfen Soyisminizi girin!'
                                                        },
                                                        {
                                                            pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇ\s]+$/,
                                                            message: 'Soyisim özel karakter içermemelidir!'
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder='Soyisim' size='large' style={{ padding: "0.7em" }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <p style={{ margin: "0", padding: " 0 0 1em 0", fontSize: "1.3em" }}>Email</p>
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: 'Lütfen geçerli bir email girin!', type: 'email' }]}
                                        >
                                            <Input placeholder='Email' size='large' style={{ padding: "0.7em" }} />
                                        </Form.Item>
                                        <p style={{ margin: "0", padding: " 0 0 1em 0", fontSize: "1.3em" }}>Şifre</p>
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                { required: true, message: 'Lütfen şifrenizi girin!' },
                                                { validator: passwordValidator }
                                            ]}
                                        >
                                            <Input.Password
                                                size='large'
                                                style={{ padding: "0.7em" }}
                                                prefix={<LockOutlined className="site-form-item-icon" />}
                                                placeholder="Şifre"
                                                // Bu kısım şifre görünürlüğünü kontrol eder.
                                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                            />
                                        </Form.Item>
                                        <p style={{ margin: "0", padding: " 0 0 1em 0", fontSize: "1.3em" }}>Şifreyi Onayla</p>
                                        <Form.Item
                                            name="confirmPassword"
                                            rules={[
                                                { required: true, message: 'Lütfen şifrenizi tekrar girin!' },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject('Şifreler uyuşmuyor!');
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password
                                                size='large'
                                                style={{ padding: "0.7em" }}
                                                prefix={<LockOutlined className="site-form-item-icon" />}
                                                placeholder="Şifre Tekrar"
                                            />
                                        </Form.Item>
                                        <div style={{ padding: '20px' }}>
                                            <div style={{ marginBottom: '10px' }}>
                                                <Form.Item
                                                    name="bireyselHesapCheckbox" // Bu alanı form değerlerini yönetmek için ekledim.
                                                    valuePropName="checked" // Checkbox'ın kontrol edilip edilmediğini belirtmek için.
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'İşaretleme Gerekli!' // Gerekli validasyon mesajı.
                                                        }
                                                    ]}
                                                >
                                                    <Checkbox value="bireyselHesap">
                                                        <a style={{ color: "#DEA800", textDecoration: "underline" }}>Bireysel Hesap Sözleşmesi </a>
                                                        ve <a style={{ color: "#DEA800" }}>Ek</a>lerini kabul ediyorum.
                                                    </Checkbox>
                                                </Form.Item>
                                            </div>
                                            <div>
                                                <Form.Item
                                                    name="iletisimBilgileriCheckbox" // Bu alanı form değerlerini yönetmek için ekledim.
                                                    valuePropName="checked" // Checkbox'ın kontrol edilip edilmediğini belirtmek için.
                                                >
                                                    <Checkbox value="iletisimBilgileri">
                                                        İletişim bilgilerime kampanya, tanıtım ve reklam içerikli ticari elektronik ileti gönderilmesine,
                                                        bu amaçla kişisel verilerimin işlenmesine ve tedarikçilerinizle paylaşılmasına izin veriyorum.
                                                    </Checkbox>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <Form.Item>
                                            <Button style={{ width: "100%", background: "black", height: "4em" }} type="primary" htmlType="submit" className="login-form-button">
                                                <p style={{ fontSize: "1.2em", margin: "0", color: "#FFCB2B" }}><b>Hesap Oluştur</b></p>
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default LoginPage;