
import React from 'react'
import { Alert, Card, Col, Row } from 'antd';
import FBMCard from './FBMCard';

interface FBMCreateStepProps {
    changeShipmentFee: (id: any, fee: any) => void;
    changeCustomer: (id: any, data: any) => void;
    changeDeliveryOption: (id: any, data: any) => void;
    productList: []
}

const FBMCreateStep2: React.FC<FBMCreateStepProps> = ({ changeCustomer, changeShipmentFee, productList, changeDeliveryOption }) => {

    return <>
        <Alert
            className='border-none border-radius-md p-24 mt-24'
            description={<p className='text fs-14 m-0'>
                Kargo ödeme tutarı Amazon'dan alınan ölçü bilgilerine göre hesaplanmaktadır.
                Paket depoya ulaştığı zaman ölçüler arasında bazen farklar oluşabilmektedir.
                Oluşacak olan farklardan dolayı kullanıcıya ekstra kargo ücreti ile ilgili
                ödeme yapması için bildirim yapılacaktır.
            </p>}
            type="info"
            showIcon
        />
        <h3 className='h-3 font-color-slate-blue mt-24 mb-24'>
            Müşteri Bilgileri
        </h3>
        <Card className='bg-cloud border-radius-md mt-24' >
            <Row justify={"center"}>
                <Col span={24}>
                    {productList.map((fbmItem: any) => (
                        <FBMCard changeCustomer={changeCustomer} changeShipmentFee={changeShipmentFee} changeDeliveryOption={changeDeliveryOption} fbmItem={fbmItem}></FBMCard>
                    ))}
                </Col>
            </Row>
        </Card>
    </>
}

export default FBMCreateStep2