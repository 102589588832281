import { Col, Row, Card } from 'antd'
import React from 'react'
import { IResponseOrder } from '../../../types/Order';
import AmazonProductInfo from '../../../components/Common/AmazonProductInfo';
import AmazonProductImage from '../../../components/Common/AmazonProductImage';

interface FBAShipmentItemCardManagerProps {
    item: any;
    order: IResponseOrder,
    orderItem: any,
    setQty: (id: any, qty: any) => void;
}
const FBAResultItemCard: React.FC<FBAShipmentItemCardManagerProps> = ({ item, order, orderItem, setQty }) => {
    return (
        <>
            <Card>
                <Row align={"top"} justify={"start"}>
                    <Col span={16}>
                        <Row gutter={[24, 24]} align={"middle"} justify={"start"}>
                            <Col>
                                <AmazonProductImage asin={item.product.asin}></AmazonProductImage>
                            </Col>
                            <Col span={18}>
                                <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={5}>
                        <Row>
                            <Col span={24}>
                                <p className='pretitle font-color-slate-blue upper-case'>EK HİZMETLER</p>
                            </Col>
                            <Col span={24}>
                                {item.services.map((service: any) => (
                                    <>
                                        <Row key={service.id}>
                                            <Col span={24}>
                                                <p className='text-small font-color-slate-blue-05 m-0'>{service.generalService.name}</p>
                                            </Col>
                                        </Row>
                                    </>
                                ))}
                            </Col>
                        </Row>
                    </Col>
                    <Col span={3}>
                        <Row>
                            <Col span={24}>
                                <Row justify={'center'}>
                                    <Col>
                                        <p className='pretitle font-color-slate-blue upper-case'>ADET</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row justify={'center'}>
                                    <Col>
                                        <p className='btn-text-sm font-color-slate-blue-05 m-0'>{item.qty}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>

        </>
    )
}

export default FBAResultItemCard