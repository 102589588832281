import { Col, Collapse, Row, Input, Divider } from 'antd'
import React from 'react'
import { IResponseOrder } from '../../../types/Order';
import ClaimOrderService from '../../../services/ClaimOrderService';
import AmazonProductImageAvatar from '../../../components/Common/AmazonProductImageAvatar';
import OrderAlert from '../../../components/Order/OrderAlert';
import AmazonProductInfo from '../../../components/Common/AmazonProductInfo';
import TextArea from 'antd/es/input/TextArea';
import { formatMoney } from '../../../utils';
import OneamzShippingLabel from '../../../components/FBADetail/OneamzShippingLabel';
import ServiceList from '../../../components/FBACreate/ServiceList';
import OrderDetailServiceList from './OrderDetailServiceList';

interface FBAShipmentItemCardManagerProps {
    item: any;
    order: IResponseOrder,
    orderItem: any,
    setQty: (id: any, qty: any) => void;
}
const FBAShipmentItemCardManager: React.FC<FBAShipmentItemCardManagerProps> = ({ item, order, orderItem, setQty }) => {
    const mandatoryServices = item.services.filter((service: any) => !service.generalService.isOptional);
    const optionalServices = item.services.filter((service: any) => service.generalService.isOptional);
    const readOnly = order.type === "ORDER" ? true : false;

    const updateDamageQty = (orderItemId: any, value: any) => {
        console.log(value);
        if (value === 0 || value > 0) {
            ClaimOrderService.updateDamage(orderItemId, value)
                .then((response: any) => {
                }).catch((e: Error) => {
                });
        }
        return false;
    };

    const updateNote = (orderItemId: any, e: any) => {
        ClaimOrderService.updateNote(orderItemId, e.target.value)
            .then((response: any) => {
            }).catch((e: Error) => {
            });
    };

    return (
        <>
            <Collapse
                className='antd-order-detail-collapse border-radius-md mb-24'
                expandIconPosition={'end'}
                items={[{
                    key: '1',
                    label:
                        <>
                            <Row gutter={[24, 24]} align={"middle"} justify={"space-between"}>
                                <Col span={3}>
                                    <AmazonProductImageAvatar orderItem={item}></AmazonProductImageAvatar>
                                </Col>
                                <Col span={10}>
                                    <Row>
                                        <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <OneamzShippingLabel order={order} orderItem={orderItem}></OneamzShippingLabel>
                                </Col>
                                <Col className='text-center' span={3}>
                                    <OrderAlert order={order} />
                                </Col>
                            </Row>
                        </>,
                    children:
                        <>
                            <Row justify={'space-between'} align={'top'}>
                                <Col xl={11}>
                                    <h4 className='text mt-0 mb-8'><b>FBA ZORUNLU HİZMETLER</b></h4>
                                    <Row>
                                        <Col span={9}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Hizmet</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Birim Fiyatı</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Adet</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Toplam</b></p>
                                        </Col>
                                    </Row>
                                    {mandatoryServices.map((service: any) => (
                                        <>
                                            <Row key={service.id}>
                                                <Col span={9}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.generalService.name}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b> {formatMoney(service.generalService.fee)}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.qty}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue mb-8'><b> {formatMoney(service.amount)}</b></p>
                                                </Col>
                                            </Row>
                                            <Divider className='m-0 mt-8' />
                                        </>
                                    ))}
                                </Col>

                                <Col xl={11}>
                                    <Row justify={"space-between"}>
                                        <Col>
                                            <h4 className='text mt-0 mb-8'><b>EK HİZMETLER</b></h4>
                                        </Col>
                                        <Col>
                                            <OrderDetailServiceList product={orderItem}></OrderDetailServiceList>
                                        </Col>
                                    </Row>                                    
                                    <Row>
                                        <Col span={9}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Hizmet</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Birim Fiyatı</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Adet</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Toplam</b></p>
                                        </Col>
                                    </Row>
                                    {optionalServices.map((service: any) => (
                                        <>
                                            <Row key={service.id}>
                                                <Col span={9}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.generalService.name}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b> {formatMoney(service.generalService.fee)}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.qty}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue mb-8'><b> {formatMoney(service.amount)}</b></p>
                                                </Col>
                                            </Row>
                                            <Divider className='m-0 mt-8' />
                                        </>
                                    ))}
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]} className='mt-24'>
                                <Col span={12}>
                                    <p className='text-sm fs-12 m-0'><b>HASARLI</b></p>
                                    <Input
                                        className='amz-input w-full'
                                        placeholder="Hasarlı"
                                        readOnly={readOnly}
                                        defaultValue={orderItem.damage} onChange={(e) => updateDamageQty(orderItem.id, e.target.value)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <p className='text-sm fs-12 m-0'><b>ONAYLI</b></p>
                                    <Input
                                        className='amz-input w-full'
                                        placeholder="Onaylı"
                                        readOnly={readOnly}
                                        defaultValue={0}
                                        onChange={(e) => setQty(orderItem.id, e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]} className='mt-24'>
                                <Col span={24}>
                                    <p className='text-sm fs-12 m-0'><b>NOT</b></p>
                                    <TextArea showCount
                                        readOnly={readOnly}
                                        placeholder="Ürünle İlgili bilgilendirme İçin Not Yazın..."
                                        defaultValue={orderItem.note}
                                        onChange={(value) => updateNote(orderItem.id, value)}
                                        style={{ height: '150px' }}
                                    > </TextArea>
                                </Col>
                            </Row>
                        </>
                }]}
            />
        </>
    )
}

export default FBAShipmentItemCardManager