import http from "../http-common";

const uploadFbaLabel = (id: any, file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    return http.post(`/claim-orders/${id}/fba/upload`, formData, config);
};

const uploadFbmLabel = (id: any, file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    return http.post(`/claim-orders/${id}/fbm/upload`, formData, config);
};

const uploadOrderFbaLabel = (id: any, file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    return http.post(`/orders/${id}/fba/upload`, formData, config);
};

const uploadCargoLabel = (id: any, file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    return http.post(`claim-orders/${id}/shipping-label/upload`, formData, config);
};

const uploadOrderCargoLabel = (id: any, file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    return http.post(`/orders/${id}/shipping-label/upload`, formData, config);
};

const uploadFnskuLabel = (id: any, file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    return http.post(`/claim-order-items/${id}/fnsku/upload`, formData, config);
};

const uploadOrderItemFNSKULabel = (id: any, file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    return http.post(`/order-items/${id}/fnsku/upload`, formData, config);
};

const FileUploadService = {
    uploadFbaLabel,
    uploadFbmLabel,
    uploadFnskuLabel,
    uploadCargoLabel,
    uploadOrderItemFNSKULabel,
    uploadOrderFbaLabel,
    uploadOrderCargoLabel
};

export default FileUploadService;