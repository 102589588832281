import { Card, Col, Divider, Row } from 'antd'
import React from 'react'
import StorageItemCard from './StorageItemCard'
import { ReactComponent as HouseCheckIcon } from '../../../assets/V2/OrderDetailV2/house-check.svg';
import { IResponseOrder } from '../../../types/Order';
import { CopyClipboard } from '../../../components/FBACreate/CopyClipboard';

interface OrderDetailCardProps {
  order: IResponseOrder
}

const StorageDetail: React.FC<OrderDetailCardProps> = ({ order }) => {
  return (
    <Row justify={'space-between'}>
      <Col xl={8} xs={24}>
        <Row>
          <Col span={24}>
            <Card className='bg-cloud border-radius-md mt-24'>
              <Row>
                <Col span={4}>
                  <HouseCheckIcon className='wh-48' />
                </Col>
                <Col span={20}>
                  <p className='text font-color-onyx'><b>OneAmz Depo Adresi</b></p>
                  <p className='text font-color-onyx m-0'>
                    <CopyClipboard text={'356 GETTY AVE'} />
                  </p>
                  <p className='text font-color-onyx m-0'>
                  <CopyClipboard text={`BLD-5A #${order?.orderCode}`} />
                  </p>
                  <p className='text font-color-onyx m-0'>
                    <CopyClipboard text={'CLIFTON, NJ 07011-2118'} />
                  </p>
                  <p className='text font-color-onyx m-0'>
                    <CopyClipboard text={'United States'} />
                  </p>
                  <p className='text font-color-onyx mb-0'><b>Telefon:</b></p>
                  <p className='text font-color-onyx mt-0'>
                    <CopyClipboard text={'917 306 6033'} />
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className='bg-cloud border-radius-md mt-24'>
              <Row>
                <Col span={24}>
                  <h3 className='text font-color-onyx'><b>Not</b></h3>
                  <p className='text font-color-onyx m-0'>{order?.note}</p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>

      <Col>
        <Divider type="vertical" className='vertical-divider' />
      </Col>
      <Col xl={15} xs={24}>
        {order?.items.map((item: any) => (
          <StorageItemCard orderItem={item}></StorageItemCard>
        ))}
      </Col>
    </Row>
  )
}

export default StorageDetail