import { Button, Card, Col, Form, Input, Modal, Popconfirm, Row, Select, Switch, message } from 'antd'
import React, { useEffect, useState } from 'react'
import closeIcon from '../../../../assets/XCircle.svg';
import { DeleteOutlined } from '@ant-design/icons'
import ServicesService from '../../../../services/ServicesService';
import { formatDate, formatMoney, openNotification } from '../../../../utils';

interface UserItemCardProps {
    user: any
}

const { Item } = Form;

const UserItemCard: React.FC<UserItemCardProps> = ({ user }) => {

    return (
        <>
            <Card className='border-radius-md w-full p-24 antd-card-body-p-0 p-8'>
                <Row align={'middle'} justify={'space-between'}>
                    <Col xl={4} md={8} xs={24}>
                        <p className='text-sm fs-16 m-0'><b>ID</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {user.id}
                        </p>
                    </Col>
                    <Col xl={4} md={8} sm={12} xs={24}>
                        <p className='text-sm fs-16 m-0'><b>Ad Soyad</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {user.firstName} {user.lastName}
                        </p>
                    </Col>
                    <Col xl={3} md={8} sm={12} xs={24}>
                        <p className='text-sm fs-16 m-0'><b>Rol</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {user.roles[0].name}
                        </p>
                    </Col>
                    <Col xl={3} md={8} sm={12} xs={24}>
                        <p className='text-sm fs-16 m-0'><b>E-Posta</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {user.email}
                        </p>
                    </Col>
                    <Col xl={3} md={8} sm={12} xs={24}>
                        <p className='text-sm fs-16 m-0'><b>Cep Telefonu</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {user.phoneNumber}
                        </p>
                    </Col>
                    <Col xl={3} md={8} sm={12} xs={24}>
                        <p className='text-sm fs-16 m-0'><b>Kayıt Tarihi</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {formatDate(user.createdAt)}
                        </p>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default UserItemCard