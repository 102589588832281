import { Card, Col, Empty, Input, Progress, Row, Select } from 'antd'
import InventoryItemCard from './InventoryItemCard'
import { useEffect, useState } from 'react';
import InventoryService from '../../../services/InvertoryService';
import { NavLink } from 'react-router-dom';
import GetItemtoWarehouse from './GetItemtoWarehouse';
import InventoryFilters from './InventoryFilters';
import UserService from '../../../services/UserService';

const InventoryItemList = () => {
    const [inventoryList, setInventoryList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [searchText, setSearchText] = useState("");
    const [storageVolume, setStorageVolume] = useState<any>(0)
    const [totalStorage, setTotalStorage] = useState(0)

    useEffect(() => {
        getInventoryList();
        userGetAll();
        getTotalStorageVolume();
    }, [])


    useEffect(() => {
        getFilterInventoryList();
    }, [selectedCustomer, searchText]);

    const userGetAll = () => {
        UserService.getAll("?roleName=CUSTOMER")
            .then((response: any) => {
                let data = response.data;
                const newArray = data.map((item: any) => {
                    return { value: item.id, label: `#${item.id} ${item.firstName} ${item.lastName}` };
                });
                setCustomerList(newArray);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getInventoryList = (filter = "") => {
        setLoading(true);
        InventoryService.getAll(filter)
            .then((response: any) => {
                let data = response.data;
                data.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                setInventoryList(data);
                setLoading(false);
            }).catch((e: Error) => {
                console.log(e)
                setLoading(false);
            });
    }

    const handleChange = (event: any) => {
        setSearchText(event.target.value);
    };

    const getFilterInventoryList = () => {
        let filterText = '?';

        if (searchText != null && searchText != "") {
            filterText += `search=${searchText}`;
        }

        if (selectedCustomer != null && selectedCustomer != "") {
            filterText += `&userId=${selectedCustomer}`;
        }

        getInventoryList(filterText)

    }

    const getTotalStorageVolume = () => {
        InventoryService.getTotalStorageVolume()
            .then((response: any) => {
                let data = response.data;
                let total = Math.ceil(data);
                let percent = data / total * 100;
                setStorageVolume(percent.toFixed(0));
                setTotalStorage(total);
            }).catch((e: Error) => {
            });
    };

    const currentRole = localStorage.getItem('userRole');

    return (
        <>
            <Row gutter={24}>
                {(currentRole == "CUSTOMER") && <Col className='mt-24' xl={7} xs={24}>
                    <NavLink to={'/storage/new'}>
                        <GetItemtoWarehouse />
                    </NavLink>
                </Col>}
                <Col xl={6}>
                    <Card className='bg-cloud border-radius-md mt-24'>
                        <p className='mt-0 text fs-18 font-color-slate-blu mb-0'><b>DEPOLAMA ALANI</b></p>
                        <Progress className='progress-slate-blue-2 mt-8' percent={100} size={['100%', 14]} success={{ percent: storageVolume }} showInfo={false} />
                        {/* <Progress className='progress-slate-blue' percent={storageVolume} showInfo={false} /> */}
                        <p className='text font-color-slate-blue fs-14 mb-0 mt-8'><b>{totalStorage}</b> Storage Box' ın, <b>%{(storageVolume > 0) && storageVolume}</b> Kullanılıyor</p>
                    </Card>
                </Col>
                <Col className='mt-24' xl={(currentRole == "CUSTOMER") ? 11 : 18} xs={24}>
                    <Card className='bg-cloud border-radius-md h-full'>
                        <Row align={'middle'} gutter={24}>
                            {(currentRole != "CUSTOMER") ? <Col className='mt-16' xxl={12} md={12} xs={24}><Select filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            } className='amz-input w-full' options={[{ value: '', label: 'Seçiniz' }, ...customerList]} showSearch onChange={(customer) => setSelectedCustomer(customer)} placeholder={"Müşteri"} ></Select>   </Col>
                                : <></>}
                            <Col className='mt-16' span={(currentRole == "CUSTOMER") ? 24 : 12}>
                                <Input
                                    className='amz-input'
                                    placeholder="Asin yada Ürün adı ile arama yapabilirsiniz"
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-24'>
                <Col span={24}>
                    <Card loading={loading} className='bg-cloud border-radius-md'>
                        <Row>
                            <Col span={24}>
                                <h3 className='h-3 m-0'>Envanter Listesi</h3>
                            </Col>
                            <Col className='antd-card-body-p-0' span={24}>
                                {inventoryList.map(item => (
                                    <InventoryItemCard item={item}></InventoryItemCard>
                                ))}
                                {(inventoryList.length == 0) ? <Col span={24}><Empty description={<h3>Envanter Liste verisi yok</h3>} image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> : <></>}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default InventoryItemList