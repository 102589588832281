import React, { useEffect, useState } from 'react';
import { Button, Row, Image, Modal } from 'antd';
import OneamzAppService from '../../services/OneamzAppService';
import { openNotification } from '../../utils';
import { ReactComponent as UpsIcon } from '../../assets/ups.svg';
import { ReactComponent as FedexIcon } from '../../assets/fedex.svg';
import { NavLink } from 'react-router-dom';

interface OneamzShippingProps {
    order: any,
    orderItem: any,
    receiver?: any
}

const OneamzShippingLabel: React.FC<OneamzShippingProps> = ({ order, orderItem, receiver }) => {

    const [shippingLabel, setShippingLabel] = useState<any>("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [trackingNumber, setTrackingNumber] = useState("");
    const [carrier, setCarrier] = useState("");

    useEffect(() => {
        console.log('OneamzShippingLabel', order);
        console.log('OneamzShippingLabel', orderItem);
        getTrackingCode();
    }, [])

    const getTrackingCode = () => {
        let orderId = order.id;
        let shippingLabelId = `${orderId}${orderItem.id}`;
        OneamzAppService.getOneamzShippingLabel(shippingLabelId)
            .then((response: any) => {
                if (response.data.code == 500 || response.data.code == 404) {
                } else {
                    setTrackingNumber(response.data.trackingNumber)
                    setCarrier(response.data.carrier)
                }
            }).catch((e: Error) => {
            });
    }

    const getOneamzShippingLabel = (openModal = true) => {
        if (openModal) {
            setButtonLoading(true);
        }
        let orderId = order.id;
        let shippingLabelId = `${orderId}${orderItem.id}`;
        OneamzAppService.getOneamzShippingLabel(shippingLabelId)
            .then((response: any) => {
                if (response.data.code == 500 || response.data.code == 404) {
                    createOneamzShippingLabel();
                } else {
                    setShippingLabel('data:application/pdf;base64, ' + response.data.labelData);
                    setTrackingNumber(response.data.trackingNumber)
                    setCarrier(response.data.carrier)
                    if (openModal) {
                        setIsModalOpen(true);
                        setButtonLoading(false);
                    }

                }
            }).catch((e: Error) => {
                setButtonLoading(false);
            });
    }

    console.log('order', order?.orderType)

    const createOneamzShippingLabel = () => {
        let orderId = (order?.claimOrderId === undefined ? order?.id : order?.claimOrderId);
        let shippingLabelId = `${orderId}${orderItem.id}`;

        let shipTo = {
            "name": order?.warehouse?.name,
            "country": order?.warehouse?.countryCode,
            "addressLine1": order?.warehouse?.addressLine1,
            "addressLine2": order?.warehouse?.addressLine2,
            "city": order?.warehouse?.city,
            "state": order?.warehouse?.state,
            "postalCode": order?.warehouse?.postalCode,
            "phone": order?.warehouse?.phone,
        }

        if (order?.orderType === 'FBM_SHIPMENT') {
            shipTo = {
                "name": receiver?.fullName,
                "country": receiver?.country,
                "addressLine1": receiver?.addressLine1,
                "addressLine2": receiver?.addressLine2,
                "city": receiver?.city,
                "state": receiver?.state,
                "postalCode": receiver?.postCode,
                "phone": receiver?.phoneNumber,
            }
        }

        let deliveryOption = order?.orderType === 'FBM_SHIPMENT' ? order?.deliveryOption : 'STANDARD';

        let postObj = {
            "orderId": shippingLabelId,
            "deliveryOption": deliveryOption,
            "sellerName": `${order?.user?.firstName} ${order?.user?.lastName}`,
            "shipTo": shipTo,
            "asin": orderItem?.product?.asin,
            "itemName": orderItem?.product?.name,
            "quantity": orderItem?.qty,
            "itemPrice": orderItem?.product?.amount,
            "weight": orderItem?.product?.weight,
            "dimensions": {
                "length": orderItem?.product?.length,
                "width": orderItem?.product?.width,
                "height": orderItem?.product?.height,
            }
        };

        OneamzAppService.createOneamzShippingLabel(postObj)
            .then((response: any) => {
                if (response.data.code == 500) {
                    setButtonLoading(false);
                    openNotification('warning', 'Uyarı', response.data.message)
                } else {
                    setButtonLoading(false);
                    setTrackingNumber(response.data.trackingNumber);
                    getOneamzShippingLabel();
                }
            }).catch((e: Error) => {
            });
    }

    return (
        <>
            <Row gutter={[24, 24]} align={"middle"} >
                <Button loading={buttonLoading} size='small' className='btn bg-slate-blue font-color-cloud btn-hover-white btn-text border-radius-lg mr-8' onClick={() => getOneamzShippingLabel(true)} >Oneamz Shipping Label</Button>
                {trackingNumber !== '' && carrier === 'UPS' && <NavLink to={`https://www.ups.com/track?loc=tr_TR&trackNums=${trackingNumber}&track.x=Submit&requester=ST/trackdetails`} target='_blank'>
                    <UpsIcon style={{ height: '30px', width: '30px', marginLeft: '10px' }} />
                </NavLink>}
                {trackingNumber !== '' && carrier === 'FEDEX' && <NavLink to={`https://www.fedex.com/wtrk/track/?tracknumbers=${trackingNumber}`} target='_blank'>
                    <FedexIcon style={{ width: '50px', marginLeft: '10px' }} /> {trackingNumber}
                </NavLink>}
                {trackingNumber !== '' && carrier === 'E_POST' && <NavLink to={`https://epgtrack.com/${trackingNumber}`} target='_blank'>
                    EPG
                </NavLink>}
                <Modal title="Oneamz Shipping Label" open={isModalOpen} onOk={() => setIsModalOpen(false)}
                    onCancel={() => setIsModalOpen(false)} width={700} >
                                            <iframe height={680} width={650} src={shippingLabel}></iframe>
                </Modal>
            </Row>
        </>
    );
}

export default OneamzShippingLabel;


