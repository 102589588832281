import http from "../http-common";
import { IFloorRequest, IFloorResponse } from "../types/Floor";

const getAll = () => {
  return http.get<Array<IFloorResponse>>(`/floors`);
};

const get = (floorId: number) => {
  return http.get<IFloorResponse>(`/floors/${floorId}`);
};

const update = (floorId: any, data: IFloorRequest) => {
  return http.put<any>(`/floors/${floorId}`, data);
};

const create = ( data: IFloorRequest) => {
  return http.post<any>(`/floors`, data);
};

const remove = (floorId: any) => {
  return http.delete<any>(`/floors/${floorId}`);
};

const FloorService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default FloorService;