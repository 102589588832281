import React from 'react'
import { ReactComponent as WarningOutlined } from '../../assets/error-icon.svg'
import { Tooltip } from 'antd';

interface AlertProps {
  order: any;
}

const OrderAlert: React.FC<AlertProps> = ({ order }) => {
  const alerts: any[] = [];

  if (!order.fbaLabel) {
    alerts.push('FBA etiketi eksik');
  }

  if (order.shippingProvider === 'AMAZON' && !order.shippingLabel) {
    alerts.push('Amazon Kargo Etiketi Eksik');
  }

  if (!order.warehouse) {
    alerts.push('Amazon depo bilgisi eksik');
  }

  order?.items?.forEach((item: any, index: number) => {
 if (!item.fnskuLabel) {
      let services = item.services;
      for (let i = 0; i < services.length; i++) {
        const service = services[i];
        if (service.generalService.id === 1 && service.qty > 0) {

          let text = `${item.product.asin}: FNSKU etiketi eksik.`;
          const itemExists = alerts.includes(text);
          if (!itemExists) {
            alerts.push(`${item.product.asin}: FNSKU etiketi eksik.`);
          }
        }
      }
    }
    if (!item.trackingCode) {
      alerts.push(`${item.product.asin}: Takip kodu eksik.`);
    }
  });

  order?.orderItems?.forEach((item: any, index: number) => {
    if (!item.fnskuLabel) {
      alerts.push(`${item.product.asin}: FNSKU etiketi eksik`);
    }
    if (!item.trackingCode) {
      alerts.push(`${item.product.asin}: Takip kodu eksik`);
    }
  });

  return (
    <div>
      {alerts.length > 0 ? (
        <Tooltip color="black" title={alerts.map((alert, index) => (
          <p key={index} style={{ color: 'pure-white', borderBottom: '1px solid rgba(255,255,255,0.3)', padding: '0px', paddingBottom: '10px' }}>{alert}</p>
        ))} >
          <WarningOutlined className='wh-24 mt-8' />
        </Tooltip>
      ) : (
        <></>
      )}
    </div>
  );
}

export default OrderAlert