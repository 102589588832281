import http from "../http-common";
import { IWarehousesRequest, IWarehousesResponse } from "../types/Warehouse";

const getAll = () => {
  return http.get<Array<IWarehousesResponse>>(`/warehouses`);
};

const get = (warehouseId: number) => {
  return http.get<IWarehousesResponse>(`/warehouses/${warehouseId}`);
};

const update = (warehouseId: any, data: any) => {
  return http.put<any>(`/warehouses/${warehouseId}`, data);
};

const create = (data: IWarehousesRequest) => {
  return http.post<any>(`/warehouses`, data);
};

const remove = (warehouseId: any) => {
  return http.delete<any>(`/warehouses/${warehouseId}`);
};

const WarehouseService = {
  getAll,
  get,
  update,
  create,
  remove
};

export default WarehouseService;