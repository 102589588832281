import { LeftOutlined } from '@ant-design/icons'
import { Card, Row, Image, Avatar, Col, Input, Button } from 'antd'
import { NavLink } from 'react-router-dom'
import TicketIcon from '../assets/ticket.svg'
import AvatarImage from '../assets/profile.png'

const { TextArea } = Input;

const NewSupport = () => {
    return (
        <>
            <NavLink to={'/support'}>
                <LeftOutlined style={{
                    color: "black",
                    background: "rgba(123, 123, 123, 0.15)",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "4em",
                    width: "4em",
                    borderRadius: "50%"
                }} />
            </NavLink>
            <Card style={{ margin: "2em 0 0 0 " }}>
                <Row align={'middle'}>
                    <Image style={{ height: "3em" }} src={TicketIcon} />
                    <p style={{ marginLeft: "2em", marginRight: "0.3em", fontSize: "1.3em" }}><b>Güngör Durdu </b>#12345 ID’li bileti oluşturdu. </p>
                    <p style={{ color: "rgba(193, 193, 193, 1)", fontSize: "1.2em" }}>-- Ö.S 3.00</p>
                </Row>
                <Row style={{ height: "400px" }} justify={'center'}>
                    <Col span={22}>
                        <Row align={'middle'}>
                            <Col>
                                <Avatar style={{ height: "5em", width: "5em" }} src={AvatarImage} ></Avatar>
                            </Col>
                            <Col style={{ marginLeft: "2em" }}>
                                <Row align={'middle'}>
                                    <p style={{ margin: "0 0.3em 0 0", fontSize: "1.3em" }}><b>Güngör Durdu </b></p>
                                    <p style={{ color: "rgba(193, 193, 193, 1)", fontSize: "1.2em" }}>-- Ö.S 3.00</p>
                                </Row>
                                <Row>
                                    <p style={{ margin: "0 0.3em 0 0", fontSize: "1.3em" }} >Merhaba, Ürünlerimi deponuza gönderdim fakat 3 ürün eksik gözüküyor.</p>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col span={14}>
                        <TextArea placeholder='Metin girin' rows={6}>
                        </TextArea>
                        <Row justify={'end'} style={{ marginTop: "1em" }}>
                            <NavLink to={'/support'}>
                                <Button size='large' style={{ margin: "0 1em 0 0" }} ghost><p style={{ color: "black", margin: "0" }}>Sohbeti Sonlandır</p></Button>
                            </NavLink>
                            <Button size='large' type='primary' style={{ background: "black" }}>Gönder</Button>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default NewSupport