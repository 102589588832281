import http from "../http-common";
import { IShelfRequest, IShelfResponse } from "../types/Shelf";

const getAll = () => {
    return http.get<Array<IShelfResponse>>(`/shelves`);
};

const get = (shelfId: number) => {
    return http.get<IShelfResponse>(`/shelves/${shelfId}`);
};

const update = (shelfId: any, data: IShelfRequest) => {
    return http.put<any>(`/shelves/${shelfId}`, data);
};

const create = (data: IShelfRequest) => {
    return http.post<any>(`/shelves`, data);
};

const remove = (shelfId: any) => {
    return http.delete<any>(`/shelves/${shelfId}`);
};

const ShelfService = {
    getAll,
    get,
    update,
    create,
    remove
};

export default ShelfService;