import { Button, Card, Col, Drawer, Form, Input, Modal, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import WarehouseMeService from '../../services/WarehouseMeService';
import closeIcon from '../../assets/XCircle.svg';
import { openNotification } from '../../utils';

interface WarehouseDetailProps {
    onHandleWarehouseList: () => void;
}

const { Item } = Form;

const CreateWarehouse: React.FC<WarehouseDetailProps> = ({ onHandleWarehouseList }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const countries = [
        { "label": "Canada", "value": "CA" },
        { "label": "Mexico", "value": "MX" },
        { "label": "US", "value": "US" },
        { "label": "Czech Republic", "value": "CZ" },
        { "label": "France", "value": "FR" },
        { "label": "Germany", "value": "DE" },
        { "label": "Italy", "value": "IT" },
        { "label": "Poland", "value": "PL" },
        { "label": "Spain", "value": "ES" },
        { "label": "UK", "value": "GB" },
        { "label": "India", "value": "IN" },
        { "label": "Japan", "value": "JP" },
        { "label": "Singapore", "value": "SG" },
        { "label": "UAE", "value": "AE" },
        { "label": "Australia", "value": "AU" }
    ]

    const warehouseType = [
        { value: 'W', label: 'Warehouse' },
        { value: 'FC', label: 'Fulfillment Center' },
    ]

    useEffect(() => {
        form.resetFields();
    }, [isModalVisible])

    const update = (values: any) => {
        let postObj = {
            "countryCode": values.countryCode,
            "type": values.type,
            "code": values.code,
            "address": values.address,
            name: values?.name,
            addressLine1: values?.addressLine1,
            addressLine2: values?.addressLine2,
            city: values?.city,
            state: values?.state,
            postalCode: values?.postalCode,
            phone: values?.phone,
        }

        WarehouseMeService.create(postObj)
            .then((response: any) => {
                onHandleWarehouseList();
                openNotification('success', 'Başarılı', 'Depo Başarıyla Kaydedildi.')
                setIsModalVisible(false)
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    return (
        <>
            <Row justify={'end'}>
                <Col span={24}>
                    <Button className='btn btn-text btn-hover-white bg-slate-blue font-color-cloud w-full border-radius-8' onClick={() => setIsModalVisible(true)}>Depo Ekle</Button>
                </Col>
            </Row>
            <Modal
                title={<>Depo EKle</>}
                visible={isModalVisible}
                className='antd-modal-secondary '
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                closeIcon={<img src={closeIcon} height={24} alt="close" />}
            >

                <Form form={form} onFinish={update} layout="vertical">
                    <Item
                        name="name"
                        label="Depo Adı"
                        rules={[
                            {
                                required: true,
                                message: 'Depo adı zorunlu',
                            }]}
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Item
                        name="countryCode"
                        label="Ülke"
                        rules={[
                            {
                                required: true,
                                message: 'Ülke Seçiniz!',
                            }]}
                    >
                        <Select
                            className='amz-select-input border-slate-blue border-radius-8 amz-input'
                            style={{ width: "100%" }}
                            placeholder='Ülkeyi Seçiniz'
                            options={[
                                { "label": "Canada", "value": "CA" },
                                { "label": "Mexico", "value": "MX" },
                                { "label": "US", "value": "US" },
                                { "label": "Czech Republic", "value": "CZ" },
                                { "label": "France", "value": "FR" },
                                { "label": "Germany", "value": "DE" },
                                { "label": "Italy", "value": "IT" },
                                { "label": "Poland", "value": "PL" },
                                { "label": "Spain", "value": "ES" },
                                { "label": "UK", "value": "GB" },
                                { "label": "India", "value": "IN" },
                                { "label": "Japan", "value": "JP" },
                                { "label": "Singapore", "value": "SG" },
                                { "label": "UAE", "value": "AE" },
                                { "label": "Australia", "value": "AU" }
                            ]}
                        />
                    </Item>
                    <Item
                        name="code"
                        label="Depo Kodu"
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Item
                        name="addressLine1"
                        label="Adres Satırı 1"
                        rules={[
                            {
                                required: true,
                                message: 'Ülke Seçiniz!',
                            }]}
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Item
                        name="addressLine2"
                        label="Adres Satırı 2"
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Item
                        name="phone"
                        label="Telefon"
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Item
                        name="state"
                        label="Eyalet"
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Item
                        name="city"
                        label="Şehir"
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Item
                        name="postalCode"
                        label="Posta Kodu"
                    >
                        <Input className='amz-input border-radius-8' />
                    </Item>
                    <Item
                        name="type"
                        label="Depo Tipi"
                        rules={[
                            {
                                required: true,
                                message: 'Depo Tipi Seçiniz!',
                            }]}
                    >
                        <Select
                            className='amz-select-input border-slate-blue border-radius-8 amz-input'
                            style={{ width: "100%" }}
                            placeholder='Depo Türünü Seçiniz'
                            options={[
                                { value: 'W', label: 'Warehouse' },
                                { value: 'FC', label: 'Fulfillment Center' },
                            ]}
                        />
                    </Item>
                    <Item>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Button className='btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8' onClick={() => setIsModalVisible(false)} style={{ marginRight: '10px' }}>
                                    İptal
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button className='btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8' htmlType="submit">
                                    Kaydet
                                </Button>
                            </Col>
                        </Row>
                    </Item>
                </Form>
            </Modal>
        </>
    )
}

export default CreateWarehouse