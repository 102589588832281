import { Card, Input, Row, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { IShelfResponse } from '../../types/Shelf';
import BinService from '../../services/BinService';
import { IBinRequest, IBinResponse } from '../../types/Bin';
import ShelfService from '../../services/ShelfService';

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface Pane {
    label: string;
    children: React.ReactNode;
    key: string;
    isNew?: boolean; // Yeni oluşturulan sekmeler için işaretçi
}

interface BinProps {
    shelfData?: IShelfResponse;
}

const defaultPanes: Pane[] = [];

const Bin: React.FC<BinProps> = ({ shelfData }) => {
    const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
    const [items, setItems] = useState<Pane[]>(defaultPanes);
    const newTabIndex = useRef(0);
    const [newTabName, setNewTabName] = useState('');
    const [editMode, setEditMode] = useState<string | undefined>(undefined);
    const [editingName, setEditingName] = useState('');

    useEffect(() => {
        if (shelfData) {
            getBins(shelfData.id);
        }
    }, [shelfData]); // Dependency array with floorData

    const getBins = (id: any) => {
        if (shelfData) {
            ShelfService.get(id) // Assuming you have a method to get aisles by floor id
                .then((response: any) => {
                    let data: IBinResponse[] = response.data.bins;
                    console.log(data);
                    const binPanes: Pane[] = data.map((bin) => ({
                        label: bin.name,
                        children: <> İçerik {bin.id}</>, // You might want to pass aisle data to Shelf as well
                        key: `binTab${bin.id}`,
                    }));
                    setItems(binPanes);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };

    const onChange = (key: string) => {
        setActiveKey(key);
    };

    const onTabClick = (key: string, event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
        setEditMode(key);
        const currentPane = items.find(pane => pane.key === key);
        setEditingName(currentPane ? currentPane.label : '');
    };

    const handleEditConfirm = (key: string | null) => {
        if (key === null) return;

        const currentPane = items.find(pane => pane.key === key);
        if (!currentPane) return;

        if (currentPane.isNew) {
            // Yeni sekme için addAisle çağrıldığında
            createBin({ name: editingName }); // API isteği
        } else {
            // Mevcut sekme için editAisle çağrıldığında
            editBin(parseInt(key.replace('binTab', '')), { name: editingName, shelfId: shelfData?.id });
        }

        const updatedItems = items.map((pane) => {
            if (pane.key === key) {
                return { ...pane, label: editingName, isNew: false };
            }
            return pane;
        });

        setItems(updatedItems);
        setEditMode(undefined);
    };

    const editBin = (id: number, bin: IBinRequest) => {
        BinService.update(id, bin)
            .then((response: any) => {
                console.log(response)
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    const renderTabBar = (props: any, DefaultTabBar: React.ComponentType<any>) => {
        return <DefaultTabBar {...props}>
            {(node: React.ReactElement) => (
                node.key === editMode && typeof node.key === 'string' ? (
                    <Input
                        autoFocus
                        defaultValue={editingName}
                        onBlur={() => handleEditConfirm(node.key as string)}
                        onPressEnter={() => handleEditConfirm(node.key as string)}
                        onChange={(e) => setEditingName(e.target.value)}
                    />
                ) : (
                    node
                )
            )}
        </DefaultTabBar>
    };

    const addBin = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newTabLabel = newTabName || `Raf ${newTabIndex.current}`;
        setItems([...items, {
            label: newTabLabel,
            children: <>İçerik</>,
            key: newActiveKey
        }]);

        let bin = {
            name: newTabLabel,
            shelfId: shelfData?.id
        }
        createBin(bin)

        setActiveKey(newActiveKey);
        setNewTabName(''); // Reset the input field after adding
    };

    const createBin = (bin: any) => {
        BinService.create(bin)
            .then((response: any) => {
                console.log(response)
                getBins(shelfData?.id)
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    const remove = async (targetKey: TargetKey) => {
        const actualKey = typeof targetKey === 'string' ? targetKey : null;

        if (actualKey !== null) {
            const targetIndex = items.findIndex((pane) => pane.key === actualKey);

            if (targetIndex !== -1) {
                const removedBinId = parseInt(actualKey.replace('binTab', ''));
                try {
                    await removeBin(removedBinId); // Silme işlemini bekleyin
                    const newPanes = items.filter((pane) => pane.key !== actualKey);
                    setItems(newPanes);
                    if (newPanes.length && actualKey === activeKey) {
                        const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
                        setActiveKey(key);
                    }
                } catch (e) {
                    console.error("Silme işlemi sırasında hata oluştu:", e);
                    // Hata durumunda kullanıcıyı bilgilendirin
                }
            }
        }
    };
    const removeBin = async (id: number) => {
        try {
            const response = await BinService.remove(id);
            console.log(response);
            // İlave başarılı işlem kodları...
        } catch (e) {
            throw e; // Hata durumunda hata fırlat
        }
    }

    const onEdit = (targetKey: TargetKey, action: 'add' | 'remove') => {
        if (action === 'add') {
            addBin();
        } else {
            remove(targetKey);
        }
    };
    return (
        <div>
            <Card>
                <Row justify={'end'}>
                    <p style={{ color: "red", textDecoration: "overline", margin: 0 }}><b>RAFLAR</b></p>
                </Row>
                <Tabs
                    tabPosition='right'
                    onChange={onChange}
                    activeKey={activeKey}
                    type="editable-card"
                    onEdit={onEdit}
                    items={items}
                    renderTabBar={renderTabBar}
                    onTabClick={onTabClick}
                />
            </Card>
        </div>
    )
}

export default Bin