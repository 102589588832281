import { Col, Collapse, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { IResponseOrder } from '../../../types/Order';
import ClaimOrderService from '../../../services/ClaimOrderService';
import AmazonProductImageAvatar from '../../../components/Common/AmazonProductImageAvatar';
import AmazonProductInfo from '../../../components/Common/AmazonProductInfo';
import Receiver from '../../../components/FBADetail/Receiver';
import OneamzShippingLabel from '../../../components/FBADetail/OneamzShippingLabel';

interface FBAShipmentItemCardManagerProps {
    item: any;
    order: IResponseOrder,
    orderItem: any,
    setQty: (id: any, qty: any) => void;
}
const FBMShipmentItemCardManager: React.FC<FBAShipmentItemCardManagerProps> = ({ item, order, orderItem, setQty }) => {
    const [receiver, setReceiver] = useState<any>(null)

    const getReceiver = (id: any) => {
        ClaimOrderService.getCustomer(id)
            .then((response: any) => {
                setReceiver(response.data);
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    useEffect(() => {
        getReceiver(order.id)
    }, [])

    return (
        <>
            <Collapse
                className='antd-order-detail-collapse border-radius-md mb-24'
                expandIconPosition={'end'}
                items={[{
                    key: '1',
                    label:
                        <>
                            <Row align={'middle'} gutter={[24, 24]}>
                                <Col span={4}>
                                    <AmazonProductImageAvatar orderItem={item}></AmazonProductImageAvatar>
                                </Col>

                                <Col span={10}>
                                    <Row>
                                        <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <OneamzShippingLabel order={order} orderItem={orderItem} receiver={receiver}></OneamzShippingLabel>
                                </Col>
                            </Row>
                        </>,
                    children:
                        <>
                            <Receiver receiver={receiver}></Receiver>
                        </>
                }]}
            />
        </>
    )
}

export default FBMShipmentItemCardManager