import { Button, Card, Col, Row, Image, Modal } from 'antd';
import { useEffect, useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import './FBMCreate.scss'
import FBMCreateStep1 from '../components/FBMCreate/FBMCreateStep1';
import { openNotification } from '../utils';
import FBMCreateStep2 from '../components/FBMCreate/FBMCreateStep2';
import ServicesService from '../services/ServicesService';
import WarehouseService from '../services/WarehouseService';
import ClaimOrderService from '../services/ClaimOrderService';
import OneamzAppService from '../services/OneamzAppService';
import FBMProgressCard from '../components/V2/CreateFBMV2/FBMProgressCard';
import { ReactComponent as WarningIcon } from '../assets/icons/warning-icon.svg';
import StarterCard from '../components/V2/ProfileV2/StarterCard';
import { ReactComponent as InfoIcon } from '../assets/info-icon.svg';
import FbmImg from '../assets/dashboard-v2/fbm-quickaccess.png';
import FBAResultItemCard from './V2/OrderDetail/FBAResultItemCard';
import { IResponseOrder } from '../types/Order';
import { useNavigate } from 'react-router-dom';



function FBMCreate() {
    const [current, setCurrent] = useState(0);
    const [warehouse, setWarehouse] = useState<any>(null);
    const [country, setCountry] = useState<any>(null);
    const [totalQty, setTotalQty] = useState<any>(0);
    const [totalWeight, setTotalWeight] = useState<any>(0);
    const [totalDeci, setCurrentDeci] = useState<any>(0);
    const [totalFBMCost, setTotalFBMCost] = useState<any>(0);
    const [totalFulfillmentCost, setTotalFulfillmentCost] = useState<any>(0);
    const [totalServiceCost, setTotalServiceCost] = useState<any>(0);
    const [FBMPrepServiceFee, setFBMPrepServiceFee] = useState<any>(0);
    const [totalCustomServiceCost, setTotalCustomServiceCost] = useState<any>(0);
    const [totalFulfillmentCostPerUnit, setTotalFulfillmentCostPerUnit] = useState<any>(0);
    const [productList, setProductList] = useState<any>([]);
    const [loadBalance, setLoadBalance] = useState<any>(0);
    const [warehouseList, setWarehouseList] = useState<any>([])
    const [orderCreateLoading, setOrderCreateLoading] = useState(false)
    const [shipmentFee, setShipmentFee] = useState(0)
    const [currentBalance, setCurrentBalance] = useState(0)
    const [fbmCreated, setFbmCreated] = useState<any>(false);
    const [fbmNo, setFbmNo] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [note, setNote] = useState<any>(null)
    const id = localStorage.getItem('id');
    const externalToken = localStorage.getItem('externalAccessToken');
    const externalRefreshToken = localStorage.getItem('externalRefreshToken');
    const [orderItemServices, setOrderItemServices] = useState<any>([]);
    const [orderServices, setOrderServices] = useState<any>([]);
    const [order, setOrder] = useState<any>([]);
    const [receiver, setReceiver] = useState<any>(null)
    const navigate = useNavigate();


    const getAllWarehouse = () => {
        WarehouseService.getAll()
            .then((response: any) => {
                let data = response.data;
                const newArray = data.map((item: any) => {
                    return { value: item.id, label: `${item.code} ${item.address}`, country: item.countryCode, code: item.code };
                });
                setWarehouseList(newArray);
            })
            .catch((e: Error) => {
            });
    };

    const getOrderServices = () => {
        ServicesService.getOrderServices()
            .then((response: any) => {
                setOrderServices(response.data);

                const orderServiceUpdated = response.data
                    .filter((service: any) => service.id !== 4 && service.id !== 5)
                    .map((service: any) => ({
                        ...service,
                        qty: 0
                    }));

                setOrderServices(orderServiceUpdated);

            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const getOrderItemServices = () => {
        ServicesService.getOrderItemServices()
            .then((response: any) => {
                let items = response.data;
                items = items.sort((a: any, b: any) => a.id - b.id);
                const orderItemServiceUpdated = items
                    .filter((service: any) => service.id !== 1 && service.id !== 2 && service.id !== 3 && service.id !== 12)
                    .map((service: any) => ({
                        ...service,
                        qty: (service.id == 1) ? 1 : (service.isOptional) ? 0 : 1
                    }));
                setOrderItemServices(orderItemServiceUpdated);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    useEffect(() => {
        getOrderItemServices();
        getOrderServices();
        getAllWarehouse();
    }, [])


    useEffect(() => {
        calcParams();
        console.log(productList);
    }, [productList, orderServices])

    const calcParams = () => {
        let deci = 0;
        let weight = 0;
        let qty = 0;

        let fulfillmentCost: any = 0;
        let serviceCost = 0;
        let customServiceCost = 0;
        let fulfillmentCostPerUnit = 0;
        let totalShipmentFee = 0;

        let FBMCost = 0;

        orderServices.map((orderService: any) => {
            FBMCost += (orderService.qty * orderService.fee);
        })

        setTotalFBMCost(FBMCost);

        productList.map((orderItem: any) => {
            weight += (orderItem.qty * orderItem.weight)
            deci += (orderItem.qty * orderItem.deci)
            qty += orderItem.qty

            orderItem.services.map((serviceItem: any) => {
                if (serviceItem.isOptional) {
                    if (serviceItem.qty > 0) {
                        customServiceCost += (serviceItem.qty * serviceItem.fee);
                    }
                } else {
                    serviceCost += (serviceItem.qty * serviceItem.fee);
                }
            })

            totalShipmentFee += orderItem.shipmentFee;
            console.log('orderItem', orderItem);

        });

        fulfillmentCost = (FBMCost + shipmentFee + serviceCost + customServiceCost).toFixed(2);
        const FbmPrepFee = (FBMCost + serviceCost + customServiceCost + totalShipmentFee).toFixed(2);

        console.log(FbmPrepFee);

        fulfillmentCostPerUnit = (fulfillmentCost / qty);

        setTotalQty(qty);
        setTotalWeight(weight.toFixed(2));
        setCurrentDeci(deci.toFixed(2));
        setTotalFulfillmentCost(FbmPrepFee);
        setTotalCustomServiceCost(customServiceCost.toFixed(2));
        setTotalServiceCost(serviceCost.toFixed(2));
        setFBMPrepServiceFee(FbmPrepFee);
        setTotalFulfillmentCostPerUnit(fulfillmentCostPerUnit.toFixed(2));

    }

    const addProduct = (data: any) => {
        if (!productList.some((item: any) => item.asin === data.asin)) {
            let newData = {
                ...data,
                qty: 1,
                purchasePrice: 0,
                fnskuLabel: null,
                trackingCode: null,
                services: JSON.parse(JSON.stringify(orderItemServices)),
                customer: {},
                shipmentCompany: 'ONEAMZ',
                shipmentFee: null,
                deliveryOption: null
            };
            setProductList([...productList, newData]);
            openNotification('success', 'Başarılı', 'Ürün listeye eklendi')
        } else {
            openNotification('warning', 'Uyarı', 'Ürün listede mevcut')
        }
    };

    const onHandleSetQty = (id: any, qty: any) => {
        if (qty === null) {
            return false;
        }
        let products = productList.map((product: any) => {
            if (product.id === id) {
                product.qty = qty;

                let services = product.services.map((serviceItem: any) => {
                    if (!serviceItem.isOptional) {
                        serviceItem.qty = qty;
                        return serviceItem;
                    } else {

                        if (serviceItem.id == 1) {
                            if (serviceItem.qty > 0) {
                                serviceItem.qty = qty;
                                return serviceItem;
                            }
                        } else {
                            if (serviceItem.qty > qty) {
                                serviceItem.qty = qty;
                                return serviceItem;
                            }
                        }
                    }
                    return serviceItem;
                })

                product.services = services;
                return product;
            }
            return product;
        });
        setProductList(products);
    }

    const onHandleSetServiceQty = (productId: any, serviceId: any, qty: any) => {
        let products = productList.map((product: any) => {
            if (product.id === productId) {
                let services = product.services.map((serviceItem: any) => {
                    if (!serviceItem.isOptional) {
                        return serviceItem;
                    } else {
                        if (serviceItem.id === serviceId) {
                            serviceItem.qty = qty;
                            return serviceItem;
                        }
                    }
                    return serviceItem;
                })

                product.services = services;
                return product;
            }
            return product;
        });
        setProductList(products);
    }

    const onHandlePurchasePrice = (id: any, purchasePrice: any) => {
        let products = productList.map((product: any) => {
            if (product.id === id) {
                product.purchasePrice = purchasePrice;
                return product;
            }
            return product;
        });
        setProductList(products);
        console.log(products);
    }

    const onHandleRemoveProduct = (id: any) => {
        const updatedList = productList.filter((item: any) => item.id !== id);
        setProductList(updatedList);
        openNotification('success', 'Başarılı', 'Ürün listeden çıkarıldı')
    };

    const onHandleChangeShipmentFee = (id: any, fee: any) => {

        let products = productList.map((product: any) => {
            if (product.id === id) {
                product.shipmentFee = fee;

                return product;
            }
            return product;
        });
        setProductList(products);
    };

    const onHandleChangeDeliveryOption = (id: any, deliveryOption: any) => {
        let products = productList.map((product: any) => {
            if (product.id === id) {
                product.deliveryOption = deliveryOption;

                return product;
            }
            return product;
        });
        setProductList(products);
    };

    const onHandleChangeCustomer = (id: any, data: any) => {
        console.log(id);
        console.log(data);

        let products = productList.map((product: any) => {
            if (product.id === id) {
                product.customer = data;
                return product;
            }
            return product;
        });
        setProductList(products);
    };

    const refreshPage = () => {
        window.location.reload();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const steps = [
        {
            title: '',
            content: <FBMCreateStep1
                warehouse={warehouse}
                removeProduct={onHandleRemoveProduct}
                setQty={onHandleSetQty}
                setPurchasePrice={onHandlePurchasePrice}
                addProduct={addProduct}
                totalQty={totalQty}
                productList={productList}
                totalWeight={totalWeight}
                totalDeci={totalDeci}
                warehouseList={warehouseList}
                setServiceQty={onHandleSetServiceQty}
                country={country}
            ></FBMCreateStep1>,
        },
        {
            title: '',
            content: <FBMCreateStep2
                changeCustomer={onHandleChangeCustomer}
                changeShipmentFee={onHandleChangeShipmentFee}
                changeDeliveryOption={onHandleChangeDeliveryOption}
                productList={productList}
            ></FBMCreateStep2>,
        },
    ];

    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const getBalance = () => {
        for (let i = 0; i < productList.length; i++) {
            const product = productList[i];
            if (product.shipmentFee === null) {
                openNotification('warning', 'Uyarı', 'Kargo teklifi seçiniz');
                return false;
            }
        }

        let isUndefined = false;
        for (let i = 0; i < productList.length; i++) {
            const product = productList[i];
            for (let key in product.customer) {
                if (key !== 'addressLine2') {
                    if (product.customer[key] === undefined || product.customer[key] === '' || product.customer[key] === null) {
                        isUndefined = true;
                        break;
                    }
                }
            }
        }

        if (isUndefined) {
            openNotification('warning', 'Uyarı', 'Müşteri bilgilerinde zorunlu alanları giriniz');
            return false;
        }

        OneamzAppService.getBalance()
            .then((response: any) => {
                let currentBalance: number = response.data.balance;
                setCurrentBalance(currentBalance);
                if (currentBalance < totalFulfillmentCost) {
                    setLoadBalance((totalFulfillmentCost - currentBalance).toFixed(2))
                    setIsModalOpen(true);
                } else {
                    setIsConfirmModal(true);
                }
            }).catch((e: Error) => {
            });
    }

    const getClaimOrderDetail = (id: string) => {
        ClaimOrderService.get(id)
            .then((response: any) => {
                const data: IResponseOrder = new IResponseOrder(response.data);
                setOrder([...order, data]);
                getReceiver(id);
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    const onCreateOrder = () => {
        setOrderCreateLoading(true);
        let requestList: any[] = [];
        productList.map((orderItem: any) => {


            console.log('orderItem', orderItem);

            let claimOrderItems: any = [];

            let orderItemObj = {
                productId: orderItem.id,
                qty: orderItem.qty,
                fnskuLabel: null,
                trackingCode: orderItem.trackingCode,
                claimOrderItemGeneralServices: []
            }

            if (orderItem.qty > 0) {
                let orderItemServiceListArr: any = [];
                orderItem.services.map((service: any) => {
                    if (service.qty > 0) {
                        const orderItemService = {
                            generalServiceId: service.id,
                            qty: service.qty
                        }
                        orderItemServiceListArr.push(orderItemService)
                    }
                })

                orderItemObj.claimOrderItemGeneralServices = orderItemServiceListArr;
                claimOrderItems.push(orderItemObj);
            }

            let postObj = {
                warehouseId: warehouse,
                orderType: "FBM_SHIPMENT",
                shippingFee: orderItem.shipmentFee,
                deliveryOption: orderItem.deliveryOption,
                note: note,
                isInWareHouse: true,
                shippingProvider: "ONEAMZ",
                claimOrderItems: claimOrderItems,
                claimOrderGeneralServices: [],
                customer: orderItem.customer
            };

            requestList.push(ClaimOrderService.create(postObj));

        });

        Promise.all(requestList)
            .then(responses => {
                console.log('responses', responses);
                setOrderCreateLoading(false);

                const claimOrderId = responses[0].data;
                openNotification('success', 'Başarılı', "FBM gönderisi oluşturuldu");
                setOrderCreateLoading(false);
                setFbmCreated(true);
                setFbmNo(claimOrderId)
                getClaimOrderDetail(claimOrderId);
            })
            .catch(error => {
                console.error(error);
                setOrderCreateLoading(false);
            });

    }

    const getReceiver = (id: any) => {
        ClaimOrderService.getCustomer(id)
            .then((response: any) => {
                setReceiver(response.data);
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    return (
        <>
            {(!fbmCreated) ? <>
                <Modal title={<h3 style={{ margin: '0px' }}>Krediniz Yetersiz</h3>} open={isModalOpen} onCancel={handleCancel}
                    footer={(_, { }) => (
                        <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                            <Col>
                                <Button type='primary' style={{ backgroundColor: 'black', color: "pure-white", padding: '0px 30px' }} onClick={handleCancel} >İptal Et</Button>
                            </Col>
                            <Col>
                                <a target='_blank' href={`${process.env.REACT_APP_EXTERNAL_BASE_URL}/public/module/callback?accessToken=${externalToken}&refreshToken=${externalRefreshToken}&redirectUrl=/balance?amount%3D${loadBalance}%26openPaymentModal%3Dtrue`}>
                                    <Button style={{ backgroundColor: '#FFCB2B', color: 'black', padding: '0px 20px' }} type='primary'>Kredi Yükle</Button>
                                </a>
                            </Col>
                        </Row>
                    )}>
                    <Row justify={"center"}>
                        <InfoCircleOutlined style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                    </Row>
                    <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px" }}>İşleminiz için en az <b>${loadBalance}</b> kredi yüklemelisiniz</h3>
                </Modal>
                <Modal open={isConfirmModal} onCancel={() => { setIsConfirmModal(false) }}
                    footer={(_, { }) => (
                        <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                            <Col>
                                <Button className='bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text' onClick={() => { setIsConfirmModal(false) }} >İptal Et</Button>
                            </Col>
                            <Col>
                                <Button className='bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text' loading={orderCreateLoading} onClick={() => { onCreateOrder() }} >Onayla</Button>
                            </Col>
                        </Row>
                    )}>
                    <Row justify={"center"}>
                        <WarningIcon className='ml-32' style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                    </Row>
                    <Row justify={'center'}>
                        <Col span={20}>
                            <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px" }}>FBM Gönderisi ödemesi için toplam kredinizden  <b>${totalFulfillmentCost}</b>  düşecektir. Onaylıyor musunuz?</h3>
                        </Col>
                    </Row>
                </Modal>


                {/* <Modal open={isConfirmModal} onCancel={() => { setIsConfirmModal(false) }}
                    footer={(_, { }) => (
                        <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                            <Col>
                                <Button className='bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text' onClick={() => { setIsConfirmModal(false) }} >İptal Et</Button>
                            </Col>
                            <Col>
                                <Button className='bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text' loading={orderCreateLoading} onClick={() => { onCreateOrder() }} >Onayla</Button>
                            </Col>
                        </Row>
                    )}>
                    <Row justify={"center"}>
                        <WarningIcon className='ml-32' style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                    </Row>
                    <Row justify={'center'}>
                        <Col span={20}>
                            <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px" }}>FBA Gönderisi ödemesi için toplam kredinizden  <b>${totalFulfillmentCost}</b>  düşecektir. Onaylıyor musunuz?</h3>
                        </Col>
                    </Row>
                </Modal>

                <Modal open={isConfirmModal} onCancel={() => { setIsConfirmModal(false) }}
                    footer={(_, { }) => (
                        <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                            <Col>
                                <Button type='primary' style={{ backgroundColor: 'black', color: "pure-white", padding: '0px 30px' }} onClick={() => { setIsConfirmModal(false) }} >İptal Et</Button>
                            </Col>
                            <Col>
                                <Button type='primary' style={{ backgroundColor: '#FFCB2B', color: 'black', padding: '0px 30px' }} loading={orderCreateLoading} onClick={() => { onCreateOrder() }} >Onayla</Button>
                            </Col>
                        </Row>
                    )}>
                    <Row justify={"center"}>
                        <InfoCircleOutlined style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                    </Row>
                    <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px" }}>FBM Gönderisi ödemesi için toplam kredinizden  <b>${totalFulfillmentCost}</b>  düşecektir. Onaylıyor musunuz?</h3>
                </Modal> */}
                <FBMProgressCard currentStep={current} />
                {/* <Row justify={"center"} align={"middle"}>
                    <Col span={10}>
                        <Steps
                            className='fbm-steps'
                            size="default"
                            current={current}
                            items={items}
                        />
                    </Col>
                </Row> */}
                <div>{steps[current].content}</div>
                <Row style={{ marginTop: 24 }} justify={(current == 0) ? "end" : "space-between"}>
                    {current > 0 && (
                        <Button className='btn btn-hover-dark border-radius-12 bg-cloud btn-text font-color-slate-blue' size='large' onClick={() => prev()}>
                            Geri
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button disabled={totalQty == 0} className='btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud' size='large' onClick={() => next()}>
                            Sonraki Adım
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button className='btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud' size='large' onClick={() => getBalance()}>
                            FBM Gönderisi Oluştur
                        </Button>
                    )}

                </Row>
            </>
                : <>
                    <Row>
                        <Col span={24}>
                            <StarterCard />
                        </Col>
                    </Row>
                    <Card className='mt-24' bodyStyle={{ border: 'none', padding: '0px' }} style={{ border: 'none' }}>
                        <Row gutter={24}>
                            <Col xl={6} xs={24}>
                                <Card className='bg-oneamz-yellow border-radius-md'>
                                    <p className='text font-color-slate-blue bg-cloud p-8 d-inline border-radius-sm'><b>FBM GÖNDERİ KODU <InfoIcon className='wh-14' /></b></p>
                                    <h2 className='h-3 font-color-slate-blue mt-16 mb-0'>ONESHIP</h2>
                                    <h2 className='h-3 font-color-slate-blue mt-0'>({id}-FBM-{fbmNo})</h2>
                                    <p className='text fs-18 font-color-slate-blue' >
                                        Ürününüzün detaylarına ulaşmak için, oneship gönderi koduyla uygulama içinde arama yapabilirsiniz
                                    </p>
                                    <Row justify={'center'} className='mt-64'>
                                        <Col>
                                            <Image preview={false} src={FbmImg}></Image>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl={18} xs={24}>
                                <Card className='bg-cloud border-radius-md p-0'>
                                    <h1 className='h-1 font-color-slate-blue m-0 p-0 mt-0'>Tebrikler!</h1>
                                    <p className='text fs-24 font-color-slate-blue m-0'>FBM Gönderiniz Başarıyla Kaydedilmiştir</p>
                                    <p className='text font-color-slate-blue mt-8'>Göstermiş olduğunuz özen ve dikkat için teşekkür ederiz. OneAMZ FBM sürecinizin bu aşamasını başarıyla tamamladınız. Gönderiniz, sistemimize başarıyla kaydedildi ve şimdi işleme alınmak üzere hazır.</p>
                                    <p className='pretitle font-color-slate-blue mt-32 upper-case'>ÜRÜN BİLGİLERİ</p>
                                    {order?.map((fbmOrder: any, key: any) => (
                                        fbmOrder?.items.map((item: any, index: any) => (
                                            <FBAResultItemCard item={item} key={index} order={fbmOrder} setQty={() => { }} orderItem={item}></FBAResultItemCard>
                                        ))
                                    ))}

                                    <p className='pretitle font-color-slate-blue mt-32 upper-case'>MÜŞTERİ BİLGİLERİ</p>

                                    <h3 className='mt-0 mb-0'><b>{receiver?.fullName}</b></h3>
                                    <p className='mt-8 font-color-slate-blue-08'>{receiver?.phoneNumber} - {receiver?.email}</p>
                                    <p className='mt-0 font-color-slate-blue-08'>{receiver?.addressLine1} <br /> {receiver?.addressLine2} {receiver?.postCode}</p>
                                    <p className='mt-0 font-color-slate-blue-08'>{receiver?.city} {receiver?.state} {receiver?.country}</p>
                                    <Row justify={'end'} gutter={8}>
                                        <Col>
                                            <Button onClick={() => navigate(`/claim/detail/${fbmNo}`)} className=' btn btn-text btn-hover-dark bg-cloud font-color-slate-blue border-slate-blue border-radius-12 mt-48'>
                                                Gönderi Detayına Git
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button onClick={() => refreshPage()} className='btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-12 mt-48'>
                                                Yeni FBM Gönderisini Oluştur
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </>}
        </>
    )
}

export default FBMCreate