import { Button, Card, Col, Row, Image, Modal, Alert } from 'antd';
import { useEffect, useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import './FBACreate.scss'
import FBACreateStep1 from '../components/FBACreate/FBACreateStep1';
import { openNotification } from '../utils';
import FBACreateStep2 from '../components/FBACreate/FBACreateStep2';
import ServicesService from '../services/ServicesService';
import FBACreateStep3 from '../components/FBACreate/FBACreateStep3';
import FBACreateStep4 from '../components/FBACreate/FBACreateStep4';
import WarehouseMeService from '../services/WarehouseMeService';
import ClaimOrderService from '../services/ClaimOrderService';
import FileUploadService from '../services/FileUploadService';
import OneamzAppService from '../services/OneamzAppService';
import ProgressCard from '../components/V2/CreateFBAV2/ProgressCard';
import { ReactComponent as WarningIcon } from '../assets/icons/warning-icon.svg';
import StarterCard from '../components/V2/ProfileV2/StarterCard';
import FbaImg from '../assets/dashboard-v2/fba-quickaccess.png';
import { ReactComponent as InfoIcon } from '../assets/info-icon.svg';
import { IResponseOrder } from '../types/Order';
import FBAResultItemCard from './V2/OrderDetail/FBAResultItemCard';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import WarehouseCard from '../components/FBACreate/WarehouseCard';

function FBACreate() {
    const [current, setCurrent] = useState(0);
    const [warehouse, setWarehouse] = useState<any>(null);
    const [country, setCountry] = useState<any>(null);
    const [totalQty, setTotalQty] = useState<any>(0);
    const [totalWeight, setTotalWeight] = useState<any>(0);
    const [totalDeci, setCurrentDeci] = useState<any>(0);
    const [totalFBACost, setTotalFBACost] = useState<any>(0);
    const [totalFulfillmentCost, setTotalFulfillmentCost] = useState<any>(0);
    const [totalServiceCost, setTotalServiceCost] = useState<any>(0);
    const [FBAPrepServiceFee, setFBAPrepServiceFee] = useState<any>(0);
    const [totalCustomServiceCost, setTotalCustomServiceCost] = useState<any>(0);
    const [totalFulfillmentCostPerUnit, setTotalFulfillmentCostPerUnit] = useState<any>(0);
    const [productList, setProductList] = useState<any>([]);
    const [fbaServices, setFbaServices] = useState<any>([]);
    const [packageCount, setPackageCount] = useState<any>(0);
    const [loadBalance, setLoadBalance] = useState<any>(0);
    const [fbaLabel, setFBALabel] = useState<any>(null);
    const [cargoLabel, setCargoLabel] = useState<any>(null);
    const [warehouseList, setWarehouseList] = useState<any>([])
    const [orderCreateLoading, setOrderCreateLoading] = useState(false)
    const [shipmentCompany, setShipmentCompany] = useState("AMAZON")
    const [shipmentFee, setShipmentFee] = useState(0)
    const [currentBalance, setCurrentBalance] = useState(0)
    const [fbaCreated, setFbaCreated] = useState<any>(false);
    const [fbaNo, setFbaNo] = useState<any>(null);
    const [shipmentCode, setShipmentCode] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [labelModal, setLabelModal] = useState(false);
    const [fnskuWarning, setFnskuWarning] = useState(false);
    const [note, setNote] = useState<any>(null)
    const id = localStorage.getItem('id');
    const [order, setOrder] = useState<any>();

    const navigate = useNavigate();

    const externalToken = localStorage.getItem('externalAccessToken');
    const externalRefreshToken = localStorage.getItem('externalRefreshToken');

    const setFBAShipment = async (FBAShipment: any, account: any) => {
        console.log(FBAShipment.shipmentId);
        warehouseList.map((item: any) => {
            if (item.code == FBAShipment?.destinationFulfillmentCenterId) {
                setCountry(item.country)
                setWarehouse(item.value)
            }
        });

        getAmazonFbaLabelByShipmentId(account, FBAShipment.shipmentId);
    }

    const getAmazonFbaLabelByShipmentId = (userAccountId: number, shipmentId: string) => {
        OneamzAppService.getAmazonFbaLabelByShipmentId(userAccountId, shipmentId)
            .then(async (response: any) => {
                const downloadURL = response.data.payload.downloadURL;
                uploadTemp(downloadURL, shipmentId);
            })
            .catch((e: Error) => {
            });
    };

    const uploadTemp = (amazonFBALabelPath: string, shipmentId: string) => {
        let data = new FormData();
        data.append('fileUrl', amazonFBALabelPath);

        let config = {
            method: 'post',
            url: 'https://cdn-oneamz.dact.studio/fba-label/index.php',
            data: data,
            responseType: 'blob' as const,
        };

        axios.request(config)
            .then(async (response) => {
                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                const fileName = `${shipmentId}.pdf`;
                const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf' });
                setFBALabel(pdfFile);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        console.log('cargoLabel', cargoLabel)
    }, [cargoLabel])

    const colorsArray = [
        "rgba(255, 0, 0,",
        "rgba(0, 255, 0,",
        "rgba(0, 0, 255,",
        "rgba(255, 255, 0,",
        "rgba(255, 0, 255,",
        "rgba(0, 255, 255,",
        "rgba(128, 0, 0,",
        "rgba(0, 128, 0,",
        "rgba(0, 0, 128,",
        "rgba(128, 128, 0,",
        "rgba(128, 0, 128,",
        "rgba(0, 128, 128,",
        "rgba(255, 128, 0,",
        "rgba(255, 0, 128,",
        "rgba(128, 255, 0,",
        "rgba(0, 255, 128,",
        "rgba(128, 0, 255,",
        "rgba(0, 128, 255,",
        "rgba(255, 128, 128,",
        "rgba(128, 255, 128,",
        "rgba(128, 128, 255,",
        "rgba(192, 192, 192,",
        "rgba(128, 128, 128,",
        "rgba(255, 255, 255,"
    ];

    const [orderItemServices, setOrderItemServices] = useState<any>([]);
    const [orderServices, setOrderServices] = useState<any>([]);

    const getAllWarehouse = () => {
        WarehouseMeService.getAll()
            .then((response: any) => {
                let data = response.data;
                const newArray = data.map((item: any) => {
                    return { value: item.id, label: `${item.code} ${item.address}`, country: item.countryCode, code: item.code };
                });
                setWarehouseList(newArray);
            })
            .catch((e: Error) => {
            });
    };

    const getOrderServices = () => {
        ServicesService.getOrderServices()
            .then((response: any) => {
                setOrderServices(response.data);

                const orderServiceUpdated = response.data.map((service: any) => ({
                    ...service,
                    qty: 0
                }));

                setOrderServices(orderServiceUpdated);

            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const getOrderItemServices = () => {
        ServicesService.getOrderItemServices()
            .then((response: any) => {
                let items = response.data;
                items = items.sort((a: any, b: any) => a.id - b.id);
                const orderItemServiceUpdated = items.map((service: any) => ({
                    ...service,
                    qty: (service.id == 1) ? 1 : (service.isOptional) ? 0 : 1
                }));
                setOrderItemServices(orderItemServiceUpdated);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    useEffect(() => {
        getOrderItemServices();
        getOrderServices();
        getAllWarehouse();
    }, [])

    useEffect(() => {
        let services = orderServices.map((service: any) => {
            if (!service.isOptional) {
                service.qty = packageCount;
                return service;
            }
            return service;
        });
        setOrderServices(services);
    }, [packageCount])


    useEffect(() => {
        calcParams();
    }, [productList, shipmentFee, orderServices])

    useEffect(() => {
        orderServiceList();
    }, [productList])

    const orderServiceList = () => {
        let fbaServiceList: { [key: string]: any } = {};

        productList.forEach((product: any) => {
            product.services.forEach((service: any) => {
                if (service.qty > 0) {
                    // Check if the service id already exists in the accumulator object
                    if (fbaServiceList.hasOwnProperty(service.id)) {
                        // If it does, increment the qty
                        fbaServiceList[service.id].qty += service.qty;
                    } else {
                        // If it doesn't, initialize it with the current service
                        // Clone the service object to avoid unintended side effects
                        fbaServiceList[service.id] = { ...service };
                    }
                }
            });
        });

        // Convert the accumulator object back to an array of its values
        const aggregatedServiceList = Object.values(fbaServiceList);
        setFbaServices(aggregatedServiceList);
    }

    const calcParams = () => {
        let deci = 0;
        let weight = 0;
        let qty = 0;

        let fulfillmentCost: any = 0;
        let serviceCost = 0;
        let customServiceCost = 0;
        let fulfillmentCostPerUnit = 0;

        let FBACost = 0;

        orderServices.map((orderService: any) => {
            FBACost += (orderService.qty * orderService.fee);
        })

        setTotalFBACost(FBACost);

        productList.map((orderItem: any) => {
            weight += (orderItem.qty * orderItem.weight)
            deci += (orderItem.qty * orderItem.deci)
            qty += orderItem.qty

            orderItem.services.map((serviceItem: any) => {
                if (serviceItem.isOptional) {
                    if (serviceItem.qty > 0) {
                        customServiceCost += (serviceItem.qty * serviceItem.fee);
                    }
                } else {
                    serviceCost += (serviceItem.qty * serviceItem.fee);
                }
            })

        });

        fulfillmentCost = (FBACost + shipmentFee + serviceCost + customServiceCost).toFixed(2);
        const FbaPrepFee = (FBACost + serviceCost + customServiceCost).toFixed(2);

        fulfillmentCostPerUnit = (fulfillmentCost / qty);

        setTotalQty(qty);
        setTotalWeight(weight.toFixed(2));
        setCurrentDeci(deci.toFixed(2));
        setTotalFulfillmentCost(fulfillmentCost);
        setTotalCustomServiceCost(customServiceCost.toFixed(2));
        setTotalServiceCost(serviceCost.toFixed(2));
        setFBAPrepServiceFee(FbaPrepFee);
        setTotalFulfillmentCostPerUnit(fulfillmentCostPerUnit.toFixed(2));

    }

    const addProduct = (data: any) => {
        if (!productList.some((item: any) => item.asin === data.asin)) {
            let newData = {
                ...data,
                qty: 1,
                color: colorsArray[productList.length - 1],
                purchasePrice: 0,
                fnskuLabel: null,
                trackingCode: null,
                services: JSON.parse(JSON.stringify(orderItemServices))
            };
            setProductList([...productList, newData]);
            openNotification('success', 'Başarılı', 'Ürün listeye eklendi')
        } else {
            openNotification('warning', 'Uyarı', 'Ürün listede mevcut')
        }
    };

    const onHandleSetQty = (id: any, qty: any) => {
        if (qty === null) {
            return false;
        }
        let products = productList.map((product: any) => {
            if (product.id === id) {
                product.qty = qty;

                let services = product.services.map((serviceItem: any) => {
                    if (!serviceItem.isOptional) {
                        serviceItem.qty = qty;
                        return serviceItem;
                    } else {

                        if (serviceItem.id == 1) {
                            if (serviceItem.qty > 0) {
                                serviceItem.qty = qty;
                                return serviceItem;
                            }
                        } else {
                            if (serviceItem.qty > qty) {
                                serviceItem.qty = qty;
                                return serviceItem;
                            }
                        }
                    }
                    return serviceItem;
                })

                product.services = services;
                return product;
            }
            return product;
        });
        setProductList(products);
    }

    const onHandleSetServiceQty = (productId: any, serviceId: any, qty: any) => {
        let products = productList.map((product: any) => {
            if (product.id === productId) {
                let services = product.services.map((serviceItem: any) => {
                    if (!serviceItem.isOptional) {
                        return serviceItem;
                    } else {
                        if (serviceItem.id === serviceId) {
                            serviceItem.qty = qty;
                            return serviceItem;
                        }
                    }
                    return serviceItem;
                })

                product.services = services;
                return product;
            }
            return product;
        });
        setProductList(products);
    }

    const onHandlePurchasePrice = (id: any, purchasePrice: any) => {
        let products = productList.map((product: any) => {
            if (product.id === id) {
                product.purchasePrice = purchasePrice;
                return product;
            }
            return product;
        });
        setProductList(products);
        console.log(products);
    }

    const onHandleRemoveProduct = (id: any) => {
        const updatedList = productList.filter((item: any) => item.id !== id);
        setProductList(updatedList);
        openNotification('success', 'Başarılı', 'Ürün listeden çıkarıldı')
    };

    const onHandleSetTrackingCode = (id: any, code: any) => {
        let products = productList.map((product: any) => {
            if (product.id === id) {
                product.trackingCode = code;
                return product;
            }
            return product;
        });
        setProductList(products);
    };

    const onHandleSetFNSKULabel = (id: any, file: any) => {
        let products = productList.map((product: any) => {
            if (product.id === id) {
                product.fnskuLabel = file;
                return product;
            }
            return product;
        });
        setProductList(products);
    };

    const onHandleSetFBALabel = (file: any) => {
        setFBALabel(file);
    };

    const onHandleSetWarehouse = (id: any) => {
        setWarehouse(id);
    };

    const onHandleSetCountry = (countryCode: any) => {
        setCountry(countryCode);
    };

    const onHandleChangeShipmentCompany = (company: any) => {
        setShipmentCompany(company);
    };

    const onHandleChangeShipmentFee = (fee: any) => {
        console.log(fee);
        setShipmentFee(fee);
    };

    const refreshPage = () => {
        window.location.reload();
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const steps = [
        {
            title: '',
            content: <FBACreateStep1
                warehouse={warehouse}
                removeProduct={onHandleRemoveProduct}
                setQty={onHandleSetQty}
                setPurchasePrice={onHandlePurchasePrice}
                addProduct={addProduct}
                setWarehouse={onHandleSetWarehouse}
                totalQty={totalQty}
                productList={productList}
                totalWeight={totalWeight}
                totalDeci={totalDeci}
                warehouseList={warehouseList}
                country={country}
                setOnHandleCountry={onHandleSetCountry}
            ></FBACreateStep1>,
        },
        {
            title: '',
            content: <FBACreateStep2
                changeShipmentCompany={onHandleChangeShipmentCompany}
                changeShipmentFee={onHandleChangeShipmentFee}
                removeProduct={onHandleRemoveProduct}
                setQty={onHandleSetQty}
                setPurchasePrice={onHandlePurchasePrice}
                addProduct={addProduct}
                setWarehouse={onHandleSetWarehouse}
                setServiceQty={onHandleSetServiceQty}
                setPackageCount={setPackageCount}
                setCargoLabel={setCargoLabel}
                totalFBACost={totalFBACost}
                FBAPrepServiceFee={FBAPrepServiceFee}
                cargoLabel={cargoLabel}
                country={country}
                totalQty={totalQty}
                productList={productList}
                fbaServices={fbaServices}
                orderServices={orderServices}
                totalWeight={totalWeight}
                totalDeci={totalDeci}
                totalFulfillmentCost={totalFulfillmentCost}
                totalServiceCost={totalServiceCost}
                totalCustomServiceCost={totalCustomServiceCost}
                totalFulfillmentCostPerUnit={totalFulfillmentCostPerUnit}
            ></FBACreateStep2>,
        },
        {
            title: '',
            content: <FBACreateStep3
                setTrackingCode={onHandleSetTrackingCode}
                setFNSKULabel={onHandleSetFNSKULabel}
                setFBALabel={onHandleSetFBALabel}
                setPackageCount={setPackageCount}
                totalQty={totalQty}
                productList={productList}
                totalWeight={totalWeight}
                orderServices={orderServices}
                totalDeci={totalDeci}
                fbaLabel={fbaLabel}
            ></FBACreateStep3>,
        },
        {
            title: '',
            content: <FBACreateStep4
                setNote={setNote}
                warehouse={warehouse}
                totalQty={totalQty}
                country={country}
                productList={productList}
                totalWeight={totalWeight}
                totalDeci={totalDeci}
                shipmentFee={shipmentFee}
                shipmentCompany={shipmentCompany}
                FBAPrepServiceFee={FBAPrepServiceFee}
                totalFBACost={totalFBACost}
                totalServiceCost={totalServiceCost}
                orderServices={orderServices}
                totalCustomServiceCost={totalCustomServiceCost}
                totalFulfillmentCost={totalFulfillmentCost}
                totalFulfillmentCostPerUnit={totalFulfillmentCostPerUnit}
                warehouseList={warehouseList}
            ></FBACreateStep4>,
        },
    ];

    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    const next = () => {
        if (current == 2) {
            modalRender();
        } else {
            setCurrent(current + 1);
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const getBalance = () => {
        OneamzAppService.getBalance()
            .then((response: any) => {
                let currentBalance: number = response.data.balance;
                setCurrentBalance(currentBalance);
                if (currentBalance < totalFulfillmentCost) {
                    setLoadBalance((totalFulfillmentCost - currentBalance).toFixed(2))
                    setIsModalOpen(true);
                } else {
                    setIsConfirmModal(true);
                    // onCreateOrder();
                }
            }).catch((e: Error) => {
            });
    }

    const onCreateOrder = () => {

        setOrderCreateLoading(true);

        let claimOrderItems: any = [];

        productList.map((orderItem: any) => {

            let orderItemObj = {
                productId: orderItem.id,
                qty: orderItem.qty,
                fnskuLabel: null,
                trackingCode: orderItem.trackingCode,
                claimOrderItemGeneralServices: []
            }

            if (orderItem.qty > 0) {
                let orderItemServiceListArr: any = [];
                orderItem.services.map((service: any) => {
                    if (service.qty > 0) {
                        const orderItemService = {
                            generalServiceId: service.id,
                            qty: service.qty
                        }
                        orderItemServiceListArr.push(orderItemService)
                    }
                })

                orderItemObj.claimOrderItemGeneralServices = orderItemServiceListArr;

                claimOrderItems.push(orderItemObj);

                console.log('orderItemObj', orderItemObj);
            }

        });

        let claimOrderGeneralServices: any = [];

        orderServices.map((orderService: any) => {

            let orderServicePostObj = {
                generalServiceId: orderService.id,
                qty: orderService.qty
            };
            claimOrderGeneralServices.push(orderServicePostObj);

        })


        let postObj = {
            warehouseId: warehouse,
            orderType: "FBA_SHIPMENT",
            shippingFee: (shipmentCompany === "ONEAMZ") ? shipmentFee : 0,
            note: note,
            isInWareHouse: false,
            shippingProvider: shipmentCompany,
            claimOrderItems: claimOrderItems,
            claimOrderGeneralServices: claimOrderGeneralServices
        };

        console.log(postObj);

        ClaimOrderService.create(postObj)
            .then((response: any) => {
                const claimOrderId = response.data;
                setFbaNo(claimOrderId)
                setShipmentCode(`ONESHIP (${id}-FBA-${claimOrderId})`)
                uploadFnskuLabel(claimOrderId);
                getClaimOrderDetail(claimOrderId);
            })
            .catch((e: Error) => {
                console.log(e);
                setOrderCreateLoading(false);
            });
    }

    const getClaimOrderDetail = (id: string) => {
        ClaimOrderService.get(id)
            .then((response: any) => {
                const data: IResponseOrder = new IResponseOrder(response.data);
                setOrder(data);
            }).catch((e: Error) => {
                console.log(e)
            });
    }

    const uploadFnskuLabel = async (claimOrderId: any) => {
        try {
            // Retrieve claim order data
            const response = await ClaimOrderService.get(claimOrderId);
            const claimOrderItems = response.data.claimOrderItems;

            // Prepare an array to hold all the upload promises
            const uploadPromises = [];

            // Loop through each claimOrderItem and corresponding product
            for (const claimOrderItem of claimOrderItems) {
                for (const product of productList) {
                    if (product.asin === claimOrderItem.product.asin) {
                        // Add the upload promise to the array
                        if (product.fnskuLabel != null) {
                            const uploadPromise = FileUploadService.uploadFnskuLabel(claimOrderItem.id, product.fnskuLabel);
                            uploadPromises.push(uploadPromise);
                        }
                    }
                }
            }

            // Wait for all upload promises to resolve
            await Promise.all(uploadPromises);

            if (cargoLabel != null) {
                FileUploadService.uploadCargoLabel(claimOrderId, fbaLabel)
                    .then((response: any) => {
                    })
                    .catch((e: Error) => {
                        console.log(e);
                    });
            }

            if (fbaLabel != null) {
                FileUploadService.uploadFbaLabel(claimOrderId, fbaLabel)
                    .then((response: any) => {
                        openNotification('success', 'Başarılı', "FBA gönderisi oluşturuldu");
                        //  navigate('/fba');
                        setOrderCreateLoading(false);
                        setFbaCreated(true);

                    })
                    .catch((e: Error) => {
                        console.log(e);
                    });

            } else {
                openNotification('success', 'Başarılı', "FBA gönderisi oluşturuldu");
                setOrderCreateLoading(false);
                setFbaCreated(true);
            }

        } catch (e) {
            // Handle any errors that occur during the process
            setOrderCreateLoading(false);
            console.error(e);
        }
    }

    const modalRender = () => {

        let fnskuWarning = 0;
        const response = productList.map((orderItem: any, index: number) => {
            let services = orderItem.services;
            for (let i = 0; i < services.length; i++) {
                const service = services[i];
                if (service.id === 1 && service.qty > 0 && orderItem.fnskuLabel === null) {
                    fnskuWarning++;
                }
            }
        });

        if (fnskuWarning || fbaLabel === null) {

            if (fnskuWarning > 0) {
                setFnskuWarning(true);
            } else {
                setFnskuWarning(false);
            }

            setLabelModal(true);

        } else {
            setCurrent(current + 1);
        }

        return response;
    };

    return (
        <>
            <Modal open={labelModal} onCancel={() => { setLabelModal(false) }}
                footer={(_, { }) => (
                    <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                        <Col>
                            <Button className='bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text' onClick={() => { setLabelModal(false) }} >Geri</Button>
                        </Col>
                        <Col>
                            <Button className='bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text' loading={orderCreateLoading} onClick={() => { setCurrent(current + 1); setLabelModal(false) }} >İlerle</Button>
                        </Col>
                    </Row>
                )}>
                <Row justify={"center"}>
                    <WarningIcon className='ml-32' style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                </Row>
                <Row justify={'center'}>
                    <Col span={20}>
                        {(fbaLabel === null) && <h3 style={{ textAlign: "center", fontWeight: 400 }}><b>FBA</b> Etiketi Yüklemediniz</h3>}
                        {(fnskuWarning) && <h3 style={{ textAlign: "center", fontWeight: 400 }}><b>FNSKU</b> Etiketi Yüklemediniz</h3>}
                        <h3 style={{ textAlign: "center", fontWeight: 400 }}>Devam etmek istediğinize emin misiniz?</h3>
                    </Col>
                </Row>
            </Modal>
            {(!fbaCreated) ? <>

                <Modal title={<h3 style={{ margin: '0px' }}>Krediniz Yetersiz</h3>} open={isModalOpen} onCancel={handleCancel}
                    footer={(_, { }) => (
                        <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                            <Col>
                                <Button type='primary' style={{ backgroundColor: 'black', color: "pure-white", padding: '0px 30px' }} onClick={handleCancel} >İptal Et</Button>
                            </Col>
                            <Col>
                                <a target='_blank' href={`${process.env.REACT_APP_EXTERNAL_BASE_URL}/public/module/callback?accessToken=${externalToken}&refreshToken=${externalRefreshToken}&redirectUrl=/balance?amount%3D${loadBalance}%26openPaymentModal%3Dtrue`}>
                                    <Button style={{ backgroundColor: '#FFCB2B', color: 'black', padding: '0px 20px' }} type='primary'>Kredi Yükle</Button>
                                </a>
                            </Col>
                        </Row>
                    )}>
                    <Row justify={"center"}>
                        <InfoCircleOutlined style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                    </Row>
                    <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px" }}>İşleminiz için en az <b>${loadBalance}</b> kredi yüklemelisiniz</h3>
                </Modal>
                <Modal open={isConfirmModal} onCancel={() => { setIsConfirmModal(false) }}
                    footer={(_, { }) => (
                        <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                            <Col>
                                <Button className='bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text' onClick={() => { setIsConfirmModal(false) }} >İptal Et</Button>
                            </Col>
                            <Col>
                                <Button className='bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text' loading={orderCreateLoading} onClick={() => { onCreateOrder() }} >Onayla</Button>
                            </Col>
                        </Row>
                    )}>
                    <Row justify={"center"}>
                        <WarningIcon className='ml-32' style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                    </Row>
                    <Row justify={'center'}>
                        <Col span={20}>
                            <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px" }}>FBA Gönderisi ödemesi için toplam kredinizden  <b>${totalFulfillmentCost}</b>  düşecektir. Onaylıyor musunuz?</h3>
                        </Col>
                    </Row>
                </Modal>
                <ProgressCard setFBAShipmentId={setFBAShipment} currentStep={current} ></ProgressCard>
                <>{steps[current].content}</>
                <Row style={{ marginTop: 24 }} justify={(current == 0) ? "end" : "space-between"}>
                    {current > 0 && (
                        <Button className='btn btn-hover-dark border-radius-12 bg-cloud btn-text font-color-slate-blue' size='large' onClick={() => prev()}>
                            Geri
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button className='btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud' disabled={totalQty == 0 || warehouse == null} type="primary" size='large' onClick={() => next()}>
                            Sonraki Adım
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button className='btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud' type="primary" size='large' onClick={() => getBalance()}>
                            FBA Gönderisi Oluştur
                        </Button>
                    )}

                </Row>
            </>
                : <>
                    <Row>
                        <Col span={24}>
                            <StarterCard />
                        </Col>
                    </Row>
                    <Card className='mt-24' bodyStyle={{border: 'none', padding: '0px'}} style={{border: 'none'}}>
                        <Row gutter={24}>
                            <Col xl={6} xs={24}>
                                <Card className='bg-oneamz-yellow border-radius-md'>
                                    <p className='text font-color-slate-blue bg-cloud p-8 d-inline border-radius-sm'><b>FBA GÖNDERİ KODU <InfoIcon className='wh-14' /></b></p>
                                    <h2 className='h-3 font-color-slate-blue mt-16 mb-0'>ONESHIP</h2>
                                    <h2 className='h-3 font-color-slate-blue mt-0'>({id}-FBA-{fbaNo})</h2>
                                    <p className='text fs-18 font-color-slate-blue' >
                                        Ürününüzün detaylarına ulaşmak için, oneship gönderi koduyla uygulama içinde arama yapabilirsiniz
                                    </p>
                                    <Row justify={'center'} className='mt-64'>
                                        <Col>
                                            <Image preview={false} src={FbaImg}></Image>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl={18} xs={24}>
                                <Card className='bg-cloud border-radius-md'>
                                    <h1 className='h-2 font-color-slate-blue m-0'>Tebrikler, 1. Adımı Tamamladınız!</h1>
                                    <p className='text fs-24 font-color-slate-blue m-0'>Gönderi Talebiniz Başarıyla Kaydedilmiştir</p>
                                    <p className='text font-color-slate-blue mt-8'>Göstermiş olduğunuz özen ve dikkat için teşekkür ederiz.
                                        Amazon FBA sürecinizin bu aşamasını başarıyla tamamladınız. Gönderi detaylarınıza <b>ONESHIP ({id}-FBA-{fbaNo})</b> FBA Gönderi Kodu ile ulaşabilirsiniz.
                                        2. Adımı tamamlayıp FBA gönderi sürecinizi sonlandırabilirsiniz</p>
                                    <p className='pretitle font-color-slate-blue mt-32 upper-case'>ÜRÜN BİLGİLERİ</p>
                                    {order?.items.map((item: any, index: any) => (
                                        <FBAResultItemCard item={item} key={index} order={order} setQty={() => { }} orderItem={item}></FBAResultItemCard>
                                    ))}
                                </Card>

                                <Alert className='border-none border-radius-md mt-24'
                                    description={<p className='text fs-14 m-0'><b>ÖNEMLİ BİLGİLENDİRME:</b> Lütfen unutmayın, ürünleri aşağıda belirtilen adrese göndermediğiniz takdirde siparişiniz tamamlanmayacaktır.
                                    </p>}
                                    type="info"
                                    showIcon
                                />
                                <Card className='bg-cloud mt-24 border-radius-md'>
                                    <Row>
                                        <Col span={24}>
                                            <h2 className='h-2 m-0 font-color-slate-blue mb-0'>2.Adım: Ürünleri Depoya Gönderin</h2>
                                            <p className='font-color-slate-blue fs-14 btn-text-sm mt-8'>
                                                Yukarıda belirtmiş olduğunuz ürünleri aşağıdaki OneAmz Depo adresine göndermenizi bekliyoruz.
                                            </p>
                                        </Col>
                                        <Col span={24}>
                                            <WarehouseCard shipmentCode={shipmentCode} />
                                        </Col>
                                    </Row>
                                </Card>
                                <Row justify={'end'} gutter={8}>
                                    <Col>
                                        <Button onClick={() => navigate(`/claim/detail/${fbaNo}`)} className=' btn btn-text btn-hover-dark bg-cloud font-color-slate-blue border-slate-blue border-radius-12 mt-48'>
                                            Gönderi Detayına Git
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button onClick={() => refreshPage()} className='btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-12 mt-48'>
                                            Yeni FBA Gönderisini Oluştur
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </>}
        </>
    )
}

export default FBACreate