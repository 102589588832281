import { Button, Card, Col, Drawer, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import WarehouseMeService from '../../services/WarehouseMeService';
import WarehouseService from '../../services/WarehouseService';

interface WarehouseDetailProps {
    id: any,
    onHandleWarehouseList: () => void;
}

const { Item } = Form;

const WarehouseDetail: React.FC<WarehouseDetailProps> = ({ id, onHandleWarehouseList }) => {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            getWarehouse();
        }
    }, [open])

    const getWarehouse = () => {
        setLoading(true);
        WarehouseService.get(id)
            .then((response: any) => {
                let data = response.data;
                console.log(data);
                form.setFieldsValue({
                    countryCode: data?.countryCode,
                    type: data?.type,
                    code: data?.code,
                    address: data?.address,
                    name: data?.name,
                    addressLine1: data?.addressLine1,
                    addressLine2: data?.addressLine2,
                    city: data?.city,
                    state: data?.state,
                    postalCode: data?.postalCode,
                    phone: data?.phone,
                });
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const update = (values: any) => {

        let postObj = {
            "countryCode": values.countryCode,
            "type": values.type,
            "code": values.code,
            "address": values.address,
            name: values?.name,
            addressLine1: values?.addressLine1,
            addressLine2: values?.addressLine2,
            city: values?.city,
            state: values?.state,
            postalCode: values?.postalCode,
            phone: values?.phone,
        }


        WarehouseMeService.update(id, postObj)
            .then((response: any) => {
                onHandleWarehouseList();
                // onClose();
            }).catch((e: Error) => {
            });
    }

    return (
        <div>
            <Button className='btn-sm btn-text-sm btn-hover-white bg-slate-blue font-color-cloud border-radius-8'  onClick={showDrawer}>
                Detay
            </Button>
            <Drawer title="Depo Düzenle" placement="right" onClose={onClose} open={open}>
            <Card loading={loading} >
                <Form form={form} onFinish={update} layout="vertical">
                    <Item
                        name="name"
                        label="Depo Adı"
                        rules={[
                            {
                                required: true,
                                message: 'Depo Adı zorunlu',
                            },
                        ]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="countryCode"
                        label="Ülke"
                        rules={[
                            {
                                required: true,
                                message: 'Ülke Seçiniz!',
                            }]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            options={[
                                { "label": "Canada", "value": "CA" },
                                { "label": "Mexico", "value": "MX" },
                                { "label": "US", "value": "US" },
                                { "label": "Czech Republic", "value": "CZ" },
                                { "label": "France", "value": "FR" },
                                { "label": "Germany", "value": "DE" },
                                { "label": "Italy", "value": "IT" },
                                { "label": "Poland", "value": "PL" },
                                { "label": "Spain", "value": "ES" },
                                { "label": "UK", "value": "GB" },
                                { "label": "India", "value": "IN" },
                                { "label": "Japan", "value": "JP" },
                                { "label": "Singapore", "value": "SG" },
                                { "label": "UAE", "value": "AE" },
                                { "label": "Australia", "value": "AU" }
                            ]}
                        />
                    </Item>
                    <Item
                        name="code"
                        label="Depo Kodu"
                        rules={[
                            {
                                required: true,
                                message: 'Depo Kodu zorunlu',
                            }]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="address"
                        label="Adres"
                        rules={[
                            {
                                required: true,
                                message: 'Adres zorunlu',
                            },
                        ]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="addressLine1"
                        label="Adres Satır 1"
                        rules={[
                            {
                                required: true,
                                message: 'Adres Satır 1 zorunlu',
                            },
                        ]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="addressLine2"
                        label="Adres Satır 2"
                    >
                        <Input />
                    </Item>
                    <Item
                        name="phone"
                        label="Telefon"
                    >
                        <Input />
                    </Item>
                    <Item
                            name="state"
                            label="Eyalet"
                        >
                            <Input />
                        </Item>
                        <Item
                            name="city"
                            label="Şehir"
                        >
                            <Input />
                        </Item>
                    <Item
                        name="postalCode"
                        label="Posta Kodu"
                    >
                        <Input />
                    </Item>
                    <Item
                        name="type"
                        label="Depo Tipi"
                        rules={[
                            {
                                required: true,
                                message: 'Depo Tipi Seçiniz!',
                            }]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            options={[
                                { value: 'W', label: 'Warehouse' },
                                { value: 'FC', label: 'Fulfillment Center' },
                            ]}
                        />
                    </Item>
                    <Item>
                        <Row justify={'end'}>
                            <Col>
                            <Button className='btn btn-text btn-hover-dark' onClick={onClose} style={{ marginRight: '10px' }}>
                                Vazgeç
                            </Button>
                            </Col>
                            <Button className='btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-8' htmlType="submit">
                                Kaydet
                            </Button>
                        </Row>
                    </Item>
                </Form>
                </Card>
            </Drawer>
        </div>
    )
}

export default WarehouseDetail