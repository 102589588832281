import { Avatar, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const ProfilePart = () => {
    const location = useLocation();
    const [firstName, setFirstName] = useState<any>('');
    const [lastName, setLastName] = useState<any>('');
    const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';

    const getMyName = () => {
        let firstName = localStorage.getItem("firstName");
        let lastName = localStorage.getItem("lastName");
        setFirstName(firstName);
        setLastName(lastName);
    }

    useEffect(() => {
        getMyName()

    }, [location.pathname]);
    
    return (
        <>
            <Row>
                <Col span={24}>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'bg-slate-blue-02 border-radius-md w-full' : ''
                    } to={'/profile'} >
                        <Row className='mt-16' gutter={16} justify={"center"}>
                            <Col>
                                <Avatar className='bg-oneamz-yellow font-color-slate-blue' size={48}><b>{firstLetter}</b></Avatar>
                            </Col>
                            <Col>
                                <p className='text m-0 font-color-slate-blue'><b>Merhaba,</b></p>
                                <p className='text m-0 font-color-slate-blue'>{firstName} {lastName}</p>
                            </Col>
                        </Row>
                    </NavLink>
                </Col>
            </Row>
        </>
    )
}

export default ProfilePart