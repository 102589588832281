import { Col, Divider, Row } from 'antd'
import OrderDetailStatus from '../../components/V2/OrderDetailV2/OrderDetailStatus'
import ShipmentDetail from '../../components/V2/OrderDetailV2/ShipmentDetail'
import OrderDetailPackageDetail from '../../components/V2/OrderDetailV2/OrderDetailPackageDetail'
import ClaimOrderService from '../../services/ClaimOrderService'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const OrderDetailV2 = () => {
  let { id } = useParams();

  const [order, setOrder] = useState<any>()
  const [orderItemList, setOrderItemList] = useState<any>([])
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [orderCode, setOrderCode] = useState("");
  const [receiver, setReceiver] = useState<any>(null)

  useEffect(() => {
    getOrderDetail();
  }, [])

  useEffect(() => {
    if (order?.orderType === "FBA_SHIPMENT") {
      setTitle("FBA GÖNDERİ DETAY");
      setOrderCode(`FBA-${order?.user.id}-${order?.id}`);
    } else if (order?.orderType === "FBM_SHIPMENT") {
      setTitle("FBM GÖNDERİ DETAY");
      setOrderCode(`FBM-${order?.user.id}-${order?.id}`);
      getCustomer(order?.id);
    } else if (order?.orderType === "STORAGE") {
      setTitle("DEPOLAMA DETAY");
      setOrderCode(`STORAGE-${order?.user.id}-${order?.id}`);
    };
  }, [order])

  const getCustomer = (id: any) => {
    ClaimOrderService.getCustomer(id)
      .then((response: any) => {
        setReceiver(response.data);
      }).catch((e: Error) => {
        setLoading(false);
        console.log(e)
      });
  }

  const getOrderDetail = () => {
    setLoading(true);
    ClaimOrderService.get(id)
      .then((response: any) => {
        let data = response.data;
        let claimOrderObj = data.claimOrderItems.map((updatedItem: any) => {
          updatedItem.approvalQty = updatedItem.qty;
          return updatedItem;
        });

        data.claimOrderItems = claimOrderObj;

        setOrder(data);
        setOrderItemList(data.claimOrderItems)
        setLoading(false);
      }).catch((e: Error) => {
        setLoading(false);
        console.log(e)
      });
  }

  // const changeQty = (id: any, qty: any) => {
  //   if (qty === null) {
  //     qty = 0;
  //   }

  //   let data = order;
  //   let claimOrderObj = order.claimOrderItems.map((updatedItem: any) => {
  //     if (updatedItem.id === id) {
  //       updatedItem.approvalQty = qty;
  //       return updatedItem;
  //     }
  //     return updatedItem;
  //   });

  //   data.claimOrderItems = claimOrderObj;

  //   setOrder(data);
  //   setOrderItemList(data.claimOrderItems)
  // }

  // const confirmOrder = () => {
  //   let orderItems: any = [];
  //   let errorCount = 0;

  //   order.claimOrderItems.map((orderItem: any) => {
  //     if (orderItem.approvalQty > 0) {

  //       let itemServices: any[] = [];

  //       orderItem.services.map((servicesItem: any) => {

  //         let serviceItemObj = {
  //           "generalServiceId": servicesItem.generalService.id,
  //           "qty": orderItem.approvalQty,
  //         }

  //         itemServices.push(serviceItemObj);
  //       })

  //       orderItem.binId = 1

  //       let itemObj = {
  //         claimOrderItemId: orderItem.id,
  //         qty: orderItem.approvalQty,
  //         binId: orderItem.binId,
  //         claimOrderItemGeneralServices: itemServices
  //       }
  //       orderItems.push(itemObj)
  //     }
  //   });

  //   if (errorCount > 0) {
  //     openNotification('error', 'Hata', "Ürünün depolanacak rafını seçiniz");
  //     return false;
  //   }

  //   if (orderItems.length == 0) {
  //     openNotification('error', 'Hata', "Ürün adetleri sıfır olamaz");
  //     return false;
  //   }

  //   let orderGeneralServices: any = [];

  //   order.claimOrderGeneralServices.map((generalServiceItem: any) => {
  //     let orderGeneralService = {
  //       "generalServiceId": generalServiceItem.generalService.id,
  //       "qty": generalServiceItem.qty
  //     }

  //     orderGeneralServices.push(orderGeneralService);

  //   })

  //   let postObj = {
  //     "claimOrderId": id,
  //     "orderItems": orderItems,
  //     "employeeNote": note,
  //     "oneamzToken": localStorage.getItem('externalAccessToken'),
  //     "orderGeneralServices": orderGeneralServices
  //   };

  //   OrderService.create(postObj)
  //     .then((response: any) => {
  //       openNotification('success', 'Başarılı', "Gönderi onaylandı");
  //       if (order?.orderType == 'FBA_SHIPMENT') {
  //         navigate('/fba')
  //       } else if (order?.orderType == 'FBM_SHIPMENT') {
  //         navigate('/fbm')
  //       } else {
  //         navigate('/storage')
  //       }
  //     }).catch((e: Error) => {

  //     });
  // }

  return (
    <>
      <Row>
        <Col span={24}>
          {/* <OrderDetailCard order_id={order?.id} oneship_id={order?.user.id} order_type={order?.orderType}></OrderDetailCard> */}
        </Col>
      </Row>
      <Row className='mt-24'>
        <Col span={24}>
          <OrderDetailStatus order={order}></OrderDetailStatus>
        </Col>
      </Row>
      <Row justify={'space-between'}>
        <Col xl={8} xs={24}>
          <ShipmentDetail warehouse={order?.warehouse}></ShipmentDetail>
          {/* <OrderDetailLabels></OrderDetailLabels> */}
        </Col>
        <Col>
          <Divider type="vertical" className='vertical-divider' />
        </Col>
        <Col xl={15} xs={24}>
          <h3 className='h-3'>Paket Detayları</h3>
          {order?.claimOrderItems.map((item:any)=>(
            <OrderDetailPackageDetail item={item}></OrderDetailPackageDetail>
          ))}
          {/* <OrderDetailPackageInfo order={order}></OrderDetailPackageInfo> */}
        </Col>
      </Row>
    </>
  )
}

export default OrderDetailV2