import { Card, Col, Empty, Input, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import ShipmentListCard from '../../DashboardV2/ShipmentList/ShipmentListCard'
import { IResponseOrder } from '../../../types/Order';
import OrderService from '../../../services/OrderService';
import ClaimOrderService from '../../../services/ClaimOrderService';
import { formatDate, getDateRangeDefault } from '../../../utils';
import FbmShipmentCard from './FbmShipmentCard';
import DateRange from '../../Common/DateRange';
import CreateFbmCard from './CreateFbmCard';
import { NavLink } from 'react-router-dom';
import UserService from '../../../services/UserService';
import ShipmentListCardManager from '../../DashboardV2/ShipmentList/ShipmentListCardManager';

const FbmShipmentList = () => {
    const [dateRange, setDateRange] = useState<any[]>(getDateRangeDefault)
    const [loading, setLoading] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [orderList, setOrderList] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [claimOrderList, setClaimOrderList] = useState<any[]>([]);
    const [allOrders, setAllOrders] = useState<any>([]);

    const currentRole = localStorage.getItem('userRole');

    const statusList = [
        { value: '', label: 'Seçiniz' },
        { value: 'INCOMING', label: 'OneAmz Deposuna Ulaşması Bekleniyor' },
        { value: 'WAITING', label: 'OneAmz Deposunda Beklemede' },
        { value: 'PROCESSING', label: 'OneAmz Deposunda İşlemde' },
        { value: 'COMPLETE', label: 'OneAmz Deposundan Gönderilmeye Hazır' },
        { value: 'APPROVED', label: 'OneAmz Deposu Gönderiyi Onayladı.' },
        { value: 'SHIPPED', label: 'Kargo Firması Teslim Aldı' }
    ];


    const userGetAll = () => {
        UserService.getAll("?roleName=CUSTOMER")
            .then((response: any) => {
                let data = response.data;
                const newArray = data.map((item: any) => {
                    return { value: item.id, label: `#${item.id} ${item.firstName} ${item.lastName}` };
                });
                setCustomerList(newArray);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    useEffect(() => {
        getOrderList();
        userGetAll();
    }, []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase();
        let filtered;
        if (value) {
            filtered = allOrders.filter((order: any) =>
                order.items && order.items.some((item: any) => {
                    const product = item.product;
                    return product && (
                        product.name.toLowerCase().includes(value) ||
                        product.asin.toLowerCase().includes(value)
                    );
                })
            );
        } else {
            filtered = allOrders;
        }

        setFilteredData(filtered);
    };

    const getOrderList = (filter = "") => {
        let fbmFilter = filter + `&orderType=FBM_SHIPMENT`;
        OrderService.getAll(fbmFilter)
            .then((response: any) => {
                const fbmData: IResponseOrder[] = response.data.map((itemData: any) => new IResponseOrder(itemData));
                let storageFilter = filter + `&orderType=STORAGE`;
                OrderService.getAll(storageFilter)
                    .then((response: any) => {
                        const storageData: IResponseOrder[] = response.data.map((itemData: any) => new IResponseOrder(itemData));
                        let data = [...fbmData, ...storageData]
                        setOrderList(data);
                        setLoading(false);
                    }).catch((e: Error) => {
                        setOrderList([]);
                        console.log(e)
                        setLoading(false);
                    }); setLoading(false);
            }).catch((e: Error) => {
                setOrderList([]);
                console.log(e)
                setLoading(false);
            });
    }

    const getClaimOrderList = (filter = "") => {
        let fbmFilter = filter + `&orderType=FBM_SHIPMENT&status=INCOMING`;
        ClaimOrderService.getAll(fbmFilter)
            .then((response: any) => {
                const fbmData: IResponseOrder[] = response.data.map((itemData: any) => new IResponseOrder(itemData));
                let storageFilter = filter + `&orderType=STORAGE&status=INCOMING`;
                ClaimOrderService.getAll(storageFilter)
                    .then((response: any) => {
                        const storageData: IResponseOrder[] = response.data.map((itemData: any) => new IResponseOrder(itemData));
                        let data = [...fbmData, ...storageData]
                        setClaimOrderList(data);
                        setLoading(false);
                    })
                    .catch((e: Error) => {
                        setClaimOrderList([]);
                        console.log(e)
                        setLoading(false);
                    });
                setLoading(false);
            })
            .catch((e: Error) => {
                setClaimOrderList([]);
                console.log(e)
                setLoading(false);
            });
    }

    useEffect(() => {
        setLoading(true);
        let filter = `?startCreatedAt=${formatDate(dateRange[0])}T00:00:00&endCreatedAt=${formatDate(dateRange[1])}T23:59:59`

        if (selectedCustomer != "") {
            filter += `&userId=${selectedCustomer}`;
        }

        if (selectedStatus === '') {

            let filterWithStatus = filter + `&status=INCOMING`;
            getClaimOrderList(filterWithStatus);

            let filterWithoutStatus = filter;
            getOrderList(filterWithoutStatus);

        } else {
            if (selectedStatus === 'INCOMING') {
                let filterWithStatus = filter + `&status=INCOMING`;
                getClaimOrderList(filterWithStatus);
                setOrderList([]);
            } else {
                setClaimOrderList([]);
                if (selectedStatus != "") {
                    filter += `&orderStatus=${selectedStatus}`;
                }
                let filterWithoutStatus = filter;
                getOrderList(filterWithoutStatus);
            }
        }

    }, [dateRange, selectedStatus, selectedCustomer])

    useEffect(() => {
        const allOrderList = [...orderList, ...claimOrderList];
        allOrderList.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
        setAllOrders([...allOrderList]);
        setFilteredData([...allOrderList]);
    }, [orderList, claimOrderList])

    return (
        <>



            <Row gutter={24}>
                {(currentRole == "CUSTOMER") && <Col className='mt-24' xl={8} xs={24}>
                    <NavLink to={'/fbm/new'}>
                        <CreateFbmCard></CreateFbmCard>
                    </NavLink>
                </Col>}

                <Col className='mt-24' xl={(currentRole == "CUSTOMER") ? 16 : 24} xs={24}>
                    <Card className='bg-cloud border-radius-md h-full'>
                        <Row align={'middle'} gutter={24}>
                            {(currentRole != "CUSTOMER") ? <Col className='mt-16' xxl={6} md={12} xs={24}><Select filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} className='amz-input w-full' options={[{ value: '', label: 'Seçiniz' }, ...customerList]} showSearch onChange={(customer) => setSelectedCustomer(customer)} placeholder={"Müşteri"} ></Select>   </Col>
                                : <></>}
                            <Col className='mt-16' xl={(currentRole == "CUSTOMER") ? 8 : 6} md={12} xs={24}>
                                <Input className='amz-input w-full' placeholder="Ürün Ara" onChange={handleSearch}
                                />
                            </Col>
                            <Col className='mt-16' xl={(currentRole == "CUSTOMER") ? 8 : 6} md={12} xs={24}>
                                <Select className='amz-input w-full' options={statusList} onChange={(status) => setSelectedStatus(status)} placeholder={"Statü"} > </Select>
                            </Col>
                            <Col className='mt-16' xl={(currentRole == "CUSTOMER") ? 8 : 6} md={12} xs={24}>
                                <DateRange onChangeDateRange={(dateRange) => setDateRange(dateRange)} ></DateRange>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Card loading={loading} className='bg-cloud border-radius-md mt-24'>
                <h3 className='h-3 m-0'>FBM Gönderilen Listesi</h3>
                {filteredData.map(order => (
                    <Col className='mt-24 antd-card-body-p-0' span={24} key={order.id}>
                        {(currentRole === "CUSTOMER") && <ShipmentListCard order={order}></ShipmentListCard>}
                        {(currentRole === "ADMIN") && <ShipmentListCardManager order={order}></ShipmentListCardManager>}
                    </Col>
                ))}
                {(filteredData.length == 0) ? <Col span={24}><Empty description={<h3>FBM Gönderi verisi yok</h3>} image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> : <></>}
            </Card>
        </>
    )
}

export default FbmShipmentList