import { Col, Row } from 'antd'
import { NavLink } from 'react-router-dom'

const SettingsRouter = () => {
    const linkStyle = (isActive: boolean) => {
        return isActive ? 'font-color-slate-blue' : 'font-color-slate-blue-05';
    }

    return (
        <>
            <Row>
                <Col style={{ padding: "1rem" }}>
                    <NavLink className={({ isActive }) => linkStyle(isActive)} to="/settings/services">
                        <p className='link fs-16' ><b>Hizmet Listesi</b></p>
                    </NavLink>
                </Col>
                <Col style={{ padding: "1rem" }}>
                    <NavLink className={({ isActive }) => linkStyle(isActive)} to="/settings/warehouses">
                        <p className='link fs-16'><b>Amazon FBA Depoları</b></p>
                    </NavLink>
                </Col>
                <Col style={{ padding: "1rem" }}>
                    <NavLink className={({ isActive }) => linkStyle(isActive)} to="/settings/warehouse-management">
                        <p className='link fs-16'><b>Depo Yönetimi</b></p>
                    </NavLink>
                </Col>
                <Col style={{ padding: "1rem" }}>
                    <NavLink className={({ isActive }) => linkStyle(isActive)} to="/settings/users">
                        <p className='link fs-16'><b>Kullanıcılar</b></p>
                    </NavLink>
                </Col>
                <Col style={{ padding: "1rem" }}>
                    <NavLink className={({ isActive }) => linkStyle(isActive)} to="/settings/roles">
                        <p className='link fs-16'><b>Roller</b></p>
                    </NavLink>
                </Col>
            </Row>
        </>
    )
}

export default SettingsRouter