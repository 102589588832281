import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Image, Divider } from 'antd';
import { LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import loginLogo from '../../assets/loginLogo.svg'
import loginImage from '../../assets/loginImage2.png'
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from '../../services/AuthService';

const NewPassword: React.FC = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const userId = query.get('userId');

    const createAccount = () => {
        navigate('/login')
    }

    const SignIn = (values: any) => {

        let userData: any = {
            "userId": userId,
            "password": values.password,
            "token": token,
        };

        AuthService.resetPassword(userData)
            .then((response: any) => {
                console.log(response)
                alert('başarılı')
                navigate('/login')
            })
            .catch((e: Error) => {
                console.log(e);
            });
        console.log(values)
    };

    const onFinish = (values: any) => {
        SignIn(values)
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Row justify={'space-between'}>
                <Col xs={0} sm={0} md={0} lg={16} xl={16} style={{ position: 'fixed', top: 0, left: 0, height: '100vh', overflow: 'hidden' }}>
                    <Image style={{ width: "54vw", height: "100vh" }} preview={false} src={loginImage} />
                </Col>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 9, offset: 14 }} xl={{ span: 9, offset: 14 }}>
                    <Row justify={'center'}>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 20 }} md={{ span: 14 }} lg={{ span: 22 }} xl={18}>
                            <Row justify={'center'} style={{ margin: "5em 0 0 0", width: "100%" }}>
                                <Col>
                                    <Image style={{ height: "15vh" }} preview={false} src={loginLogo} />
                                </Col>
                            </Row>
                            <Row style={{ height: "30vh" }} justify={'center'}>
                                <Col span={20}>
                                    <Form
                                        name="normal_login"
                                        className="login-form"
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}
                                    >
                                        <h1 style={{ fontSize: "3em", margin: "1em 0 1em 0" }}>Yeni Şifrenizi Giriniz</h1>

                                        <p style={{ fontSize: "1.2em", marginBottom: "3em" }}>
                                            Parolanızı seçerken minimum 14 harf uzunluğunda,
                                            1 adet büyük harf içeren ve önceki şifrenizden farklı bir şifre seçiniz.
                                        </p>
                                        <p style={{ margin: "0", padding: " 0 0 1em 0", fontSize: "1.3em" }}>Şifreyi Girin</p>
                                        <Form.Item
                                            name="password"
                                            rules={[{
                                                required: true,
                                                message: 'Yeni şifre oluşturmak için kriterlere uyduğunuzdan emin olun.',
                                                min: 8
                                            }]}
                                        >
                                            <Input.Password
                                                size='large'
                                                style={{ padding: "0.7em" }}
                                                prefix={<LockOutlined className="site-form-item-icon" />}
                                                placeholder="Şifre"
                                                iconRender={visible => (visible ? <EyeTwoTone onClick={togglePasswordVisibility} /> : <EyeInvisibleOutlined onClick={togglePasswordVisibility} />)}
                                            />
                                        </Form.Item>
                                        <p style={{ margin: "0", padding: " 0 0 1em 0", fontSize: "1.3em" }}>Şifreyi Onaylayın</p>
                                        <Form.Item
                                            name="confirmPassword"
                                            rules={[
                                                { required: true, message: 'Lütfen şifrenizi tekrar girin!' },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject('Şifreler uyuşmuyor!');
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password
                                                size='large'
                                                style={{ padding: "0.7em" }}
                                                prefix={<LockOutlined className="site-form-item-icon" />}
                                                placeholder="Şifre Tekrar"
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button style={{ width: "100%", background: "black", height: "4em", margin: "2em 0 0 0" }} type="primary" htmlType="submit" className="login-form-button">
                                                <p style={{ fontSize: "1.2em", margin: "0", color: "#FFCB2B" }}><b>Gönder</b></p>
                                            </Button>
                                        </Form.Item>
                                        <Divider style={{ margin: "0" }} />
                                        <Row justify={'center'}>
                                            <Col>
                                                <h4 style={{ fontSize: "1.15em", fontWeight: "400" }}> <a href='/login' onClick={createAccount} style={{ color: "#FFCB2B" }}>Giriş</a></h4>
                                            </Col>
                                            <Col style={{ margin: "0 0 0 0.5em" }}>
                                                <h4 style={{ color: "#777777", fontSize: "1.15em", fontWeight: "400" }}>Ekranına Geri Dön</h4>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default NewPassword;