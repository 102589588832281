import { Button, Card, Checkbox, Col, Divider, Form, Input, Modal, Popconfirm, Row, Select, Switch, message } from 'antd'
import React, { useEffect, useState } from 'react'
import closeIcon from '../../../../assets/XCircle.svg';
import { DeleteOutlined } from '@ant-design/icons'
import ServicesService from '../../../../services/ServicesService';
import { formatMoney, openNotification } from '../../../../utils';
import RoleService from '../../../../services/RoleService';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import PermissionService from '../../../../services/PermissionService';

interface RoleItemCardProps {
    role: any
    getRoles: () => void
}

const { Item } = Form;

const RoleItemCard: React.FC<RoleItemCardProps> = ({ role, getRoles }) => {
    // const displayText = item.product.name.length > 100 ? `${item.product.name.substring(0, 100)}...` : item.product.name;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedItemDetails, setSelectedItemDetails] = useState<any>(null);
    const [form] = Form.useForm();
    const [isOptional, setIsOptional] = useState(false);
    const [isPerUnit, setPerUnit] = useState(false);

    const showDetailModal = (details: any) => {
        setIsModalVisible(true);
    };

    const cancel = () => {
    };

    useEffect(() => {
        getPermissions()
    }, [])


    const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
    const [permissionItems, setPermissionItems] = useState();

    const getPermissions = () => {
        let ids: number[] = role.permissions.map((permission: any) => permission.id);
        setSelectedPermissions(ids);
        PermissionService.getAll()
            .then((response: any) => {
                const sortedData = [...response.data].sort((a, b) => a.name.localeCompare(b.name));
                forRoles(sortedData)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const forRoles = (permissionlist: any) => {
        const permissionCheckboxList: any = []

        for (let index = 0; index < permissionlist.length; index++) {
            const allPermissions = permissionlist[index];
            let obj = {
                label: allPermissions.name,
                value: allPermissions.id,
            }
            permissionCheckboxList.push(obj)
        }
        setPermissionItems(permissionCheckboxList)
    }

    const onChange = (checkedValues: CheckboxValueType[]) => {
        console.log(checkedValues);
        setSelectedPermissions(checkedValues);
    };

    const updateRole = () => {
        let data: any = {
            name: role?.name,
            description: role?.description,
            permissionIds: selectedPermissions
        }

        RoleService.update(role.id, data)
            .then((response: any) => {
                openNotification('success', 'Başarılı', ' İzinler Başarıyla kaydedildi.')
                setIsModalVisible(false);
            })
            .catch((e: Error) => {
                console.log(e);
            });

        console.log(selectedPermissions)
    }

    return (
        <>
            <Card className='border-radius-md w-full p-24 antd-card-body-p-0 p-8'>
                <Row align={'middle'} justify={'space-between'}>
                    <Col xl={4} md={8} xs={24}>
                        <p className='text-sm fs-16 m-0'><b>Role Name</b></p>
                        <p className='text-sm fs-14 font-color-slate-blue-06 m-0'>
                            {role.name}
                        </p>
                    </Col>
                    <Col>
                        <Button className='btn btn-hover-dark bg-slate-blue-02 font-color-slate-blue btn-text border-radius-lg' onClick={() => showDetailModal(role)}>
                            Detay
                        </Button>
                    </Col>
                </Row>
            </Card>
            <Modal
                title={<>{role.name} Detay</>}
                visible={isModalVisible}
                className='antd-modal-secondary '
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                closeIcon={<img src={closeIcon} height={24} alt="close" />}
            >
                <Form
                    layout="horizontal"
                    onFinish={updateRole}
                >
                    <Divider />
                    <Checkbox.Group className='text' value={selectedPermissions} style={{ maxWidth: "150px" }} options={permissionItems} onChange={onChange} />
                    <Divider />
                    <Item>
                        <Row gutter={24}>
                           <Col span={12}>
                           <Button className='btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8' onClick={() => setIsModalVisible(false)} style={{ marginRight: '10px' }}>
                                İptal
                            </Button>
                           </Col>
                           <Col span={12}>
                           <Button className='btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8' htmlType="submit">
                                Kaydet
                            </Button>
                           </Col>
                        </Row>
                    </Item>
                </Form>
            </Modal>
        </>
    )
}

export default RoleItemCard