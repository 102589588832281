import { Avatar, Button, Card, Col, Row,} from 'antd'
import React from 'react'
import OrderAlert from '../../Order/OrderAlert'
import { formatDateTime } from '../../../utils';
import { NavLink } from 'react-router-dom';
import { IResponseOrder } from '../../../types/Order';
import AmazonProductImageAvatar from '../../Common/AmazonProductImageAvatar';

interface ShipmentListCardProps {
    order: IResponseOrder;
}

const ShipmentListCard: React.FC<ShipmentListCardProps> = ({ order }) => {
    const statusList = [
        { value: '', label: 'Seçiniz' },
        { value: 'INCOMING', label: (order.orderType === 'FBM_SHIPMENT') ? 'Depo Onayı bekliyor' : 'OneAmz Deposuna Ulaşması Bekleniyor' },
        { value: 'WAITING', label: 'OneAmz Deposunda Beklemede' },
        { value: 'PROCESSING', label: 'OneAmz Deposunda İşlemde' },
        { value: 'COMPLETE', label: 'OneAmz Deposundan Gönderilmeye Hazır' },
        { value: 'APPROVED', label: 'OneAmz Deposu Gönderiyi Onayladı.' },
        { value: 'SHIPPED', label: 'Kargo Firması Teslim Aldı' }
    ];

    const statusColors: any = {
        'INCOMING': 'red',
        'WAITING': 'orange',
        'PROCESSING': 'purple',
        'COMPLETE': 'blue',
        'APPROVED': 'lime',
        'SHIPPED': 'green',
        '': 'default' // Boş durumlar için varsayılan renk
    };

    const statusLabels = statusList.reduce((acc: any, status) => {
        acc[status.value] = status.label;
        return acc;
    }, {});

    return (
        <>
            <Card className='border-radius-md w-full p-24'>
                <Row align={'middle'} justify={'start'} gutter={[16, 16]}>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text-sm fs-12 bg-cloud p-8 d-inline border-radius-xs'><b>GÖNDERİ KODU</b></p>
                        <p className='pretitle fs-16 mb-0'>{order.orderCode}</p>
                    </Col>
                    <Col xl={6} md={8} xs={24}>
                        <Avatar.Group maxCount={2}>
                            {order.items.map((item: any, index: any) => (
                                <AmazonProductImageAvatar key={index} orderItem={item}></AmazonProductImageAvatar>
                            ))}
                        </Avatar.Group>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <p className='text-sm fs-12 m-0'><b>OLUŞTURMA TARİHİ</b></p>
                        <p className='text-sm fs-12 font-color-slate-blue-06 m-0'>{formatDateTime(order.createdAt.toLocaleString())}</p>
                    </Col>
                    <Col xl={4} md={8} xs={12}>
                        <Row align={'middle'}>
                            <Col span={3}>
                                <div className={`bg-${statusColors[order.orderStatus]} wh-8 border-radius-lg`}></div>
                            </Col>
                            <Col span={21}>
                                <p className='text-small '>{statusLabels[order.orderStatus] || order.orderStatus}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={2}>
                        <OrderAlert order={order} />
                    </Col>
                    <Col xl={2}>
                        <NavLink to={order.detailUrl}>
                            <Button className='btn btn-hover-dark bg-cloud font-color-slate-blue btn-text border-radius-lg'>
                                Detay
                            </Button>
                        </NavLink>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default ShipmentListCard