import { Card, Col, Row } from 'antd'
import React from 'react'

const StarterCard = () => {
    return (
        <>
            <Card className='profile-starter-card bg-cloud border-radius-md'>
                <Row justify={'space-between'} align={'middle'}>
                    <Col xl={12} lg={12} md={12}>
                        <p className='pretitle font-color-oneamz-yellow upper-case'>Grow on Amazon using the OneAMZ web app!</p>
                        <h3 className='h-3 font-color-slate-blue mt-0'>Elevate your storage experience with confidence</h3>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default StarterCard