import { Col, Collapse, Row, Divider } from 'antd'
import React from 'react'
import { IResponseOrder } from '../../../types/Order';
import AmazonProductImageAvatar from '../../../components/Common/AmazonProductImageAvatar';
import OrderAlert from '../../../components/Order/OrderAlert';
import AmazonProductInfo from '../../../components/Common/AmazonProductInfo';
import { formatMoney } from '../../../utils';
import OneamzShippingLabel from '../../../components/FBADetail/OneamzShippingLabel';

interface FBAShipmentItemCardManagerProps {
    item: any;
    order: IResponseOrder,
    orderItem: any,
    setQty: (id: any, qty: any) => void;
}
const FBAShipmentItemCard: React.FC<FBAShipmentItemCardManagerProps> = ({ item, order, orderItem, setQty }) => {
    const mandatoryServices = item.services.filter((service: any) => !service.generalService.isOptional);
    const optionalServices = item.services.filter((service: any) => service.generalService.isOptional);

    return (
        <>
            <Collapse
                className='antd-order-detail-collapse border-radius-md mb-24'
                expandIconPosition={'end'}
                items={[{
                    key: '1',
                    label:
                        <>
                            <Row align={'middle'}>
                                <Col span={3}>
                                    <AmazonProductImageAvatar orderItem={item}></AmazonProductImageAvatar>
                                </Col>
                                <Col span={10}>
                                    <Row>
                                        <AmazonProductInfo asin={item.product.asin} name={item.product.name}></AmazonProductInfo>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <OneamzShippingLabel order={order} orderItem={orderItem}></OneamzShippingLabel>
                                </Col>
                                <Col className='text-center' xl={10}>
                                    <OrderAlert order={order} />
                                </Col>
                            </Row>
                        </>,
                    children:
                        <>
                            <Row justify={'space-between'} align={'top'}>
                                <Col xl={11}>
                                    <h4 className='text mt-0 mb-8'><b>FBA ZORUNLU HİZMETLER</b></h4>
                                    <Row>
                                        <Col span={9}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Hizmet</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Birim Fiyatı</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Adet</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Toplam</b></p>
                                        </Col>
                                    </Row>
                                    {mandatoryServices.map((service: any) => (
                                        <>
                                            <Row key={service.id}>
                                                <Col span={9}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.generalService.name}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b> {formatMoney(service.generalService.fee)}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.qty}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue mb-8'><b> {formatMoney(service.amount)}</b></p>
                                                </Col>
                                            </Row>
                                            <Divider className='m-0 mt-8' />
                                        </>
                                    ))}
                                </Col>

                                <Col xl={11}>
                                    <h4 className='text mt-0 mb-8'><b>EK HİZMETLER</b></h4>
                                    <Row>
                                        <Col span={9}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Hizmet</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Birim Fiyatı</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Adet</b></p>
                                        </Col>
                                        <Col className='text-center' span={5}>
                                            <p className='text-small font-color-slate-blue mb-8'><b>Toplam</b></p>
                                        </Col>
                                    </Row>
                                    {optionalServices.map((service: any) => (
                                        <>
                                            <Row key={service.id}>
                                                <Col span={9}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.generalService.name}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b> {formatMoney(service.generalService.fee)}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue-05 mb-8'><b>{service.qty}</b></p>
                                                </Col>
                                                <Col className='text-center' span={5}>
                                                    <p className='text-small font-color-slate-blue mb-8'><b> {formatMoney(service.amount)}</b></p>
                                                </Col>
                                            </Row>
                                            <Divider className='m-0 mt-8' />
                                        </>
                                    ))}
                                </Col>
                            </Row>
                        </>
                }]}
            />
        </>
    )
}

export default FBAShipmentItemCard