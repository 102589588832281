import React, { useEffect } from 'react';
import { DatePicker, TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { convertDateFormat, getDateRangeDefault } from '../../utils';

interface DateRangeProps {
    onChangeDateRange: (values: any) => void;
}

const DateRange: React.FC<DateRangeProps> = ({ onChangeDateRange }) => {
    const { RangePicker } = DatePicker;

    useEffect(() => {
        const dateFormat = getDateRangeDefault();
        let convertedDateFormat = [convertDateFormat(dateFormat[0]), convertDateFormat(dateFormat[1])];
        onChangeDateRange(convertedDateFormat);
    }, [])

    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'Today', value: [dayjs().startOf('day'), dayjs()] },
        { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs()] },
        { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
        { label: 'This Month', value: [dayjs().startOf('month'), dayjs()] },
        {
            label: dayjs().subtract(1, 'month').format('MMMM'),
            value: [
                dayjs().subtract(1, 'month').startOf('month'),
                dayjs().subtract(1, 'month').endOf('month')
            ]
        },
        {
            label: dayjs().subtract(2, 'month').format('MMMM'),
            value: [
                dayjs().subtract(2, 'month').startOf('month'),
                dayjs().subtract(2, 'month').endOf('month')
            ]
        },
        {
            label: dayjs().subtract(3, 'month').format('MMMM'),
            value: [
                dayjs().subtract(3, 'month').startOf('month'),
                dayjs().subtract(3, 'month').endOf('month')
            ]
        },
        // { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
        { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
    ];

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates && dates[0] && dates[1]) {
            const formattedDates = [convertDateFormat(dates[0]), convertDateFormat(dates[1])];
            onChangeDateRange(formattedDates);
        } else {
            onChangeDateRange([]);
        }
    };

    return (
        <RangePicker className='amz-input w-full' defaultValue={getDateRangeDefault()} presets={rangePresets} onChange={onRangeChange} format={"DD/MM/YYYY"} />
    );
}

export default DateRange
